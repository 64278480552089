import { Grid, Icon, IconButton } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { config } from '../apps/config';
import TwitterIcon from '@material-ui/icons/Twitter';
import psocFooterImg from '../images/psocFooterImg.png';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import OverridableComponent from '../utils/components/overridableComponent';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: '#556691',
        borderRadius: 15,
        color: '#fff',
        '&:hover': {
            backgroundColor: '#607196'
        },
        padding: '5px 10px'
    },
    iconRight: {
        textAlign: 'right'
    },
    iconLeft: {
        textAlign: 'left'
    },
    centerItemXS: {
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            margin: '0 auto'
        }
    },
    footer: {
        position: 'relative',
        bottom: 0,
        margin: '21px auto 0 auto',
        width: '70%',
        height: 85
    },
    italix: {
        color: 'white',
        fontStyle: 'italic',
        fontWeight: 'bold'
    },
    img: {
        display: 'block',
        margin: '0 auto',
        height: 80
    },
    textRightSMUp: {
        [theme.breakpoints.up('sm')]: {
            textAlign: 'end',
            width: '100%'
        }
    }
}));

export function Footer(): JSX.Element {
    const classes = useStyles();
    return <Grid className={classes.footer} container justifyContent={'center'} alignItems={'center'} spacing={2}>
        <Grid container xs={12} sm={2} item alignItems={'center'}>
            <Link to={'/termsAndConditions'} className={classnames(classes.italix, classes.centerItemXS)}>
                Terms &amp; Conditions
            </Link>
        </Grid>
        <Grid container xs={12} sm={2} item alignItems={'center'}>
            <Link to={'/privacyPolicy'} className={classnames(classes.italix, classes.centerItemXS, classes.textRightSMUp)}>
                Privacy Policy
            </Link>
        </Grid>
        <Grid container xs={12} sm={4} item justifyContent={'center'}>
            <img className={classes.img} src={psocFooterImg} />
        </Grid>
        <Grid container xs={12} sm={2} item alignItems={'center'}>
            <Link
                className={classnames(classes.button, classes.centerItemXS)}
                to={{
                    pathname: `https://${config.metaMask.preferredNetwork === 'main' ?
                        '' :
                        `${config.metaMask.preferredNetwork}.`}etherscan.io/address/${config.metaMask.contractAddresses?.parrotPass}`
                    }
                }
                target="_blank">
                {config.metaMask.contractAddresses?.parrotPass.substring(0, 8) + '..'}
            </Link>
        </Grid>
        <Grid xs={12} sm={2} item container>
            <Grid item xs={6} className={classes.iconRight}>
                <IconButton color={'inherit'} href="https://discord.gg/CRD9tvukYP" rel="noreferrer" target="_blank">
                    <Icon>
                        discord
                    </Icon>
                </IconButton>
            </Grid>
            <Grid item xs={6} className={classes.iconLeft}>
                <IconButton color={'inherit'} href="https://twitter.com/ParrotSocialApp" rel="noreferrer" target="_blank">
                    <TwitterIcon />
                </IconButton>
            </Grid>
        </Grid>
    </Grid>;
}

export const OverridableFooter = OverridableComponent('footer', Footer);
