import { Box, Button, Container, Grid, makeStyles, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ITicket } from '@ogsnetwork/opp-api-lib';
import { useMyContests } from '@ogsnetwork/opp-component-lib';
import { MyContestSelectorStatus } from '@ogsnetwork/opp-redux-lib';
import React, { SetStateAction } from 'react';
import { Link, useParams } from 'react-router-dom';
import polyBeach from '../images/polyBeach.png';
import { SimplePagination, SimplePaginationProps, useRoutePage } from '../utils/components/pagination';
import { OverridableSafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';
import { TicketCard } from './ticketCard';

const useStyles = makeStyles(() => ({
    container: {
        width: '84%',
        marginLeft: '8%',
        marginRight: '8%'
    },
    paginationContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    tabContainer: {
        display: 'block',
        margin: '0 auto',
        width: '70%'
    },
    containerSpace: {
        marginBottom: 15,
        marginTop: 15
    },
    noTicketsHeader: {
        marginTop: 25
    }
}));

export interface MyContestsRouteParams {
    status?: MyContestSelectorStatus
}

interface MyContestsSimplePaginationProps extends Omit<SimplePaginationProps, 'to'> {
    status: MyContestSelectorStatus;
    tickets: ITicket[];
}

function MyContestsSimplePagination(props: MyContestsSimplePaginationProps): JSX.Element {
    const { status, tickets, ...simplePaginationProps } = props;
    if (status === 'completed' && (tickets.length > 0 || simplePaginationProps.page > 1)) {
        return <SimplePagination { ...simplePaginationProps } to="/mycontests/completed" />;
    }
    return <></>;
}

export function MyContests(): JSX.Element {
    const { status = 'upcoming' } = useParams<MyContestsRouteParams>();
    const page = useRoutePage();
    const myContests = useMyContests({ status, page });
    const classes = useStyles();
    const [tab, setTab] = React.useState(status);

    const handleTabChange = (event: React.ChangeEvent<Record<string, unknown>>, newTab: SetStateAction<'upcoming' | 'live' | 'completed'>) => {
      setTab(newTab);
    };

    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <Container>
                <Grid container className={classes.containerSpace}>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom>
                            My Entries
                        </Typography>
                    </Grid>
                    <Grid container item xs={6} justify={'flex-end'}>
                        <Button variant={'contained'} startIcon={<RefreshIcon />} onClick={() => myContests.refresh()} color='secondary'>
                            Refresh
                        </Button>
                    </Grid>
                </Grid>
                <Paper className={classes.containerSpace}>
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                        centered
                        variant={'fullWidth'}
                    >
                        <Tab
                            label="Upcoming"
                            component={Link}
                            to={'/mycontests/upcoming'}
                            value={'upcoming'} />
                        <Tab
                            label="Live"
                            component={Link}
                            to={'/mycontests/live'}
                            value={'live'}  />
                        <Tab
                            label="Completed"
                            component={Link}
                            to={'/mycontests/completed'}
                            value={'completed'}  />
                    </Tabs>
                </Paper>
                <Box className={classes.paginationContainer}>
                    <MyContestsSimplePagination
                        status={status}
                        tickets={myContests.tickets}
                        hasNextPage={myContests.hasNextPage}
                        page={page}
                    />
                </Box>
                <Grid container spacing={3}>
                    {myContests.tickets.map((ticket) => (
                        <Grid item xs={12} md={6} lg={4} key={ticket.id}>
                            <TicketCard ticket={ticket} />
                        </Grid>
                    ))}
                </Grid>
                {
                    myContests.tickets.length === 0 &&
                        <Box className={classes.noTicketsHeader}>
                            <Typography variant="h6" gutterBottom align={'center'}>
                                You do not have any { page > 1 && 'more' } { tab } tickets
                            </Typography>
                        </Box>
                }
                { myContests.tickets.length > 0 &&
                    <Box className={classes.paginationContainer}>
                        <MyContestsSimplePagination
                            status={status}
                            tickets={myContests.tickets}
                            hasNextPage={myContests.hasNextPage}
                            page={page}
                        />
                    </Box>
                }
            </Container>
        </OverridableSafeAreaDiv>
    );
}
