import { Divider, Typography } from '@material-ui/core';
import polyBeach from '../../images/polyBeach.png';
import React from 'react';
import { OverridableSafeAreaDiv } from './overridableSafeAreaDiv';
import { CenterScreenView } from './CenterScreenView';
import { useParams } from 'react-router-dom';
import { BrandedLink } from '../components/BrandedLink';

export interface SuccessRouteParams {
    successType: 'purchase' | 'edit'
}

export function Success(): JSX.Element {
    const routeParams = useParams<SuccessRouteParams>();
    let message = 'Congratulations!';
    const explanationContent = <>
        <Typography variant={'h6'}>View your ticket in <BrandedLink to={'/mycontests/upcoming'} text={'My Contests'} /></Typography>
        <Typography variant={'h6'}>- or -</Typography>
        <Typography variant={'h6'}>Purchase more tickets in the <BrandedLink to={'/props/lobby'} text={'Game Lobby'} /></Typography>
    </>;
    switch (routeParams.successType) {
        case 'purchase':
            message = 'You\'ve purchased a ticket!';
            break;
        case 'edit':
            message = 'Your ticket has been edited!';
            break;
    }

    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <CenterScreenView fullHeight>
                <Typography variant={'h4'}>{ message }</Typography>
                <Divider />
                {
                    explanationContent
                }
            </CenterScreenView>
        </OverridableSafeAreaDiv>
    );
}
