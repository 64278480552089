import {
    Button,
    Box,
    Card,
    CircularProgress,
    Divider,
    Grid,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import { useAuthentication, useRegister } from '@ogsnetwork/opp-component-lib';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { config } from '../apps/config';
import polyBeach from '../images/polyBeach.png';
import { CenterScreenView } from '../utils/views/CenterScreenView';
import { Error } from '../utils/views/error';
import { OverridableSafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';
import { CryptoSingleSignOn } from './cryptoSingleSignOn';

interface RegisterCardProps {
    onClose?: () => void;
    noRedirect?: true;
    onClickLoginOpen?: () => void;
}

const useStyles = makeStyles(() => ({
    button: {
        marginTop: 10
    },
    card: {
        width: 350,
        padding: 20,
        borderRadius: 10
    },
    container: {
        height: 'calc(100vh - 120px)'
    },
    cryptoContainer: {
        marginTop: 20,
        marginBottom: 10
    }
}));

export function RegisterScreen(): JSX.Element {
    const classes = useStyles();
    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <CenterScreenView fullHeight>
                <RegisterCard />
            </CenterScreenView>
        </OverridableSafeAreaDiv>
    );
}

export function RegisterCard(props: RegisterCardProps): JSX.Element {
    const classes = useStyles();
    const history = useHistory();
    const onRegister = () => {
        if (typeof props.onClose !== 'undefined') {
            props.onClose();
        }
        if (config.dynamicUserflow.registerDestination && !props.noRedirect) {
            history.push(config.dynamicUserflow.registerDestination);
        }
    };
    const register = useRegister({ onRegister });
    const { status } = useAuthentication();
    const handleKeypress = (e: React.KeyboardEvent) => {
        if (e.charCode === 13) {
            register.dispatchRegister();
        }
    };
    return (
        <Card className={classes.card}>
            <Typography variant={'h3'}>
                Register
            </Typography>
            <Divider />
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="h6">Email</Typography>
                    <TextField
                        id="email"
                        type="email"
                        fullWidth
                        value={register.email}
                        onChange={(event) => register.setEmail(event.target.value)}
                    />
                    <Error error={register.error} errorKey="email" />
                </Grid>
                <Grid item>
                    <Typography variant="h6">State/Province</Typography>
                    <Select
                        id="province"
                        fullWidth
                        value={register.province}
                        onChange={(event) => register.setProvince(event.target.value as string)}
                    >
                        { register.provinces.map((province) => (
                            <MenuItem key={province.abbreviation} value={province.abbreviation}>{province.name}</MenuItem>
                        ))}
                    </Select>
                    <Error error={register.error} errorKey="province" />
                </Grid>
                <Grid item>
                    <Typography variant="h6">Username</Typography>
                    <TextField
                        id="username"
                        type="username"
                        fullWidth
                        value={register.username}
                        onChange={(event) => register.setUsername(event.target.value)}
                    />
                    <Error error={register.error} errorKey="username" />
                </Grid>
                <Grid item>
                    <Typography variant="h6">Password</Typography>
                    <TextField
                        onKeyPress={handleKeypress}
                        id="password"
                        type="password"
                        fullWidth
                        value={register.password}
                        onChange={(event) => register.setPassword(event.target.value)}
                    />
                    <Error error={register.error} errorKey="password" />
                </Grid>
                <Grid item>
                    {
                        status !== 'registering' ?
                            <Button
                                onKeyPress={handleKeypress}
                                fullWidth
                                color="primary"
                                disabled={ register.disableSignup }
                                onClick={() => register.dispatchRegister()}
                                variant="contained">Sign Up</Button>:
                            <Button
                                fullWidth
                                color="primary"
                                variant={'contained'}
                                disabled><CircularProgress size={22} /></Button>
                    }
                    {
                        typeof props.onClickLoginOpen !== 'undefined' ?
                            <Button onClick={props.onClickLoginOpen} className={classes.button} fullWidth color="secondary" variant="text">Login</Button>:
                            <Link to="/login">
                                <Button className={classes.button} fullWidth color="secondary" variant="text">Login</Button>
                            </Link>
                    }
                    <Typography align={'center'}>- or -</Typography>
                    <Box className={classes.cryptoContainer}>
                        <CryptoSingleSignOn cryptoConnect={false} />
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
}
