import { Box, makeStyles, Typography } from '@material-ui/core';
import { usePropBetSelection, UsePropBetSelectionProps } from '@ogsnetwork/opp-component-lib';
import classnames from 'classnames';
import React from 'react';
import { ThemeWithOrgPalette } from '../apps/config';

export type PointsEarnedPropBetProps = UsePropBetSelectionProps;

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    correctContainer: {
        display: 'block',
        margin: '0 auto',
        backgroundColor: theme.orgPalette?.cardOnCard,
        borderLeft: `5px solid ${theme.palette.success.main}`,
        borderRadius: '0 0 5px 5px'
    },
    incorrectContainer: {
        display: 'block',
        margin: '0 auto',
        backgroundColor: theme.orgPalette?.cardOnCard,
        borderRight: `5px solid ${theme.palette.error.main}`,
        borderRadius: '0 0 5px 5px'
    },
    italix: {
        fontStyle: 'italic'
    },
    text: {
        paddingBottom: 10,
        marginBottom: 20,
        paddingTop: 10
    }
}));

export function PointsEarnedPropBet(props: PointsEarnedPropBetProps): JSX.Element {
    const classes = useStyles();
    const propBetSelection = usePropBetSelection(props);
    const status = propBetSelection.selection?.pointsEarned === undefined
        ? 'not scored'
        : props.bet.isPush
            ? 'push'
            : propBetSelection.selection.pointsEarned > 0
                ? 'win'
                : 'lose';
    return (
        <Box className={status === 'win' ? classes.correctContainer : classes.incorrectContainer}>
            <Typography className={classnames(classes.italix, classes.text)} align={'center'}>Points Earned: {propBetSelection.selection?.pointsEarned ?? 0}</Typography>
        </Box>
    );
}
