import React from 'react';
import {
    Box, Card, Container, makeStyles, Typography
} from '@material-ui/core';
import { ThemeWithOrgPalette } from '../../config';
import { OverridableSafeAreaDiv } from '../../../utils/views/overridableSafeAreaDiv';
import polyBeach from '../../../images/polyBeach.png';
import { Link } from 'react-router-dom';
import { BrandedLink } from '../../../utils/components/BrandedLink';

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    card: {
        padding: 20
    },
    containerHeader: {
        background: theme.orgPalette?.cardGradientOpacity,
        padding: 20,
        marginTop: 20,
        borderBottom: `5px solid ${theme.palette.primary.main}`,
        borderTop: `1px solid ${theme.palette.primary.main}`
    },
    link: {
        color: theme.palette.secondary.main
    }
}));

export function SweepsRulesScreen(): JSX.Element {
    const classes = useStyles();
    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <Container>
                <Box className={classes.containerHeader}>
                    <Typography variant={'h4'} gutterBottom>Sweeps Rules</Typography>
                    <Typography>Version - 1.0</Typography>
                    <Typography>Date of last update - 09 March 2022</Typography>
                    <Typography>NO PURCHASE OR PAYMENT NECESSARY TO PARTICIPATE. A PURCHASE OR PAYMENT OF ANY KIND WILL NOT INCREASE A PARTICIPANT’S CHANCES OF WINNING.</Typography>
                </Box>
                <Card className={classes.card}>
                    <Typography>Parrot Social is an online social platform which offers Participants the chance to play games for entertainment and enjoyment. Parrot Social also gives away sweepstakes entries referred to as “Parrot Tokens” as set out in these Sweeps Rules. Parrot Tokens can be used to play sweepstakes games for a chance to win further Parrot Tokens which may be redeemable for real prizes.</Typography>
                    <Typography>These Sweeps Rules are to be read in conjunction with the Parrot Social <BrandedLink to={'/termsAndConditions'} text={'Terms of Use'} />. Terms which are defined in the <BrandedLink to={'/termsAndConditions'} text={'Terms of Use'} /> have the same meaning in these Sweeps Rules.</Typography>
                    <Typography variant={'h6'}>1. Eligibility to play Parrot Social</Typography>
                    <Typography>1.1. The Parrot Social sweepstakes promotion (Sweepstakes) is only open to legal residents of the United States (including the District of Columbia but excluding outlying territories and the State of Washington) and Canada (excluding the province of Quebec), who are at least eighteen (18) years old or the age of majority in their jurisdiction (whichever occurs later) at the time of entry. PARTICIPATION IS VOID WHERE PROHIBITED BY LAW.</Typography>
                    <Typography>1.2. A person who participates in the Sweepstakes is a Participant.</Typography>
                    <Typography>1.3. The Sweepstakes is subject to all applicable federal, state, provincial, territorial and local laws and regulations. It is the sole responsibility of a Participant to determine whether the Sweepstakes is legal and compliant with all regulations in the jurisdiction in which the Participant resides.</Typography>
                    <Typography>1.4. Participation constitutes the Participant’s full and unconditional agreement to these Sweeps Rules and Sponsor’s/Promoter’s decisions, which are final and binding in all matters related to the Sweepstakes.</Typography>
                    <Typography>1.5. Winning a prize is contingent upon fulfilling all requirements set out in these Sweeps Rules.</Typography>
                    <Typography>1.6 Employees of OGS Group, any of their respective affiliates, subsidiaries, holding companies, advertising agencies, or any other company or individual involved with the design, production, execution or distribution of the Sweepstakes and their immediate family (spouse, parents, siblings and children, whether the relationship is by birth, marriage or adoption) and household members (people who share the same residence at least 3 months of the year) are not eligible to participate.</Typography>
                    <Typography variant={'h6'}>2. Sponsor/Promoter</Typography>
                    <Typography>2.1 The Sponsor/Promoter of the Sweepstakes is Open Gaming Solutions Inc. of PO Box 28047 Lansdowne PO Kamloops, BC  V2C 0C9.</Typography>
                    <Typography variant={'h6'}>3. How to collect Parrot Tokens</Typography>
                    <Typography>3.1. To Participate, you must have an active Customer Account. If you do not have a Customer Account, you can create one free of charge via the Parrot Social app or at parrotsocial.club). To enter the Sweepstakes, a Participant must access Parrot Social via the app or web site and sign in to their Customer Account.</Typography>
                    <Typography>3.2. Once they have accessed Parrot Social, there are four potential ways for a Participant in the United States (including the District of Columbia but excluding outlying territories and the State of Washington) and Canada (excluding the province of Quebec) to collect Parrot Tokens:</Typography>
                    <ol type='a'>
                        <li>Receive Parrot Tokens as a free bonus. Each Participant may receive Parrot Tokens as a bonus upon registering</li>
                        <li>Receive Parrot Tokens when entering contests on the Parrot Social App. The Sponsor/Promoter regularly holds Parrot Token contests which Participants can enter by following the instructions provided in the contest.</li>
                        <li>Receive Parrot Tokens by logging into your account. Participants can receive free Parrot Tokens on a daily basis by taking the following steps (Daily Bonus):</li>
                        <ol type='i'>
                            <li>log into your account;</li>
                            <li>if you are eligible, a pop up screen will appear for you to claim your Daily Bonus;</li>
                            <li>click the “Claim” button at the bottom of the Daily Bonus pop up.</li>
                            <li>Participants can only claim a Daily Bonus once per day (the daily clock resets at 12:00 EST).</li>
                        </ol>
                        <li>Receive Parrot Tokens by sending a request by post. Participants can receive free Parrot Tokens by sending a standard post card or piece of white paper (Request Card) which must be 4” x 6”, unfolded, blank and unlined and placed inside a stamped #10 envelope addressed in handwriting, to the following address and satisfying the requirements set out below:</li>
                    </ol>
                    <Typography>United States residents:</Typography>
                    <Typography variant={'caption'}>OGSN INC.</Typography>
                    <Typography variant={'caption'}>PARROT SOCIAL SWEEPSTAKES DEPARTMENT</Typography>
                    <Typography variant={'caption'}>304 S. Jones Blvd #2799</Typography>
                    <Typography variant={'caption'}>Las Vegas NV 89107</Typography>
                    <Typography>Canadian residents:</Typography>
                    <Typography variant={'caption'}>OGS INC.</Typography>
                    <Typography variant={'caption'}>PARROT SOCIAL SWEEPSTAKES DEPARTMENT</Typography>
                    <Typography variant={'caption'}>PO Box 28047 Lansdowne PO</Typography>
                    <Typography variant={'caption'}>Kamloops, BC  V2C 0C9</Typography>
                    <Typography>Participants must:</Typography>
                    <ul>
                        <li>handwrite their return address on the front of the envelope and the words: “Parrot Tokens”; and</li>
                        <li>handwrite all of the following on only one side of the Request Card inserted inside the envelope:</li>
                        <ol type='1'>
                            <li>their full name as shown on their government issued identification;</li>
                            <li>the email address registered to their Parrot Social account;</li>
                            <li>the return/residential address registered to their Parrot Social account; and</li>
                            <li>the following statement:</li>
                        </ol>
                    </ul>
                    <Typography>“I wish to receive Parrot Tokens to participate in the sweepstakes promotions offered by Parrot Social. By submitting this request, I hereby declare that I have read, understood and agree to be bound by Parrot Social’s <BrandedLink to={'/termsAndConditions'} text={'Terms of Use'} /> and Sweeps Rules.”</Typography>
                    <Typography>3.3 There is a limit of one Request Card per outer envelope. For each Request Card a Participant submits in accordance with the above requirements, the Participant will receive 2 Parrot Tokens. The Parrot Tokens will be added to the Participant’s Customer Account. NOTE: A Participant must ensure that their handwriting is legible. If the Participant’s handwriting is not legible, the entry will be void and the Parrot Tokens will not be credited to the Participant’s Customer Account. The legibility of a Participant’s handwriting shall be determined by Sponsor/Promoter in its sole discretion. THE REQUEST MUST ONLY BE MADE BY THE PARTICIPANT AND MUST BE POSTED FROM THE SAME STATE OR PROVINCE AS THE PARTICIPANT’S VERIFIED RESIDENTIAL ADDRESS. Requests made by any other individual or any entity (including but not limited to commercial sweepstakes subscription notification and/or entering services) or posted from a State or Province different to the Participant’s verified residential address will be declared invalid and Parrot Tokens will not be credited to the Participant’s Customer Account. Tampering with the entry process or the operation of the Sweepstakes, including but not limited to the use of any device to automate the Parrot Tokens request/entry process, is prohibited and any requests/entries deemed by Sponsor/Promoter, in its sole discretion, to have been submitted in this manner will be void. In the event a dispute regarding the identity of the individual who actually submitted a request or whether a request satisfies the requirements set out above cannot be resolved to Sponsor/Promoter’s satisfaction, the affected request/entry will be deemed ineligible.</Typography>
                    <Typography>a. The amount of Parrot Tokens a Participant has will be displayed in their Customer Account on the website. The amount of Parrot Tokens to be allocated to Participants can be changed at any time by the Sponsor/Promoter in its sole discretion.</Typography>
                    <Typography>b. Sponsor/Promoter is not responsible for lost, late, incomplete, invalid, unintelligible or misdirected Parrot Token requests or allocations.</Typography>
                    <Typography>c. In the event of a dispute as to any registration of a Customer Account, the authorized account holder of the email address used to register the Customer Account will be deemed to be the Participant. The “authorized account holder” is the natural person assigned the email address by an internet access provider, online service provider or other organization responsible for assigning email addresses for the domain associated with the submitted address.</Typography>
                    <Typography>d. Use of any automated or other system(s) to Participate, to acquire Parrot Tokens or enter contests or play the games is prohibited and will result in disqualification and loss of eligibility to Participate in the contests or games.</Typography>
                    <Typography>e. Parrot Tokens are only valid for sixty (60) days from the date a Participant last logged on to their Customer Account and will then automatically expire.</Typography>
                    <Typography>f. Parrot Tokens may also be forfeited if a Participant’s Customer Account is deactivated for any reason, or at the Sponsor/Promoter’s discretion.</Typography>
                    <Typography variant={'h6'}>4. Using Parrot Tokens to play games</Typography>
                    <Typography>4.1 Participants with Parrot Tokens can use those Parrot Tokens to enter contests or to play games within Parrot Social for a chance to win additional Parrot Tokens. Parrot Tokens won through game play may be redeemed for real prizes.</Typography>
                    <Typography>4.2 Within Parrot Social there are different games. The amount of Parrot Tokens required to play each game will be detailed next to the game.</Typography>
                    <Typography>4.3 Only contests and games played with Parrot Tokens provide the opportunity to redeem winnings for real prizes. The prize that can be won while playing a game will be shown in in the contest details.</Typography>
                    <Typography>4.4 Sponsor’s/Promoter’s decisions as to the administration and operation of the Sweepstakes, the game and the amount of winnings are final and binding.</Typography>
                    <Typography>4.5 Unless Sponsor/Promoter requires otherwise, any Parrot Tokens allocated to a Participant are required to be played once before they are eligible to be redeemed for prizes. Sponsor/Promoter may, in its sole discretion, require that any Parrot Tokens allocated to a Participant must be played a greater number of times (not exceeding 20) in any combination of Parrot Token games before they are eligible to be redeemed for prizes.</Typography>
                    <Typography>4.6 Sponsor/Promoter reserves the right to change the prize win rates and odds of any of the Sweepstakes at any time. A Participant can see the actual and any amended details by clicking on the contest details. It is a Participant’s responsibility to check the prize win rate on each occasion before they participate.</Typography>
                    <Typography variant={'h6'}>5. Verification and confirmation of potential winners</Typography>
                    <Typography>5.1 POTENTIAL SWEEPSTAKES WINNERS ARE SUBJECT TO VERIFICATION BY SPONSOR/PROMOTER (IN ANY MANNER IT MAY CHOOSE) AND THE DECISIONS OF SPONSOR/PROMOTER ARE FINAL AND BINDING IN ALL MATTERS RELATED TO THE SWEEPSTAKES. A PARTICIPANT IS NOT A WINNER OF ANY PRIZE, EVEN IF THE ONLINE SCREEN INDICATES THEY ARE, UNLESS AND UNTIL THE PARTICIPANT’S ELIGIBILITY AND THE POTENTIAL WINNING PLAY HAS BEEN VERIFIED AND THE PARTICIPANT HAS FULLY COMPLIED WITH THESE SWEEPS RULES AND BEEN NOTIFIED THAT VERIFICATION IS COMPLETE. SPONSOR/PROMOTER WILL NOT ACCEPT SCREEN SHOTS OR OTHER PURPORTED EVIDENCE OF WINNING IN LIEU OF ITS VALIDATION PROCESS.</Typography>
                    <Typography>5.2 Potential prize winners must comply with these Sweeps Rules and winning is contingent upon fulfilling all requirements.</Typography>
                    <Typography>5.3 A potential prize winner may be required to sign and return to Sponsor/Promoter, an affidavit/declaration of eligibility, and liability/publicity release (except where prohibited) in order to claim his/her prize (if applicable). In addition, in the case of a Canadian eligible winner, he/she will be required to correctly answer a mathematical skill-testing question without mechanical or other aid to be administered at a pre-arranged mutually convenient time.</Typography>
                    <Typography>5.4 If a potential winner cannot be contacted, fails to properly execute and return the affidavit/declaration of eligibility and liability/publicity release within the required time period (if applicable), fails to comply with these Sweeps Rules (including, but not limited to, the requirement to correctly answer the mathematical skill-testing question if a resident of Canada), or if the prize or prize notification is returned as undeliverable, that potential winner forfeits the prize.</Typography>
                    <Typography>5.5 In the event that a potential winner of a Sweepstakes prize is disqualified for any reason, Sponsor/Promoter may, in its sole discretion, reseed the prize back into the Sweepstakes.</Typography>
                    <Typography variant={'h6'}>6. Prizes</Typography>
                    <Typography>6.1 A Participant’s Parrot Tokens balance is shown in the Participant’s Customer Account.</Typography>
                    <Typography>6.2 Where a Participant has chosen to redeem prizes for gift cards, the gift cards will be allocated to the email address that the Participant has registered against their Customer Account.</Typography>
                    <Typography>6.3 TO BE ELIGIBLE FOR A PARROT TOKEN PRIZE OR A PRIZE REDEMPTION:</Typography>
                    <Typography>6.4 A PARTICIPANT MUST BE A LEGAL RESIDENT OF THE UNITED STATES (INCLUDING THE DISTRICT OF COLUMBIA BUT EXCLUDING OUTLYING TERRITORIES AND THE STATE OF WASHINGTON) OR CANADA (EXCLUDING THE PROVINCE OF QUEBEC); AND</Typography>
                    <Typography>6.5 THE PARTICIPANT’S DETAILS MUST MATCH THOSE OF THE PARTICIPANT’S ACCOUNT.</Typography>
                    <Typography>6.6 In New York and Florida, the maximum redemption value of a Parrot Token prize won in any one contest, via a Participant’s participation in the Sweepstakes is US$5,000. Any redemption of a prize valued in excess of US$5,000 will not be allocated or paid.</Typography>
                    <ol type='a'>
                        <li>Sponsor/Promoter reserves the right, in its sole discretion, to limit a Participant’s redemption of Prizes to US$10,000 per day. No more than the stated number of prizes will be awarded.</li>
                        <li>Sponsor/Promoter is not responsible for any taxes or fees associated with a prize redemption. Participants are responsible for all applicable taxes and fees associated with prize receipt and/or redemption.</li>
                        <li>Sponsor/Promoter is not responsible for any foreign exchange transaction fees, charges or related costs that may be incurred as a result of, or in relation to, a prize redemption, including but not limited to any losses or additional costs arising from foreign exchange fluctuations.</li>
                        <li>Parrot Tokens are not transferable and no substitution will be made except as provided herein at the Sponsor’s/Promoter’s sole discretion. Sponsor/Promoter reserves the right to substitute the listed prize of equal or greater value for any reason owing to circumstances outside Sponsor’s/Promoter’s reasonable control.</li>
                        <li>Prize winners will be notified by private message or email.</li>
                    </ol>
                    <Typography>6.7 Parrot Tokens have no cash value. Where Parrot Tokens are exchanged for gift cards using PrizeOut, the value of a token is $0.0025.</Typography>
                    <Typography variant={'h6'}>7. Entry conditions and release</Typography>
                    <Typography>7.1 By Participating, each Participant agrees to:</Typography>
                    <ol type='a'>
                        <li>comply with and be bound by:</li>
                        <ol type='i'>
                            <li>the <BrandedLink to={'/termsAndConditions'} text={'Terms of Use'} />;</li>
                            <li>these Sweeps Rules; and</li>
                            <li>the decisions of the Sponsor/Promoter which are binding and final;</li>
                        </ol>
                        <li>release and hold harmless the Sponsor/Promoter and its parent, subsidiary, and affiliated companies, the prize suppliers and any other organizations responsible for sponsoring, fulfilling, administering, advertising or promoting the Sweepstakes, and all of their respective past and present officers, directors, employees, agents and representatives (collectively, the Released Parties) from and against any and all claims, expenses, and liability, including but not limited to negligence and damages of any kind to persons and property, including but not limited to invasion of privacy (under appropriation, intrusion, public disclosure of private facts, false light in the public eye or other legal theory), defamation, slander, libel, violation of right of publicity, infringement of trademark, copyright or other intellectual property rights, property damage, or death or personal injury arising out of or relating to a Participant’s entry, creation of an entry or submission of an entry, participation in the Sweepstakes, acceptance or use or misuse of prizes (including any travel or activity related thereto) and/or the broadcast, exploitation or use of entry; and</li>
                        <li>indemnify, defend and hold harmless the Sponsor/Promoter from and against any and all claims, expenses, and liabilities (including reasonable attorneys/legal fees) arising out of or relating to a Participant’s participation in the Sweepstakes and/or Participant’s acceptance, use or misuse of prizes.</li>
                    </ol>
                    <Typography variant={'h6'}>8. Publicity</Typography>
                    <Typography>8.1 Except where prohibited, participation in the Sweepstakes constitutes each Participant’s consent to Sponsor’s/Promoter’s and its agents’ use of Participant’s name, likeness, photograph, voice, opinions and/or hometown and state/province/territory for promotional purposes in any media, worldwide, without further payment, notice or consideration.</Typography>
                    <Typography variant={'h6'}>9. General Conditions</Typography>
                    <Typography>9.1 Sponsor/Promoter reserves the right to cancel, suspend and/or modify the Sweepstakes or these Sweeps Rules, or any part of the Sweepstakes or these Sweeps Rules, with immediate effect owing to circumstances outside its reasonable control and only where circumstances make it unavoidable if any fraud, technical failures or any other factor beyond Sponsor’s/Promoter’s reasonable control impairs the integrity or proper functioning of the Sweepstakes, as determined by Sponsor/Promoter in its sole discretion.</Typography>
                    <Typography>9.2 Sponsor/Promoter reserves the right in its sole discretion to disqualify any individual it finds to be tampering with the entry process or the operation of the Sweepstakes or to be acting in violation of these Sweeps Rules or any other promotion or in an unsportsmanlike or disruptive manner.</Typography>
                    <Typography>9.3 Any attempt by any person to deliberately undermine the legitimate operation of the Sweepstakes may be a violation of criminal and civil law and, should such an attempt be made, Sponsor/Promoter reserves the right to seek damages from any such person to the fullest extent permitted by law. Sponsor’s/Promoter’s failure to enforce any term of these Sweeps Rules shall not constitute a waiver of that provision.</Typography>
                    <Typography>9.4 In all other cases, the Sponsor/Promoter reserves the right to cancel, suspend and/or modify the Sweepstakes. Any notice regarding cancellation, suspension and/or modification will be posted on the Parrot Social web site  at <a className={classes.link} href='https://parrotsocial.app' target='_blank' rel='noreferrer'>https://parrotsocial.app</a></Typography>
                    <Typography>9.5 In the event of modifying the Sweepstakes, a Participant’s continued enrolment and/or participation in the Sweepstakes constitutes acceptance of the modified terms.</Typography>
                    <Typography variant={'h6'}>10. Limitations of liability</Typography>
                    <Typography>10.1 Insofar as permitted by law in the respective territories, the Released Parties (as defined above) are not responsible for:</Typography>
                    <ol type='a'>
                        <li>any incorrect or inaccurate information, whether caused by a Participant, printing error or by any of the equipment or programming associated with or utilized in the Sweepstakes;</li>
                        <li>technical failures of any kind, including, but not limited to malfunctions, interruptions, or disconnections in phone lines or network hardware or software;</li>
                        <li>unauthorized human intervention in any part of the entry process or the Sweepstakes;</li>
                        <li>technical or human error which may occur in the administration or operation of the Sweepstakes; or</li>
                        <li>any injury or damage to persons or property which may be caused, directly or indirectly, in whole or in part, from Participant’s participation in the Sweepstakes or receipt or use or misuse of any prize.</li>
                    </ol>
                    <Typography>10.2 If for any reason a Participant’s Parrot Tokens or winning play is confirmed to have been erroneously deleted, lost, or otherwise destroyed or corrupted, Participant’s sole remedy is replacement by Sponsor/Promotor of an equivalent amount of Parrot Tokens.</Typography>
                    <Typography variant={'h6'}>11. Disputes</Typography>
                    <Typography>11.1 Except where prohibited by law, as a condition of participating in this Sweepstakes, each Participant agrees that:</Typography>
                    <ol type='a'>
                        <li>any and all disputes and causes of action arising out of or connected with this Sweepstakes, including but not limited to prizes awarded, shall be resolved individually, without resort to any form of class action, and exclusively by final and binding arbitration under the rules of the American Arbitration Association and held at the AAA regional office nearest the Participant (or for Canadian residents the International Centre for Dispute Resolution);</li>
                        <li>the Federal Arbitration Act shall govern the interpretation, enforcement and all proceedings at such arbitration; and</li>
                        <li>judgment upon such arbitration award may be entered in any court having jurisdiction.</li>
                    </ol>
                    <Typography>11.2 Under no circumstances will any Participant be permitted to obtain awards for, and Participant hereby waives all rights to claim, punitive, incidental or consequential damages, or any other damages, including attorneys’ fees, other than Participant’s actual out-of-pocket expenses (i.e., costs associated with participating in this Sweepstakes), and Participant further waives all rights to have damages multiplied or increased. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE MAY NOT APPLY TO YOU.</Typography>
                    <Typography>11.3 All issues and questions concerning the construction, validity, interpretation and enforceability of these Sweeps Rules, or the rights and obligations of the Participant and Sponsor/Promoter in connection with the Sweepstakes, are governed by, and construed in accordance with, the laws of the State of Delaware, without giving effect to any choice of law or conflict of law rules (whether of the State of Delaware or any other jurisdiction), which would cause the application of the laws of any jurisdiction other than the State of Delaware.</Typography>
                    <Typography>11.4 To ensure fairness and the integrity of the promotion to all Participants, the Sponsor/Promoter will respond to questions to Customer Support via this <a className={classes.link} href='https://parrotsocial.app/contact/' target='_blank' rel='noreferrer'>form</a> and may post updates/communications on its Facebook page.</Typography>
                    <Typography>11.5 Any Participant posting or seen to be posting comments on Sponsor’s/Promoter’s Facebook page or elsewhere during the promotion that are considered bullying, spiteful or upsetting to other Participants, players and fans of Parrot Social or directly aimed at the Sponsor/Promoter, will have their comments removed and will be disqualified from the Sweepstakes. The Sponsor/Promoter reserves the right to alert Facebook to any such behaviour and Participant may have his/her Facebook account frozen pending investigation.</Typography>
                    <Typography variant={'h6'}>12. Participant’s personal information</Typography>
                    <Typography>12.1 Information collected from Participants is subject to the Sponsor’s/Promoter’s <BrandedLink to={'/privacyPolicy'} text={'Privacy Policy'} />.</Typography>
                    <Typography variant={'caption'}>Copyright © 2020- 2022 Parrot Social. All rights reserved. Parrot Social is owned and operated by Open Gaming Solutions Inc.. All payments are processed by Open Gaming Solutions Inc. or Open Gaming Sports Network Inc.. The sweepstakes promotions and prizes offered at Parrot Social are operated by Open Gaming Solutions Inc.. The registered address of Open Gaming Solutions is PO Box 28047 Lansdowne PO Kamloops, BC V2C 0C9.</Typography>
                </Card>
            </Container>
        </OverridableSafeAreaDiv>
    );
}
