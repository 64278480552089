import {Box, Theme, withStyles, WithStyles} from '@material-ui/core';
import MintView from './mint';
import React from 'react';
import '../index.css';
import './style.css';

const styles = (theme: Theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderColor: '#c5395f'
        }
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    select: {
        color: 'white',
        '&:before': {
            borderColor: '#c5395f',
        },
        '&:after': {
            borderColor: '#c5395f',
        },
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
    },
    tab: {
        color: theme.palette.primary.main
    },
    outline: {
        color: 'white'
    }
});

class MintScreen extends React.PureComponent<WithStyles<typeof styles>> {

    public render(): React.ReactNode {
        return (
            <Box className={'mint-screen-background'}>
                <MintView />
            </Box>
        );
    }
}

export default withStyles(styles, { withTheme: true })(MintScreen);
