import { IconButton, makeStyles, Grid } from '@material-ui/core';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';

export interface SimplePaginationProps {
    hasNextPage: boolean;
    page: number;
    to: string;
}

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        }
    },
    buttonContainer: {
        width: 75,
        marginTop: 20,
        marginBottom: 20
    }
}));

export function SimplePagination(props: SimplePaginationProps): JSX.Element {
    const classes = useStyles();
    const { hasNextPage, page, to } = props;
    return (
        <Grid container className={classes.buttonContainer}>
            <Grid item xs={6}>
                <IconButton
                    className={classes.button}
                    component={Link}
                    disabled={page === 1}
                    to={`${to}/${page - 1}`}
                    size={'small'}
                >
                    <ArrowBack />
                </IconButton>
            </Grid>
            <Grid item xs={6} container justify={'flex-end'}>
                <IconButton
                    className={classes.button}
                    component={Link}
                    disabled={!hasNextPage}
                    to={`${to}/${page + 1}`}
                    size={'small'}
                >
                    <ArrowForward />
                </IconButton>
            </Grid>
        </Grid>
    );
}

export interface PaginationRouteParams {
    page: string;
}

export function useRoutePage() {
    const { page: rawPage = '1' } = useParams<PaginationRouteParams>();
    return parseInt(rawPage, 10);
}
