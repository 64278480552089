import { Grid } from '@material-ui/core';
import { useAppDispatch, useAuthentication, usePrevious } from '@ogsnetwork/opp-component-lib';
import { attemptExistingSessionAuthorization } from '@ogsnetwork/opp-redux-lib';
import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { config } from '../apps/config';
import { OverridableFooter } from '../footer/footer';
import ScrollToTop from '../utils/components/scrollToTop';
import { OverridableAppNavigationBar } from './overridableAppNavigationBar';

/**
 * Optionally include an AppNavigationMenuItem into an AppNavigationRoute for it to show up in a navigation
 * menu; currently there is only one type of menu, though it is conceivable that other menus could exist
 *
 * If using a route with route params eg. /transactions/:page the `path` will be used in the navigation menu
 * item. Without a `path` the menu item link will look like eg. <a href="/transactions/:page">
 * While using a `path` the menu item link will be whatever its value is eg. <a href="/transactions/1">
 */
export interface AppNavigationConfig {
    path?: string;
    label: string;
    type: 'topNavigation';
}

/**
 * `AppNavigationMenuItem` requires path to be defined while the `AppNavigationConfig` allows path to be optional;
 * The `AppNavigationMenuItem` is intended to be used with Navigation components, while `AppNavigationConfig` is
 * intended to reduce verbosity in configs.
 *
 * The `AppRoutes` component will ensure the an `AppNavigationRoute` with `AppNavigationMenuItem` is passed to
 * Navigation components
 */
export interface AppNavigationMenuItem extends AppNavigationConfig {
    path: string;
}

/**
 * use `default = true` for one each authenticated = true and authenticated = false; if more than one `default` is
 * provided for each authenticated state, the first `default` will be used; if no `default` is provided,
 * the first `default = false` will be used
 *
 * `path` with hashes: if a screen has navigable anchor elements and it is desirable for those anchors to show in
 * a menu, the same `component` with different `navigation` and `path` may be declared. Give `navigation.label` a
 * unique name and set the desired hash in `path` eg. `/#team`
 *
 * Note: to ensure hashes work properly use `<a href='/#team'>...` only within the same component; use
 * `import { HashLink as Link } from 'react-router-hash-link';` when needing to navigate to anchor elements in
 * other screens. React Router Dom's own `Link` doesn't handle the scroll properly
 *
 * Note: be mindful of how the paths are defined. eg. `/` will be an inexact match for everything! To remedy this
 * effect, use the `exact: true` property. Sometimes having inexact path may be desirable for something like
 * a `ContestScreen` eg. `/contest/:contestId/`
 */
export interface AppNavigationRouteConfig {
    authenticated?: true;  // allow route to be visible and navigable while authenticated
    unauthenticated?: true;  // allow route to be visible and navigable while not authenticated
    external?: true;  // a route with an external url
    component?: typeof React.Component | React.FunctionComponent;
    default?: true;
    exact?: true;
    icon?: string;
    highlight?: true;
    navigation?: AppNavigationConfig;
    requiresLogin?: true;
    path: string;
}

export interface AppNavigationRoute extends AppNavigationRouteConfig {
    navigation?: AppNavigationMenuItem;
}

export interface AppNavigationRouteWithMenuItem extends AppNavigationRouteConfig {
    navigation: AppNavigationMenuItem;
}

export function AppRoutes(): JSX.Element {
    function routeExactBoolToNumber(value: boolean | undefined): number {
        return (value ?? false) ? -1 : 1;
    }

    /**
     * sort routes with route.exact path first
     */
    function sortCompareRouteExact(aRoute: AppNavigationRoute, bRoute: AppNavigationRoute): number {
        return routeExactBoolToNumber(aRoute.exact) - routeExactBoolToNumber(bRoute.exact);
    }

    const routes: AppNavigationRoute[] = config.navigation.routes.map((route) => {
        if (route.navigation) {
            const mappedRoute: AppNavigationRoute = {
                ...route,
                navigation: {
                    ...route.navigation,
                    path: route.navigation?.path ?? route.path
                }
            };
            return mappedRoute;
        }

        // if `route.navigation` is undefined then `AppNavigationRouteConfig` is same as `AppNavigationRoute`
        return route as AppNavigationRoute;
    });
    const authenticatedRoutes: AppNavigationRoute[] = routes
        .filter((route) => route.authenticated);

    const defaultAuthenticatedRoute: AppNavigationRoute = authenticatedRoutes.find(
        (route) => (route.default ?? false)
    ) ?? authenticatedRoutes[0];

    const unauthenticatedRoutes: AppNavigationRoute[] = routes
        .filter((route) => route.unauthenticated);

    const defaultUnauthenticatedRoute: AppNavigationRoute = unauthenticatedRoutes.find(
        (route) => (route.default ?? false)
    ) ?? unauthenticatedRoutes[0];

    const dispatch = useAppDispatch();
    const { status: authenticationStatus } = useAuthentication();
    const previousAuthenticationStatus = usePrevious(authenticationStatus);

    useEffect(() => {
        dispatch(attemptExistingSessionAuthorization());
    }, []);

    const startingNewSession = previousAuthenticationStatus === 'init';
    const authenticated = authenticationStatus === 'loggedIn' || authenticationStatus === 'success';
    const pendingAuthentication = ['init', 'submitting', 'registering'].includes(authenticationStatus);
    const notAuthenticated = ['error', 'loggedOut'].includes(authenticationStatus);

    function isRouteWithMenuItem(route: AppNavigationRoute | AppNavigationRouteWithMenuItem): route is AppNavigationRouteWithMenuItem {
        return typeof (route as AppNavigationRouteWithMenuItem).navigation !== 'undefined';
    }

    /**
     * if starting a new session (eg. first visit or browser refresh) then pass the authenticatedRoutes to the
     * OverridableAppNavigationBar; otherwise, pass the unauthenticatedRoutes to the OverridableAppNavigationBar
     *
     * Doing this lets the OverridableAppNavigationBar render the authenticated routes while an authentication request
     * is pending and beginning a new session. If a session is already underway and a user logs in, then the
     * authenticated routes are hidden while the request is pending. The `pendingAuthentication && startingNewSession`
     * is just little bit of UX logic
     */
    const appNavigationRoutes: AppNavigationRouteWithMenuItem[] = [];
    ((authenticated || pendingAuthentication && startingNewSession) ?
        authenticatedRoutes : unauthenticatedRoutes).forEach((route) => {
        if (isRouteWithMenuItem(route)) {
            appNavigationRoutes.push(route);
        }
    });

    return (
        <BrowserRouter>
            <ScrollToTop />
            <OverridableAppNavigationBar routes={appNavigationRoutes} />
            <Grid container direction={'column'}>
                <Grid item>
                    <Switch>
                        {
                            routes.filter((route) => !(route.external ?? false)).map((route) => {
                                const routeProps = {
                                    exact: route?.exact ?? false,
                                    key: route.path,
                                    path: route.path.split('#')[0]
                                };
                                const routeComponentProp = { component: route.component };

                                if (pendingAuthentication) {
                                    return <Route { ...routeComponentProp } { ...routeProps } />;
                                } else if (authenticated) {
                                    if (route.authenticated) {
                                        return <Route { ...routeComponentProp } { ...routeProps } />;
                                    } else {
                                        return (
                                            <Route { ...routeProps }>
                                                <Redirect to={defaultAuthenticatedRoute.path} />
                                            </Route>
                                        );
                                    }
                                } else if (notAuthenticated) {
                                    if (route.unauthenticated) {
                                        return <Route { ...routeComponentProp } { ...routeProps } />;
                                    } else {
                                        return (
                                            <Route { ...routeProps }>
                                                <Redirect to={defaultUnauthenticatedRoute.path} />
                                            </Route>
                                        );
                                    }
                                }
                            })
                        }
                        <Route
                            exact
                            path="/"
                            render={() => {
                                if (notAuthenticated) {
                                    return (<Redirect to={defaultUnauthenticatedRoute.path} />);
                                } else if (authenticated) {
                                    return (<Redirect to={defaultAuthenticatedRoute.path} />);
                                }
                            }}
                        />
                    </Switch>
                </Grid>
                <Grid item>
                    <OverridableFooter />
                </Grid>
            </Grid>
        </BrowserRouter>
    );
}
