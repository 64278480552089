import { StyledComponentProps } from '@material-ui/core';
import { AppNavigationBarProps } from '../navigation/overridableAppNavigationBar';
import { SafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';
import { components as iratethComponents } from './irateth/components';
import { components as parrotComponents } from './parrot/components';
import { components as parrotGamesAppComponents } from './parrotGames/components';

/**
 * copied from the `withStyles` return type from a MaterialUI typescript definition file
 * if additional higher order component types need to be supported for overridable component, then
 * expand upon this definition
 */
export type AppComponent<Props> = React.ComponentType<Pick<Props, keyof Props> & StyledComponentProps>

export interface AppConfigComponents {
    appNavigationBar?: AppComponent<AppNavigationBarProps>;
    footer?: AppComponent<never>;
    safeAreaDiv?: typeof SafeAreaDiv;
}

export let components: AppConfigComponents;
switch (process.env.REACT_APP_CONFIG ?? 'parrot') {
    case 'irateth':
        components = iratethComponents;
        break;
    case 'parrotGames':
        components = parrotGamesAppComponents;
        break;
    case 'parrot':
    default:
        components = parrotComponents;
}
