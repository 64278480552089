import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
    Box, Button, Card, CircularProgress, Container, Divider, Grid, Hidden, LinearProgress, makeStyles, SvgIcon, Typography
} from '@material-ui/core';
import { EVENT_STATUS_OPEN, ISelectionBet } from '@ogsnetwork/opp-api-lib';
import { useAuthentication, useAppDispatch, useAppSelector, useCustomer, useDraftPropBet, useTicketFromEvent } from '@ogsnetwork/opp-component-lib';
import { fetchAllPropEventTickets, fetchEvent } from '@ogsnetwork/opp-redux-lib';

import { PrizeModal } from '../utils/prizeModal';
import { OverridableSafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';
import defaultPropGameImage from './media/defaultPropGameImage.png';
import {
    PropBetSelectionDropDown, PropBetSelectionFreeForm, PropBetSelectionHeadToHead
} from './selection';
import { config } from '../apps/config';
import { Error } from '../utils/views/error';
import { ThemeWithOrgPalette } from '../apps/config';
import { CenterScreenView } from '../utils/views/CenterScreenView';
import polyBeach from '../images/polyBeach.png';
import { Timestamp } from '../utils/components/timestamp';
import { AuthenticationModal } from '../authentication/login';
import { NFTPopupModal } from './nftPopupModal';
import { BrandedIcon } from '../utils/components/icon';
import { FormatTokensForDisplay } from '../utils/tokenFormatter';
import { BrandedLink } from '../utils/components/BrandedLink';

export interface DraftPropBetRouteParams {
    eventId: string;
}

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    button: {
        width: 150,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20
        },
        fontWeight: 'bold'
    },
    buttonFooterGridContainer: {
        alignItems: 'center',
        marginTop: 20
    },
    nftButton: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 20
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: 20
        },
        fontWeight: 'bold'
    },
    bottomButton: {
        width: 150,
        fontWeight: 'bold'
    },
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        padding: 20,
    },
    noEventContainer: {
        height: 'calc(100vh - 120px)'
    },
    icon: {
        position: 'relative',
        bottom: 1,
        fontSize: 14,
        height: '1em',
        width: '1em'
    },
    italix: {
        color: '#676767',
        fontStyle: 'italic'
    },
    propBetContainerHeader: {
        background: theme.orgPalette?.cardGradientOpacity,
        padding: 20,
        marginTop: 20,
        borderTop: `1px solid ${theme.palette.primary.main}`
    },
    gridItemCenterXS: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            textAlign: 'center'
        }
    },
    gridItemRightSM: {
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'right',
            textAlign: 'right'
        }
    },
    centerTextXS: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        }
    },
    masonryBox: {
        padding: 20
    },
    masonryGrid: {
        display: 'flex',
        marginLeft: -30,
        width: 'auto'
    },
    masonryGridColumn: {
        paddingLeft: 30,
        backgroundClip: 'paddingBox'
    }
}));

const breakPoints = {
    default: 3,
    1100: 2,
    700: 1
};

function DraftPropBetErrors(props: {draftPropBet: ReturnType<typeof useDraftPropBet>}) {
    const { draftPropBet } = props;
    return (
        <>
            <Error error={draftPropBet.error} errorKey="general" />
            <Error error={draftPropBet.error} errorKey="event" />
            <Error error={draftPropBet.error} errorKey="user" />
        </>
    );
}

export function DraftPropBet(): JSX.Element {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const routeParams = useParams<DraftPropBetRouteParams>();
    const eventId = parseInt(routeParams.eventId, 10);
    useEffect(() => {
        dispatch(fetchEvent(eventId));
    }, [eventId]);
    const event = useAppSelector((state) => state.events.byId[eventId]);
    const onLogin = () => {
        dispatch(fetchAllPropEventTickets());
    };
    useAuthentication({ onLogin });
    const { ticket } = useTicketFromEvent({ event });
    const { customer } = useCustomer();
    const history = useHistory();
    const [showAuthenticationModal, setShowAuthenticationModal] = useState<boolean>(false);
    const draftPropBet = useDraftPropBet({
        event,
        ticket,
        onEventClose: () => null,
        onEventFinalize: () => null,
        onSave: () => null,
        onEdit: () => {
            if (config.dynamicUserflow.editTicketPurchaseDestination) {
                history.push(config.dynamicUserflow.editTicketPurchaseDestination);
            }
        },
        onPurchase: () => {
            if (config.dynamicUserflow.ticketPurchaseDestination) {
                history.push(config.dynamicUserflow.ticketPurchaseDestination);
            }
        }
    });

    if (!event) {
        return (
            <OverridableSafeAreaDiv imageSrc={polyBeach}>
                <CenterScreenView className={classes.noEventContainer}>
                    <Typography variant={'h4'}>Event is not open at this time</Typography>
                    <Divider />
                    <Typography variant={'h6'}>You can view your tickets in <BrandedLink to={'/mycontests/upcoming'} text={'My Contests'} /></Typography>
                    <Typography variant={'h6'}>- or -</Typography>
                    <Typography variant={'h6'}>Purchase more tickets in the <BrandedLink to={'/props/lobby'} text={'Game Lobby'} /></Typography>
                </CenterScreenView>
            </OverridableSafeAreaDiv>
        );
    }

    const requiresNFTs = typeof event.requiredNFTs !== 'undefined' && event.requiredNFTs.length > 0;
    return (
        <OverridableSafeAreaDiv imageSrc={event.infoGraphicUrl ?? defaultPropGameImage}>
            <Container>
                <Grid className={classes.propBetContainerHeader} container>
                    <Grid xs={12} sm={6} container item>
                        <Grid xs={12} direction={'column'} item>
                            <Typography className={classes.centerTextXS} variant="h4" gutterBottom>{event.description}</Typography>
                            <Timestamp timestamp={event.closeTs} variant={'h5'}/>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={6} container item>
                        <Grid xs={12} direction={'column'} item className={classes.gridItemRightSM}>
                            <Typography className={classes.centerTextXS} variant="h5"><span className={classes.italix}>cost: </span> 
                            {FormatTokensForDisplay(event.ticketCost)} <BrandedIcon className={classes.icon} icon={config.icons.pointsIcon} /></Typography>
                            <Typography className={classes.centerTextXS} variant="h5"><span className={classes.italix}>picks:</span> {draftPropBet.selections.length}/{draftPropBet.bets.length}</Typography>
                            <Typography className={classes.centerTextXS} variant="h5"><span className={classes.italix}>entrants:</span> {event.ticketCount}/{event.ticketMax}</Typography>
                            { !event.isGuaranteed && <Typography className={classes.centerTextXS} variant="h5"><span className={classes.italix}>min entrants required:</span> {event.ticketMin}</Typography> }
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={8} item className={classes.gridItemCenterXS} direction={'row'} spacing={2}>
                        <PrizeModal buttonClassName={classes.button} event={event} variant={'outlined'} />
                        {
                            requiresNFTs && <NFTPopupModal buttonClassName={classes.nftButton} event={event} buttonVariant={'outlined'} variant={'button'} />
                        }
                    </Grid>
                    <Hidden xsDown>
                        <Grid xs={12} sm={4} item className={classNames(classes.gridItemCenterXS, classes.gridItemRightSM)}>
                            {
                                typeof customer !== 'undefined' ?
                                    draftPropBet.ticketStatus !== 'editing' && draftPropBet.ticketStatus !== 'purchasing' ?
                                        <Button
                                            className={classes.button}
                                            variant={'contained'}
                                            color={'secondary'}
                                            disabled={draftPropBet.selections.length !== draftPropBet.bets.length}
                                            onClick={() => draftPropBet.save()}>{ticket ? 'Edit' : 'Submit'}</Button> :
                                        <Button
                                            className={classes.button}
                                            variant={'contained'}
                                            color={'secondary'}
                                            disabled>
                                            <CircularProgress size={22}></CircularProgress>
                                        </Button> :
                                    <Button
                                        onClick={() => { setShowAuthenticationModal(true); }}
                                        className={classes.button}
                                        variant={'contained'}
                                        color={'secondary'}>
                                        Enter
                                    </Button>
                            }
                        </Grid>
                    </Hidden>
                    <DraftPropBetErrors draftPropBet={draftPropBet} />
                </Grid>
                <Card>
                    <LinearProgress variant={'determinate'} value={(draftPropBet.selections.length / draftPropBet.bets.length) * 100}></LinearProgress>
                    <Box className={classes.masonryBox}>
                        <Masonry
                            breakpointCols={breakPoints}
                            className={classes.masonryGrid}
                            columnClassName={classes.masonryGridColumn}
                        >
                            {
                                typeof draftPropBet.bets === 'undefined' ?
                                    <CircularProgress></CircularProgress> :
                                    <></>
                            }
                            {
                                typeof ticket === 'undefined' ?
                                    draftPropBet.bets.map((bet) => (
                                        <Box key={bet.id}>
                                            {bet.widgetChoice === 'radio_image' &&
                                                <PropBetSelectionHeadToHead
                                                    bet={bet}
                                                    event={event}
                                                    editable={event.status === EVENT_STATUS_OPEN}
                                                    propBetComponent={draftPropBet}
                                                    ticket={ticket} />
                                            }
                                            {['free_form_int', 'free_form_float'].includes(bet.widgetChoice) &&
                                                <PropBetSelectionFreeForm
                                                    bet={bet}
                                                    event={event}
                                                    editable={event.status === EVENT_STATUS_OPEN}
                                                    propBetComponent={draftPropBet}
                                                    ticket={ticket} />
                                            }
                                            {bet.widgetChoice === 'name_dropdown' &&
                                                <PropBetSelectionDropDown
                                                    bet={bet}
                                                    event={event}
                                                    editable={event.status === EVENT_STATUS_OPEN}
                                                    propBetComponent={draftPropBet}
                                                    ticket={ticket} />
                                            }
                                        </Box>
                                    )) :
                                    draftPropBet.bets.length > 0 && draftPropBet.selections.length > 0 ?
                                        draftPropBet.bets.map((bet) => (
                                            <Box key={bet.id}>
                                                {bet.widgetChoice === 'radio_image' &&
                                                    <PropBetSelectionHeadToHead
                                                        bet={bet}
                                                        event={event}
                                                        editable={event.status === EVENT_STATUS_OPEN}
                                                        propBetComponent={draftPropBet}
                                                        ticket={ticket} />
                                                }
                                                {['free_form_int', 'free_form_float'].includes(bet.widgetChoice) &&
                                                    <PropBetSelectionFreeForm
                                                        bet={bet}
                                                        event={event}
                                                        editable={event.status === EVENT_STATUS_OPEN}
                                                        propBetComponent={draftPropBet}
                                                        ticket={ticket} />
                                                }
                                                {bet.widgetChoice === 'name_dropdown' &&
                                                    <PropBetSelectionDropDown
                                                        bet={bet}
                                                        event={event}
                                                        editable={event.status === EVENT_STATUS_OPEN}
                                                        propBetComponent={draftPropBet}
                                                        ticket={ticket} />
                                                }
                                            </Box>
                                        )) :
                                    <></>

                            }
                        </Masonry>
                        <Hidden smUp>
                            <Grid xs={12} item>
                                <DraftPropBetErrors draftPropBet={draftPropBet} />
                            </Grid>
                            <Grid xs={12} sm={6} item className={classNames(classes.gridItemCenterXS, classes.gridItemRightSM)}>
                                {
                                    typeof customer !== 'undefined' ?
                                        draftPropBet.ticketStatus !== 'editing' && draftPropBet.ticketStatus !== 'purchasing' ?
                                            <Button
                                                className={classes.button}
                                                variant={'contained'}
                                                color={'secondary'}
                                                disabled={draftPropBet.selections.length !== draftPropBet.bets.length}
                                                onClick={() => draftPropBet.save()}>{ticket ? 'Edit' : 'Submit'}</Button> :
                                            <Button
                                                className={classes.button}
                                                variant={'contained'}
                                                color={'secondary'}
                                                disabled>
                                                <CircularProgress size={22}></CircularProgress>
                                            </Button> :
                                        <Button
                                            onClick={() => { setShowAuthenticationModal(true); }}
                                            className={classes.button}
                                            variant={'contained'}
                                            color={'secondary'}>
                                            Enter
                                        </Button>
                                }
                            </Grid>
                        </Hidden>
                    </Box>
                </Card>
                <Hidden xsDown>
                    <Grid className={classes.buttonFooterGridContainer} container direction="row" >
                        <Grid item xs={8}>
                            <DraftPropBetErrors draftPropBet={draftPropBet} />
                        </Grid>
                        <Grid item xs={4} className={classes.gridItemRightSM}>
                            {
                                typeof customer !== 'undefined' ?
                                    draftPropBet.ticketStatus !== 'editing' && draftPropBet.ticketStatus !== 'purchasing' ?
                                        <Button
                                            className={classes.bottomButton}
                                            variant={'contained'}
                                            color={'secondary'}
                                            disabled={draftPropBet.selections.length !== draftPropBet.bets.length}
                                            onClick={() => draftPropBet.save()}>{ticket ? 'Edit' : 'Submit'}</Button> :
                                        <Button
                                            className={classes.bottomButton}
                                            variant={'contained'}
                                            color={'secondary'}
                                            disabled>
                                            <CircularProgress size={22}></CircularProgress>
                                        </Button> :
                                    <Button
                                        onClick={() => { setShowAuthenticationModal(true); }}
                                        className={classes.bottomButton}
                                        variant={'contained'}
                                        color={'secondary'}>
                                        Enter
                                    </Button>
                            }
                        </Grid>
                    </Grid>
                </Hidden>
            </Container>
            <AuthenticationModal
                showModal={showAuthenticationModal}
                onClose={() => {
                    setShowAuthenticationModal(false);
                }}
                noRedirect={true}/>
        </OverridableSafeAreaDiv>
    );
}
