import { Button, CircularProgress, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { useUpdateCustomerProfile } from '@ogsnetwork/opp-component-lib';
import React from 'react';
import { ThemeWithOrgPalette } from '../apps/config';
import userHeroImg from '../images/userHeroImage.png';
import polyBeach from '../images/polyBeach.png';
import userBlob from '../images/userBlob.png';
import {
    ContainerWithImage,
    CWIContentContainer,
    CWIContentHeaderImage,
    CWIContentTitle
} from '../utils/components/containerWithImage';
import { CenterScreenView } from '../utils/views/CenterScreenView';
import { Error } from '../utils/views/error';
import { OverridableSafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    actions: {
        padding: 10
    },
    button: {
        marginBottom: 10,
        marginTop: 10
    },
    card: {
        minWidth: 350,
        maxWidth: 400,
        padding: 20
    },
    cardMetaInfo: {
        borderRadius: 5,
        backgroundColor: theme.orgPalette?.cardOnCard,
        marginBottom: 15,
        marginTop: 10
    },
    divider: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        height: 1,
        marginBottom: 10,
        width: '100%'
    },
    highlightText: {
        color: theme.palette.secondary.main
    },
    highlightTextAlt: {
        color: theme.palette.primary.main
    },
    manageFundsComingSoonContainer: {
        borderRadius: 5,
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        padding: 10
    }
}));

export function UpdateProfileScreen() {
    const classes = useStyles();
    const customerProfile = useUpdateCustomerProfile();

    const handleSubmitKeypress = (e: React.KeyboardEvent) => {
        if (['Enter', 'NumpadEnter'].includes(e.code)) {
            customerProfile.dispatchUpdateProfile();
        }
    };

    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <CenterScreenView fullHeight>
                <ContainerWithImage image={userHeroImg}>
                    <CWIContentHeaderImage image={userBlob} />
                    <CWIContentTitle title="Update Profile" />
                    <CWIContentContainer>
                        <Grid container direction="column" spacing={2} >
                            <Grid item>
                                <Typography variant='h6'>Username</Typography>
                                <TextField
                                    id="username"
                                    type="username"
                                    fullWidth
                                    value={customerProfile.username}
                                    onChange={(event) => customerProfile.setUsername(event.target.value)}
                                    onKeyPress={handleSubmitKeypress}
                                />
                                <Error error={customerProfile.error} errorKey="username" />
                            </Grid>
                            <Grid item>
                                {
                                    customerProfile.status === 'updating' ?
                                        <Button
                                            fullWidth
                                            color="primary"
                                            variant={'contained'}
                                            disabled
                                        >
                                            <CircularProgress size={22} />
                                        </Button> :
                                        <Button
                                            fullWidth
                                            color="primary"
                                            disabled={ !customerProfile.isValid }
                                            onClick={() => customerProfile.dispatchUpdateProfile()}
                                            onKeyPress={handleSubmitKeypress}
                                            variant="contained"
                                        >
                                            Update
                                        </Button>
                                }
                            </Grid>
                        </Grid>
                    </CWIContentContainer>
                </ContainerWithImage>
            </CenterScreenView>
        </OverridableSafeAreaDiv>
    );
}
