import React, { useState } from 'react';
import { IconButton, Grid, makeStyles } from '@material-ui/core';
import { OverridableSafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';
import polyBeach from '../images/polyBeach.png';
import { CenterScreenView } from '../utils/views/CenterScreenView';
import { MintParrotPass } from './nfts/mintParrotPass';
import { MintParrotPFP } from './nfts/mintParrotPFP';
import SwipeableViews from 'react-swipeable-views';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { BounceBox } from '../utils/animatedComponents/bounceBox';
import { ContractInfoModal } from '../utils/contractInfoModal';
import { cycle } from '../utils/cycle';

const useStyles = makeStyles((theme) => ({
    button: {
        height: 35,
        width: 35,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            '&:hover': {
                backgroundColor: theme.palette.primary.dark
            }
        },
        [theme.breakpoints.down('sm')]: {
            '&:hover': {
                backgroundColor: theme.palette.primary.main
            }
        }
    },
    buttonContainer: {
        width: 200,
        margin: '25px auto'
    },
    container: {
        '& :first-child': {
            overflowX: 'initial !important'
        }
    }
}));

export default function MintScreen() {
    const classes = useStyles();
    const [viewIndex, setViewIndex] = useState<number>(0);

    function cycleView(left?: true) {
        setViewIndex(cycle(viewIndex, 2, left));
    }

    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <CenterScreenView fullHeight>
                <SwipeableViews
                    enableMouseEvents
                    containerStyle={{overflowX: 'initial', width: '100vw'}}
                    index={viewIndex}
                    onChangeIndex={(index) => { setViewIndex(index); }}>
                    <BounceBox>
                        <MintParrotPFP />
                    </BounceBox>
                    <MintParrotPass />
                    <MintParrotPass freePassVariant />
                </SwipeableViews>
                <Grid container className={classes.buttonContainer}>
                    <Grid item xs={4}>
                        <IconButton
                            className={classes.button}
                            onClick={() => {cycleView(true);}}
                            size={'small'}
                        >
                            <ArrowBack />
                        </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                        <ContractInfoModal variant={'contained'} />
                    </Grid>
                    <Grid item xs={4} container justify={'flex-end'}>
                        <IconButton
                            className={classes.button}
                            onClick={() => {cycleView();}}
                            size={'small'}
                        >
                            <ArrowForward />
                        </IconButton>
                    </Grid>
                </Grid>
            </CenterScreenView>

        </OverridableSafeAreaDiv>
    );
}
