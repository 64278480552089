import {CssBaseline} from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {store} from './store';
import {initAPI} from '@ogsnetwork/opp-redux-lib';
import { config } from './apps/config';

initAPI({
    authenticationMethod: config.authenticationMethod,
    api: {
        host: config.apiHost,
        path: '/api'
    },
    locationServices: {
        enabled: 'never',

        // todo: use browser Location API
        getGeolocation: () => (Promise.resolve({
            latitude: 0,
            longitude: 0
        })),
        watchPositionChange: false
    },
    platform: 'web',
    tickets: {
        finalizedPreviousDaysRange: 7
    }
});

ReactDOM.render(
  <React.StrictMode>
      <CssBaseline />
      <Provider store={store}>
          <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
