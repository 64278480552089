import { Box, makeStyles, Typography } from '@material-ui/core';
import { IBet } from '@ogsnetwork/opp-api-lib';
import React from 'react';

export interface ScorePropBetProps {
    bet: IBet;
}

const useStyles = makeStyles(() => ({
    italix: {
        fontStyle: 'italic',
        marginTop: 10,
        marginBottom: 0
    }
}));

export function ScorePropBet(props: ScorePropBetProps): JSX.Element {
    const classes = useStyles();
    return (
        <Box>
            <Typography className={classes.italix} align={'center'}>Score: {props.bet.finalValue ?? 0}</Typography>
        </Box>
    );
}
