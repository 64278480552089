import React from 'react';
import { Link } from 'react-router-dom';

import {
    Box, Button, Card, CardMedia, Divider, Grid, makeStyles, SvgIcon, Tooltip, Typography
} from '@material-ui/core';
import { Send, Link as LinkIcon } from '@material-ui/icons';
import { IEvent } from '@ogsnetwork/opp-api-lib';
import { PrizeModal } from '../utils/prizeModal';
import defaultPropGameImage from './media/defaultPropGameImage.png';
import { Timestamp } from '../utils/components/timestamp';
import { config, ThemeWithOrgPalette } from '../apps/config';
import { NFTPopupModal } from './nftPopupModal';
import { useTicketFromEvent, useAppDispatch, useAuthentication } from '@ogsnetwork/opp-component-lib';
import { fetchAllPropEventTickets } from '@ogsnetwork/opp-redux-lib';
import { BrandedIcon } from '../utils/components/icon';
import { FormatTokensForDisplay } from '../utils/tokenFormatter';

interface EventCardProps {
    event: IEvent;
}

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    bold: {
        fontWeight: 'bold'
    },
    card: {
        borderRadius: 15,
        position: 'relative',
        display: 'block',
        margin: '0 auto',
        maxWidth: 400,
        minWidth: 300
    },
    cardInfoContainer: {
        padding: 15
    },
    cardMetaInfo: {
        color: theme.orgPalette?.lightGrey,
        borderRadius: 5,
        backgroundColor: theme.orgPalette?.cardOnCard,
        padding: 10,
        marginBottom: 15,
        marginTop: 10
    },
    cardMedia: {
        maxHeight: 245
    },
    iconContainer: {
        flexDirection: 'row',
        position: 'absolute',
        marginTop: 10,
        right: 0
    },
    guaranteedIconBackground: {
        position: 'relative',
        bottom: 1,
        display: 'inline-block',
        borderRadius: 15,
        backgroundColor: theme.orgPalette?.guaranteedIcon,
        height: 31,
        marginRight: 10,
        width: 31
    },
    guaranteedIcon: {
        position: 'relative',
        left: 8,
        top: 0
    },
    nftRequiredIconBackground: {
        display: 'inline-block',
        borderRadius: 15,
        backgroundColor: theme.palette.secondary.main,
        height: 31,
        marginRight: 10,
        width: 31
    },
    nftRequiredIcon: {
        position: 'relative',
        left: 4,
        transform: 'rotate(45deg)',
        top: 4
    },
    nftRequiredText: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold'
    },
    highlightDivider: {
        backgroundColor: theme.palette.primary.main,
        height: 5,
        width: '100%'
    },
    table: {
        width: '100%',
        maxHeight: 300
    }
}));

export function EventCard(props: EventCardProps): JSX.Element {
    const classes = useStyles();
    const { event } = props;
    const { ticket } = useTicketFromEvent({ event });
    const dispatch = useAppDispatch();
    const onLogin = () => {
        dispatch(fetchAllPropEventTickets());
    };
    useAuthentication({ onLogin });
    const requiresNFTs = typeof event.requiredNFTs !== 'undefined' && event.requiredNFTs.length > 0;

    return (
        <Card className={classes.card}>
            <Box className={classes.iconContainer}>
                {
                    event.isGuaranteed &&
                        <Tooltip
                            title={'Guaranteed to run'}
                            aria-label={'Guaranteed to run'}>
                            <Box className={classes.guaranteedIconBackground}>
                                <Typography variant={'h6'} className={classes.guaranteedIcon}>G</Typography>
                            </Box>
                        </Tooltip>
                }
                {
                    requiresNFTs &&
                        <Tooltip
                            title={'NFT Required'}
                            aria-label={'NFT Required'}>
                            <Box className={classes.nftRequiredIconBackground}>
                                <LinkIcon className={classes.nftRequiredIcon} />
                            </Box>
                        </Tooltip>
                }
            </Box>
            <CardMedia
                className={classes.cardMedia}
                component="img"
                image={ typeof event.infoGraphicUrl !== 'undefined' && event.infoGraphicUrl !== '' ? event.infoGraphicUrl : defaultPropGameImage } />
            <Box className={classes.highlightDivider}></Box>
            <Box className={classes.cardInfoContainer}>
                <Typography variant='h5'>
                    { event.description }
                </Typography>
                <Divider/>
                <Grid container className={classes.cardMetaInfo}>
                    <Grid item xs={4}>
                        <Typography className={classes.bold}>Start time</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Timestamp timestamp={event.closeTs} align={'right'}/>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.bold}>Entrants</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography align={'right'}>
                            { event.ticketCount }/{ event.ticketMax }
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.bold}>Ticket Cost</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography align={'right'}>
                            {FormatTokensForDisplay(event.ticketCost)} {event.ticketCostCurrency === 'PTS' ?
                                <BrandedIcon icon={config.icons.pointsIcon} /> : ''}
                        </Typography>
                    </Grid>
                    <Grid container item xs={4} alignItems={'center'}>
                        <Typography className={classes.bold}>Required NFTs</Typography>
                    </Grid>
                    <Grid container item xs={8} justifyContent={'flex-end'}>
                        {
                            requiresNFTs ?
                                <NFTPopupModal event={event} /> :
                                <Typography align={'right'}>
                                    None
                                </Typography>
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <PrizeModal fullWidth={true} event={event} />
                    </Grid>
                    <Grid item xs={6}>
                        <Link to={`/props/event/${event.id}`}>
                            <Button fullWidth startIcon={<Send />} color='secondary'>
                                { typeof ticket === 'undefined' ? 'Enter' : 'Edit' }
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}
