import { Box, Container, Grid, Icon, SvgIcon, Typography } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import React from 'react';
import { Link } from 'react-router-dom';
import footerLogo from '../../../images/parrot-social-good-times.png';
import { config } from '../../config';

export function Footer() {
    return (
        <Box className={'footer-container'}>
            <Container>
                <Grid container direction='row' lg className={'footer-content'}>
                    <Grid justifyContent='center' alignItems='center' container direction='column' md={4}>
                        <Box className={'icon-container'}>
                            <a href="https://discord.gg/CRD9tvukYP" target="_blank" rel="noreferrer">
                                <Icon className={'icon'} color="primary">
                                    discord
                                </Icon>
                            </a>
                            <a href="https://twitter.com/ParrotSocialApp" target="_blank" rel="noreferrer">
                                <SvgIcon
                                    className={'icon'}
                                    component={TwitterIcon}
                                    color="primary" />
                            </a>
                        </Box>
                    </Grid>
                    <Grid justifyContent='center' alignItems='center' container direction='column' md={4}>
                        <Box>
                            <img className={'footer-logo'} src={ footerLogo } />
                        </Box>
                    </Grid>
                    <Grid justifyContent='center' alignItems='center' container direction='column' md={4}>
                        <Box className={'contract-button-container'}>
                            <Typography className={'name-highlight'} variant={'h6'}>Verified Contract Address</Typography>
                            <a
                                href={`https://${config.metaMask.preferredNetwork === 'main' ? '' : `${config.metaMask.preferredNetwork}.`}etherscan.io/address/${config.metaMask.contractAddresses?.parrotPass}`}
                                target="_blank" rel="noreferrer">
                                <button className={'contract-button'}>
                                    0x98ed8b..
                                </button>
                            </a>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
