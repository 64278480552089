import { createStyles, withStyles } from '@material-ui/core';
import { UnstyledSafeAreaDiv } from '../../../../utils/views/overridableSafeAreaDiv';
import { styles } from '../../../../utils/views/safeAreaDivStyles';

const overrideStyles = createStyles({
    safeAreaDivOffset: {
        paddingTop: 50,
        paddingBottom: 0,
        paddingRight: 0
    }
});

/**
 * `withStyles(styles)(UnstyledSafeAreaDiv)` is the same as `SafeAreaDiv` (in `overridableSafeAreaDiv.tsx`)
 * this was coded this way since importing `SafeAreaDiv` directly into here causes the error
 * `ReferenceError: can't access lexical declaration 'SafeAreaDiv' before initialization` since the
 * `export const SafeAreaDiv` doesn't get initialized prior to this code executing... fun 🤕
 *
 * the inner `withStyles(styles)(...)` is to be "DRY" and thus the outer `withStyles(overrideStyles)(...)` is
 * to override those styles applied to the inner
 */

export const SafeAreaDiv = withStyles(overrideStyles)(withStyles(styles)(UnstyledSafeAreaDiv));
