import React from 'react';

import { Box, Button, Divider, List, ListItem, makeStyles, Modal, Typography } from '@material-ui/core';
import { CardGiftcard } from '@material-ui/icons';
import { IEvent, ITicket } from '@ogsnetwork/opp-api-lib';
import { config, ThemeWithOrgPalette } from '../apps/config';
import { useCustomer } from '@ogsnetwork/opp-component-lib';
import { BrandedIcon } from './components/icon';
import { FormatTokensForDisplay } from './tokenFormatter';

interface WinningsModalProps {
    ticket: ITicket;
    event: IEvent;
    variant?: 'outlined' | 'contained' | 'text';
    buttonClassName?: string;
}

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    icon: {
        position: 'relative',
        bottom: 1,
        fontSize: 14,
        height: '1em',
        width: '1em'
    },
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        background: theme.orgPalette?.cardGradient,
        borderRadius: 10,
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        padding: 20,
    }
}));

export function WinningsModal(props: WinningsModalProps): JSX.Element {
    const classes = useStyles();
    const { event, ticket, variant, buttonClassName } = props;
    const { customer } = useCustomer();
    const username = customer?.user.username;
    const [winningsModalVisible, setWinningsModalVisible] = React.useState(false);
    const handleOpenWinningsModal = () => setWinningsModalVisible(true);
    const handleCloseWinningsModal = () => setWinningsModalVisible(false);
    
    return (
        <Box>
            <Button
                fullWidth
                startIcon={<CardGiftcard />}
                onClick={handleOpenWinningsModal}
                variant={typeof variant !== 'undefined' ? variant : 'text'}
                color={'secondary'}
                className={buttonClassName}>
                Winnings
            </Button>
            <Modal
                disableScrollLock={true}
                open={winningsModalVisible}
                onClose={handleCloseWinningsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box className={classes.modalBox}>
                    <Typography align='center' id="modal-modal-title" variant="h6">
                        { event.description }
                    </Typography>
                    <Typography align='center' variant="h6">
                        { ticket.user?.username !== username ? ticket.user?.username + '\'s' : 'Your' } Winnings
                    </Typography>
                    <Divider />
                    <List>
                    {
                        ticket.prizes?.map((prize, index: number): React.ReactNode => (
                            <ListItem key={index}>
                                { index +1 }. { prize.type === 'external' ? prize.description : FormatTokensForDisplay(prize.amount) }&nbsp;{
                                    prize.type !== 'external' &&
                                        <BrandedIcon className={classes.icon} icon={config.icons.pointsIcon} />
                                }
                            </ListItem>
                        ))
                    }
                    </List>
                </Box>
            </Modal>
        </Box>
    );
}
