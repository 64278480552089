import { RootRef } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

/*
 * Replaces nav path with `:current-path:#:hashRoute:` when scrolling through the bounds of the first child.
 */

interface NavSectionTrackerProps {
    hashRoute: string;
}

export function NavSectionTracker(props: React.PropsWithChildren<NavSectionTrackerProps>): JSX.Element {
    const [ref, setRef] = useState<HTMLElement>();
    const history = useHistory();

    useEffect(() => {
        if (typeof ref !== 'undefined') {
            const updateLocationHash = () => {
                if (window.scrollY >= (ref?.offsetTop - 200) &&
                    window.scrollY < (ref?.offsetHeight + ref?.offsetTop - 200) &&
                    history.location.hash !== `#${props.hashRoute}`) {
                    history.replace(`${history.location.pathname}#${props.hashRoute}`);
                }
            };
        
            const updateLocationHashOnKeydown = (key: KeyboardEvent) => {
                if (key.code === 'Space') {
                    updateLocationHash();
                }
            };

            window.addEventListener('wheel', updateLocationHash);
            window.addEventListener('touchmove', updateLocationHash);
            document.addEventListener('keydown', updateLocationHashOnKeydown);

            return () => {
                window.removeEventListener('wheel', updateLocationHash);
                window.removeEventListener('touchmove', updateLocationHash);
                document.removeEventListener('keydown', updateLocationHashOnKeydown);
            };
        }
    }, [ref]);

    return <RootRef rootRef={ (ref: HTMLElement) => { setRef(ref); } }>
        <div id={props.hashRoute}>
            { props.children }
        </div>
    </RootRef>;
}