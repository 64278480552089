import { Box, Button, Hidden, makeStyles, MenuItem, MenuList, SvgIcon, Tab, Tabs, Theme } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TwitterIcon from '@material-ui/icons/Twitter';
import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { OnboardingButton } from '../metaMask/onboarding';
import { AppNavigationRouteWithMenuItem } from './navigation';
import OverridableComponent from '../utils/components/overridableComponent';
import './style.css';

const useStyles = makeStyles((theme: Theme) => ({
    tab: {
      color: theme.palette.primary.main
    },
    tabs: {
        '& .MuiTabs-indicator': {
            width: '75px !important',
            margin: '0px 40px'
        }
    },
    link: {
        color: theme.palette.secondary.main
    }
}));

export interface AppNavigationBarProps { routes: AppNavigationRouteWithMenuItem[] }

export default function AppNavigationBar(props: AppNavigationBarProps): JSX.Element {
    const classes = useStyles();
    const location = useLocation();
    const routes = props.routes.filter((route) => route.navigation !== undefined);
    const tabClasses = {root: classes.tab};
    const [activeRoute, setActiveRoute] = React.useState(0);
    const [menuRefElement, setMenuRefElement] = React.useState< HTMLAnchorElement | null | HTMLButtonElement>(null);
    const [showMenu, setShowMenu] = React.useState(false);

    useEffect(() => {
        const pathWithHash = `${location.pathname}${location.hash}`;
        routes.forEach((route, tabIndex) => {
            if (
                (route.exact && pathWithHash === route.path) ||
                (!(route?.exact ?? false) && pathWithHash.startsWith(route.path))
            ) {
                setActiveRoute(tabIndex);
                return true;
            }
        });
    });

    return (
        <Box>
            <Box className={'container'}>
                <Hidden only={['xs', 'sm']}>
                    <Tabs
                        classes={{root: classes.tabs}}
                        centered
                        className={'nav-tabs'}
                        indicatorColor="secondary"
                        textColor="primary"
                        value={activeRoute}
                    >
                        {
                            routes.map((route) => {
                                if (route.external) {
                                    return (
                                        <Tab
                                            classes={tabClasses}
                                            component={'a'}
                                            disableRipple={true}
                                            key={route.path}
                                            label={route.navigation?.label}
                                            href={route.path}
                                            target="_blank"
                                            textColor='primary'
                                        />
                                    );
                                }
                                return (
                                    <Tab
                                        classes={tabClasses}
                                        component={Link}
                                        disableRipple={true}
                                        key={route.path}
                                        label={route.navigation?.label}
                                        to={route.path}
                                        textColor='primary'
                                    />
                                );
                            })
                        }
                    </Tabs>
                </Hidden>
                <Hidden only={['xl', 'lg', 'md']}>
                    <Box className={'menu'}>
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            color="primary"
                            ref={(element) => setMenuRefElement(element)}
                            onClick={() => setShowMenu(true)}
                        >
                            Menu
                        </Button>
                        <Popper open={showMenu} anchorEl={menuRefElement} role={undefined} transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={() => setShowMenu(false)}>
                                            <MenuList autoFocusItem={showMenu} id="menu-list-grow">
                                                {
                                                    routes.map((route) => (
                                                        <MenuItem key={route.path}>
                                                            { route.external ?
                                                                <a className={classes.link} href={route.path} target="_blank" rel="noreferrer">
                                                                    {route.navigation?.label}
                                                                </a> :
                                                                <Link
                                                                    className={classes.link}
                                                                    onClick={() => setShowMenu(false)}
                                                                    to={route.path}
                                                                >
                                                                    {route.navigation?.label}
                                                                </Link>
                                                            }
                                                        </MenuItem>
                                                    ))
                                                }
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Box>
                </Hidden>
                <Box className={'icon-container'}>
                    <a href="https://discord.gg/CRD9tvukYP" className={'icon'} rel="noreferrer" target="_blank">
                        <Icon color="primary">
                            discord
                        </Icon>
                    </a>
                    <a href="https://twitter.com/ParrotSocialApp" className={'icon'} rel="noreferrer" target="_blank">
                        <SvgIcon component={TwitterIcon} color="primary" />
                    </a>
                    <Box className={'onboarding-nav-wrapper'}>
                        <OnboardingButton />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export const OverridableAppNavigationBar = OverridableComponent('appNavigationBar', AppNavigationBar);
