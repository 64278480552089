import ordinal from 'ordinal';
import React from 'react';

import { Box, Button, makeStyles, Modal, Paper, SvgIcon, Typography, Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from '@material-ui/core';
import { EmojiEvents } from '@material-ui/icons';
import { IEvent } from '@ogsnetwork/opp-api-lib';
import { ThemeWithOrgPalette } from '../apps/config';
import { config } from '../apps/config';
import { BrandedIcon } from './components/icon';
import { FormatTokensForDisplay } from '../utils/tokenFormatter';

interface PrizeModalProps {
    event: IEvent;
    variant?: 'outlined' | 'contained' | 'text';
    buttonClassName?: string;
    fullWidth?: true;
}

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        background: theme.orgPalette?.cardGradient,
        borderRadius: 10,
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        padding: 20,
    },
    table: {
        width: '100%',
        maxHeight: 300
    }
}));

export function PrizeModal(props: PrizeModalProps): JSX.Element {
    const classes = useStyles();
    const { event, variant, buttonClassName } = props;
    const [prizeModalVisible, setPrizeModalVisible] = React.useState(false);
    const handleOpenPrizeModal = () => setPrizeModalVisible(true);
    const handleClosePrizeModal = () => setPrizeModalVisible(false);
    
    return (
        <>
            <Button
                fullWidth={props.fullWidth}
                startIcon={<EmojiEvents color='primary' />}
                onClick={handleOpenPrizeModal}
                variant={typeof variant !== 'undefined' ? variant : 'text'}
                className={buttonClassName}>
                Prizes
            </Button>
            <Modal
                disableScrollLock={true}
                open={prizeModalVisible}
                onClose={handleClosePrizeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box className={classes.modalBox}>
                    <Typography align='center' id="modal-modal-title" variant="h6">
                        { event.description }
                    </Typography>
                    <Typography align='center' variant="h6">
                        Prize List
                    </Typography>
                    <TableContainer className={classes.table} component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Rank</TableCell>
                                    <TableCell align="right">Prize</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    event.prizes?.map((prizes) => (
                                        prizes.items.map((prizeItem, index: number): React.ReactNode => (
                                            <TableRow key={`${prizes.start}-${index}`}>
                                                <TableCell key={`${prizes.start}-${index}`}>
                                                    {ordinal(prizes.start)} {(prizes.start !== prizes.end) && `- ${ordinal(prizes.end)}`}&nbsp;
                                                </TableCell>
                                                <TableCell align="right">
                                                    { index > 0 &&  '+' }
                                                    { prizeItem.type === 'cash' && <>{FormatTokensForDisplay(prizeItem.amount)} <BrandedIcon icon={config.icons.pointsIcon} /></>}
                                                    { prizeItem.type === 'qualifier' && prizeItem.description }
                                                    { prizeItem.type === 'external' && prizeItem.description}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )) 
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>
        </>
    );
}
