// see: https://v4.mui.com/customization/color/#playground for a tool to help create theme palettes

import { montserratReg } from '../../fonts/fonts';
import { ThemeOptionsWithOrgPalette } from '../config';

const cardColor = '#0C0E11';
const darkBg = cardColor;
const cardGradient = 'linear-gradient(320deg, rgba(23,30,47,1) 0%, rgba(27,34,50,1) 100%)';
const cardGradientOpacity = 'linear-gradient(320deg, rgba(23,30,47,0.7) 0%, rgba(27,34,50,0.7) 100%)';

export const theme: ThemeOptionsWithOrgPalette = {
    orgPalette: {
        darkBackground: darkBg,
        darkBackgroundText: '#fff',
        card: cardColor,
        cardGradient: cardGradient,
        cardGradientOpacity: cardGradientOpacity,
        cardOnCard: '#26304c',
        cardDivider: '#323232',
        iconUnused: '#2E3A56',
        guaranteedIcon: '#48CA62',
        lightGrey: '#DFDFDF'
    },
    palette: {
        type: 'dark',
        primary: {
            light: '#825dee',
            main: '#805de7',
            dark: '#5b3bb7',
            contrastText: '#fff'
        },
        secondary: {
            light: '#ffce48',
            main: '#F3781E',
            dark: '#F57E00',
            contrastText: '#fff'
        },
        text: {
            primary: '#fff',
            secondary: 'rgba(255, 255, 255, 0.7)',
            disabled: 'rgba(255, 255, 255, 0.5)'
        },
        background: {
            default: '#0E121B',
            paper: cardColor
        }
    },
    typography: {
        h1: {
            fontWeight: 'bold'
        },
        h2: {
            fontWeight: 'bold'
        },
        h3: {
            fontWeight: 'bold'
        },
        h4: {
            fontWeight: 'bold'
        },
        h5: {
            fontWeight: 'bold'
        },
        h6: {
            fontWeight: 'bold'
        },
        fontFamily: '"Helvetica", "Arial", sans-serif',
        fontSize: 13
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [montserratReg],
            },
        },
        MuiButton: {
            root: {
                borderRadius: 25
            }
        },
        MuiDivider: {
            root: {
                alignSelf: 'center',
                backgroundColor: '#2E3A56',
                margin: '10px auto'
            }
        },
        MuiTypography: {
            root: {
                marginBottom: 10
            }
        },
        MuiAppBar: {
            root: {
                backdropFilter: 'blur( 4px )',
                borderBottom: '1px solid #323232'
            },
            colorPrimary: {
                backgroundColor: 'rgba(12, 14, 17, 0.67)'
            }
        },
        MuiChip: {
            colorPrimary: {
                backgroundColor: 'transparent'
            }
        },
        MuiCard: {
            root: {
                background: cardGradient,
                borderRadius: 0
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#F3781E'
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 0
            }
        }
    },
};
