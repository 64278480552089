import {web3} from '../providers';
import {Contract} from 'web3-eth-contract';
import { AbiItem } from 'web3-utils';
import { abi as ParrotPFPAbi } from './ParrotPFP.json';
import { config } from '../apps/config';

export function getParrotPFPContract(): Contract | undefined {
  if (web3) {
      return new web3.eth.Contract(
        ParrotPFPAbi as AbiItem[],
        config.metaMask.contractAddresses?.parrotPFP,
      );
  }
  return undefined;
}
