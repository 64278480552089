import { Box, CircularProgress, Container, Grid, makeStyles, Typography, TableContainer, Table, TableRow, TableBody, TableCell, TableHead, Paper } from '@material-ui/core';
import { ITransaction } from '@ogsnetwork/opp-api-lib';
import { useTransactions } from '@ogsnetwork/opp-component-lib';
import moment from 'moment';
import React from 'react';
import { config } from '../apps/config';
import polyBeach from '../images/polyBeach.png';
import { BrandedLink } from '../utils/components/BrandedLink';
import { SimplePagination, useRoutePage } from '../utils/components/pagination';
import { Timestamp } from '../utils/components/timestamp';
import { FormatTokensForDisplay } from '../utils/tokenFormatter';
import { CenterScreenView } from '../utils/views/CenterScreenView';
import { OverridableSafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';
import { BrandedIcon } from '../utils/components/icon';

const useStyles = makeStyles((theme) => ({
    button: {
        width: 200
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        marginTop: 40
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: 25,
        marginTop: 50
    },
    loadingViewContainer: {
        height: 'calc(100vh - 300px)',
        marginTop: 40
    },
    paginationContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    table: {
        width: '100%',
        '& p': {
            marginBottom: 0
        }
    }
}));

export function TransactionsScreen(): JSX.Element {
    const classes = useStyles();
    const page = useRoutePage();
    const transactions = useTransactions({ page });

    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <Container className={classes.container}>
                <Grid container>
                    <Grid container item xs={6} alignItems={'center'}>
                        <Typography variant="h6" gutterBottom>Transactions</Typography>                    
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={classes.paginationContainer}>
                            <SimplePagination hasNextPage={transactions.hasNextPage} page={page} to="/transactions" />
                        </Box>
                    </Grid>
                </Grid>
                {/* Only display full screen loading when fetching transactions for the first time */}
                { transactions.transactions.length === 0 && transactions.status === 'fetching' &&
                    <CenterScreenView className={classes.loadingViewContainer}>
                        <Box className={classes.loadingContainer}>
                            <CircularProgress />
                        </Box>
                    </CenterScreenView>
                }
                { transactions.transactions.length === 0 && transactions.status === 'success' &&
                    <CenterScreenView className={classes.loadingViewContainer}>
                        <Typography variant="h4">The game&apos;s just getting started!</Typography>
                        <Typography variant="h6">Purchase tickets in the <BrandedLink to={'/props/lobby'} text={'Game Lobby'} /></Typography>
                    </CenterScreenView>
                }
                {
                    transactions.transactions.length > 0 && transactions.status === 'success' &&
                        <>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Description</TableCell>
                                            <TableCell align="left">Date</TableCell>
                                            <TableCell align="center">Amount</TableCell>
                                            <TableCell align="right">ID</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            transactions.transactions.map((transaction: ITransaction) => (
                                                <TableRow key={transaction.id}>
                                                    <TableCell>{transaction.description}</TableCell>
                                                    <TableCell align="left"><Timestamp timestamp={moment.unix(transaction.createdTimestamp)} gutterBottom /></TableCell>
                                                    <TableCell align="center">{FormatTokensForDisplay(transaction.amount)} <BrandedIcon icon={config.icons.pointsIcon} /></TableCell>
                                                    <TableCell align="right">#{ transaction.id }</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box className={classes.paginationContainer}>
                                <SimplePagination hasNextPage={transactions.hasNextPage} page={page} to="/transactions" />
                            </Box>
                        </>
                }
            </Container>
        </OverridableSafeAreaDiv>
    );
}
