import { Button, Card, CircularProgress, Divider, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { useAuthentication, useForgotPassword } from '@ogsnetwork/opp-component-lib';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { config } from '../apps/config';
import polyBeach from '../images/polyBeach.png';
import { CenterScreenView } from '../utils/views/CenterScreenView';
import { Error } from '../utils/views/error';
import { OverridableSafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: 10
    },
    card: {
        borderRadius: 10,
        width: 350,
        padding: 20
    },
    form: {
        marginTop: 20,
        marginBottom: 20
    }
}));

export function ForgotPasswordScreen() {
    const classes = useStyles();
    const history = useHistory();
    const onSuccess = () => {
        if (config.dynamicUserflow.forgotPasswordDestination) {
                history.push(config.dynamicUserflow.forgotPasswordDestination);
        }
    };
    const { status } = useAuthentication();
    const forgotPassword = useForgotPassword({ onSuccess });
    const handleKeypress = (e: React.KeyboardEvent) => {
        if (e.charCode === 13) {
            forgotPassword.dispatchForgotPassword();
        }
    };
    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <CenterScreenView fullHeight>
                <Card className={classes.card}>
                    <Typography variant={'h4'}>
                        Forgot Password
                    </Typography>
                    <Divider />
                    <Grid container direction="column" spacing={2}>
                        <Grid item className={classes.form}>
                            <Typography>Email</Typography>
                            <TextField
                                id="email"
                                type="email"
                                onKeyPress={handleKeypress}
                                fullWidth
                                value={forgotPassword.email}
                                onChange={(event) => forgotPassword.setEmail(event.target.value)}
                            />
                            <Error error={forgotPassword.error} errorKey="email" />
                        </Grid>
                        <Grid item>
                            {
                                status !== 'submitting' ?
                                    <Button
                                        fullWidth
                                        color="primary"
                                        onKeyPress={handleKeypress}
                                        disabled={forgotPassword.disableSubmit}
                                        onClick={() => forgotPassword.dispatchForgotPassword()}
                                        variant="contained">Submit</Button> :
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant={'contained'}
                                        disabled><CircularProgress size={22} /></Button>
                            }
                            <Error error={forgotPassword.error} errorKey="general" />
                        </Grid>
                    </Grid>
                </Card>
            </CenterScreenView>
        </OverridableSafeAreaDiv>
    );
}
