// see: https://v4.mui.com/customization/color/#playground for a tool to help create theme palettes

import { kabel, montserratReg } from '../../fonts/fonts';
import { ThemeOptionsWithOrgPalette } from '../config';

const cardColor = '#0C0E11';
const darkBg = cardColor;
const cardGradient = 'linear-gradient(320deg, rgba(23,30,47,1) 0%, rgba(27,34,50,1) 100%)';
const cardGradientOpacity = 'linear-gradient(320deg, rgba(23,30,47,0.7) 0%, rgba(27,34,50,0.7) 100%)';

export const theme: ThemeOptionsWithOrgPalette = {
    orgPalette: {
        darkBackground: darkBg,
        darkBackgroundText: '#fff',
        card: cardColor,
        cardGradient: cardGradient,
        cardGradientOpacity: cardGradientOpacity,
        cardOnCard: '#26304c',
        cardDivider: '#323232',
        iconUnused: '#2E3A56',
        lightGrey: '#DFDFDF'
    },
    palette: {
        primary: {
            dark: '#d62b57',
            light: '#f5537d',
            main: '#ec416d'
        },
        secondary: {
            dark: '#1c8076',
            light: '#53cfc2',
            main: '#30a094'
        }
    },
    typography: {
        h1: {
            fontFamily: 'Kabel'
        },
        h2: {
            fontFamily: 'Kabel'
        },
        h3: {
            fontFamily: 'Kabel'
        },
        h4: {
            fontFamily: 'Kabel'
        },
        h5: {
            fontFamily: 'Kabel'
        },
        h6: {
            fontFamily: 'Kabel',
            fontSize: 'medium'
        },
        button: {
            fontFamily: 'Kabel'
        },
        fontFamily: 'Montserrat, "Helvetica", "Arial", sans-serif',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [montserratReg, kabel],
        },
      },
      MuiCard: {
          root: {
              background: cardGradient,
              borderRadius: 0
          },
      }
    },
};
