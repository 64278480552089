export function cycle(start: number, end: number, left?: true) {
    let index = start;
    if (left) {
        index--;
    } else {
        index++;
    }
    if (index > end) {
        index = 0;
    }
    if (index === -1) {
        index = end;
    }
    return index;
}