import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface BrandedLinkProps {
    text: string;
}

const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.secondary.main
    }
}));

export function BrandedLink(props: BrandedLinkProps & LinkProps) {
    const classes = useStyles();
    const { text, ...remainingProps } = props;
    return <Link className={classes.link} {...remainingProps}>{text}</Link>;
}