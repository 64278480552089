import { Theme, ThemeOptions } from '@material-ui/core';
import deepmerge from 'deepmerge';
import { AppNavigationRouteConfig } from '../navigation/navigation';
import { config as iratethGamesAppConfig } from './irateth/config';
import { config as parrotGamesAppConfig } from './parrotGames/config';
import { config as parrotConfig } from './parrot/config';
import React from 'react';

interface OrgPalette {
    orgPalette?: {
        [key: string]: string
    }
}

export type ThemeOptionsWithOrgPalette = ThemeOptions & OrgPalette;
export type ThemeWithOrgPalette = Theme & OrgPalette;

let partialConfig: Partial<AppConfig>;
switch (process.env.REACT_APP_CONFIG ?? 'parrot') {
    case 'irateth':
        partialConfig = iratethGamesAppConfig;
        break;
    case 'parrotGames':
        partialConfig = parrotGamesAppConfig;
        break;
    case 'parrot':
    default:
        partialConfig = parrotConfig;
}

export const config: Readonly<AppConfig> = deepmerge({
    authenticationMethod: process.env.REACT_APP_API_AUTHENTICATION_METHOD ?? 'session',
    apiHost: `${process.env.REACT_APP_API_SCHEME ?? 'http'}://${process.env.REACT_APP_API_HOST ?? 'localhost:8000'}`,
    routes: [],
    metaMask: {
        contractAddresses: JSON.parse((process.env.REACT_APP_CONTRACT_ADDRESSES ?? '{}').replace(/'/g, '"')),
        mintingEnabled: (process.env.REACT_APP_MINT_AVAILABLE ?? 'false') === 'true',
        preferredNetwork: process.env.REACT_APP_PREFERRED_NETWORK,
        preferredNetworkAddress: process.env.REACT_APP_PREFERRED_NETWORK_ADDRESS,
        ethSignatureDomainName: process.env.REACT_APP_ETH_SIGNATURE_DOMAIN_NAME,
        ethSignatureDomainVersion: process.env.REACT_APP_ETH_SIGNATURE_DOMAIN_VERSION
    }
}, partialConfig);

export interface AppConfig {
    authenticationMethod: 'session' | 'token';
    apiHost: string;
    dynamicUserflow: {
        editTicketPurchaseDestination?: string;
        forgotPasswordDestination?: string;
        loginDestination?: string;
        logoutDestination?: string;
        registerDestination?: string;
        resetPasswordDestination?: string;
        ticketPurchaseDestination?: string;
        parrotPassPurchaseDestination?: string;
        parrotPFPPurchaseDestination?: string;
    };
    icons: {
        // To do: Find out what this type actually is
        pointsIcon: React.FunctionComponent;
    },
    metaMask: {
        contractAddresses?: Record<string, string>;
        mintingEnabled: boolean;
        preferredNetwork?: string;
        preferredNetworkAddress?: string;
        ethSignatureDomainName?: string;
        ethSignatureDomainVersion?: string;
    };
    navigation: {
        routes: AppNavigationRouteConfig[];
        showTopNavigator?: boolean;  // default true
    };
    scoringLabel: string;
    tokenShorthand: string;
    theme?: ThemeOptions;
}
