import React, { useEffect, useState } from 'react';
import {
    makeStyles, Typography, Box, Grid, FormControl, InputLabel, Select, MenuItem, Button, CircularProgress
} from '@material-ui/core';
import { ContainerWithImage, CWIContentHeaderImage, CWIContentTitle, CWIContentContainer } from '../../utils/components/containerWithImage';
import parrotPassGlowing from '../../images/parrot-pass-glowing.png';
import parrotSocial from '../../images/parrotSocialHero.png';
import { ThemeWithOrgPalette } from '../../apps/config';
import { web3 } from '../../providers';
import MetaMaskOnboarding from '@metamask/onboarding';
import { getParrotPassContract } from '../../contracts/ParrotPass';
import { config } from '../../apps/config';
import { useHistory } from 'react-router-dom';
import { OnboardingButton } from '../../metaMask/onboarding';

// const TOTAL_MINT_PER_ROUND = 1000;
// const TOTAL_PAID_MINT = 4800;
const MINTED_IN_TOTAL = 8888;
const PARROT_PASS_FEE = 0.02;

interface RPCError extends Error { code: number; }

interface MintParrotPassProps {
    freePassVariant?: true;
}

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    actionContainer: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        borderRadius: 10,
        marginTop: 25,
        padding: 15
    },
    button: {
        marginTop: 30
    },
    card: {
        marginTop: 25,
        padding: 20
    },
    cardContainer: {
        width: 450,
        padding: 20
    },
    centerContent: {
        display: 'block',
        margin: '0 auto',
        textAlign: 'center'
    },
    centeredButton:{
        display: 'block',
        margin: '30px auto'
    },
    container: {
        height: 'calc(100vh - 100px)'
    },
    divider: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        height: 1,
        marginBottom: 5,
        marginTop: 7,
        width: '100%'
    },
    highlightText: {
        color: theme.palette.secondary.main
    },
    highlightTextAlt: {
        color: theme.palette.primary.main
    },
    loadingContainer: {
        height: '100%'
    },
    select: {
        color: 'white',
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
    },
    text: {
        color: 'white'
    }
}));

export function MintParrotPass(props?: MintParrotPassProps) {
    const history = useHistory();
    const classes = useStyles();
    const [numberToMint, setNumberToMint] = useState<number>(1);
    const [totalMint, setTotalMint] = useState<number>(0);
    const [mintPaused, setMintPaused] = useState<boolean>(true);
    const [freeMintPaused, setFreeMintPaused] = useState<boolean>(true);
    const [canFreeMint, setCanFreeMint] = useState<boolean>(false);
    const [mintStateLoading, setMintStateLoading] = useState<boolean>(true);
    const [metaMaskInstalled, setMetaMaskInstalled] = useState<boolean>(false);
    const [isPassMinting, setIsPassMinting] = useState<boolean>(false);
    const [network, setNetwork] = useState<string>('');

    async function checkPassAmounts(): Promise<void> {

        if (web3 && MetaMaskOnboarding.isMetaMaskInstalled()) {
            const contract = getParrotPassContract();
            if (!contract) {
                return;
            }

            const totalMint = await contract?.methods.totalSupply().call();
            const mintPaused = await contract?.methods.mintPaused().call();
            const freeMintPaused = await contract?.methods.freeMintPaused().call();

            const accounts = await web3.eth.requestAccounts();
            const account = accounts[0];
            const canFreeMint = await contract?.methods.walletCanFreeMint(account).call();

            setTotalMint(totalMint);
            setMintPaused(mintPaused);
            setFreeMintPaused(freeMintPaused);
            setCanFreeMint(!canFreeMint);
            setMintStateLoading(false);
        }
    }

    async function setupSubscriptions() {
        const provider = web3?.givenProvider;
        provider.on('networkChanged', () => {
            detectEthereumNetwork();
        });
    }

    useEffect(() => {
        let refreshInterval: NodeJS.Timer;
        if (config.metaMask.mintingEnabled) {
            setMetaMaskInstalled(MetaMaskOnboarding.isMetaMaskInstalled());
            refreshInterval = setInterval(() => {
                if (metaMaskInstalled) {
                    setMetaMaskInstalled(MetaMaskOnboarding.isMetaMaskInstalled());
                }
                checkPassAmounts();
            }, 5000);
            checkPassAmounts();
            detectEthereumNetwork();
            setupSubscriptions();
        }
        return () => {
            if (refreshInterval) {
                clearInterval(refreshInterval);
            }
        };
    }, []);

    async function detectEthereumNetwork() {
        web3?.eth.net.getNetworkType().then(async (netId) => {
            setNetwork(netId);
        });
    }

    async function switchNetwork() {
        const provider = web3?.givenProvider;
        provider.request({ method: 'wallet_switchEthereumChain', params:[{ chainId: config.metaMask.preferredNetworkAddress }]});
    }

    async function initiateMint({ amount, freeMint = false }: { amount: number, freeMint?: boolean }): Promise<void> {
        setIsPassMinting(true);
        if (web3 && MetaMaskOnboarding.isMetaMaskInstalled()) {
            const contract = getParrotPassContract();
            if (!contract) {
                return;
            }

            try {
                const accounts = await web3.eth.requestAccounts();
                const account = accounts[0];
                let tx;
                if (freeMint) {
                    tx = await contract.methods.freeMint().send({from: account});
                } else {
                    const passPrice = await contract.methods.price().call();
                    tx = await contract.methods.mint(amount).send({value: passPrice * amount, from: account});
                }
                if (tx) {
                    setIsPassMinting(false);
                    if (config.dynamicUserflow.parrotPassPurchaseDestination) {
                        history.push(config.dynamicUserflow.parrotPassPurchaseDestination);
                    }
                }
            } catch (err) {
                setIsPassMinting(false);
                if((err as RPCError).code === -32002) {
                    alert('Permission request already pending. Complete this action via MetaMask.');
                } else {
                    alert('There was an issue with minting. Check MetaMask and try again.');
                }
            }
        }
    }

    return (
        <ContainerWithImage image={parrotSocial}>
            <CWIContentHeaderImage image={parrotPassGlowing} />
            {
                props?.freePassVariant ?
                    <>
                        <CWIContentTitle title={'Free Parrot Pass'} />
                        <CWIContentContainer>
                            <Typography>Owning a Parrot Pass grants you top tier access to Parrot Social Games and Events, a free Parrot (while supplies last) and 1,000 in $PSC tokens. Select NFT communities can mint for free. For an updated list, check our discord</Typography>
                            <Typography>If you own a BAYC or MAYC you&apos;ll be able to mint a Parrot Pass for free, all you have to do is pay the gas.</Typography>
                            <Box className={classes.divider} />
                            {
                                totalMint >= MINTED_IN_TOTAL ?
                                    <Box className={classes.centerContent}>
                                        <Typography variant="h5">Parrot Passes are Sold Out!</Typography>
                                        <Typography>Check for a pass on OpenSea!</Typography>
                                    </Box> :
                                    <>
                                        {
                                            !config.metaMask.mintingEnabled ?
                                                <Box className={classes.centerContent}>
                                                    <Typography variant="h5">Great things are coming..</Typography>
                                                    <Typography>Parrot Pass minting will be available soon!</Typography>
                                                </Box> :
                                                network !== config.metaMask.preferredNetwork ?
                                                <Box className={classes.centerContent}>
                                                    <Typography align={'center'}>You must connect to the <span className={classes.highlightTextAlt}>{ config.metaMask.preferredNetwork }</span> network to mint</Typography>
                                                    <Button
                                                        className={classes.centeredButton}
                                                        variant={'contained'}
                                                        color={'primary'}
                                                        onClick={() => {
                                                            switchNetwork();
                                                        }}>
                                                        Switch Network
                                                    </Button>
                                                </Box>:
                                                <>
                                                    {
                                                        mintStateLoading && metaMaskInstalled ?
                                                            <Grid container justifyContent={'center'}>
                                                                <CircularProgress />
                                                            </Grid> :
                                                            !metaMaskInstalled &&
                                                                <Box className={classes.centerContent}>
                                                                    <Typography variant="h5">To mint, install Metamask</Typography>
                                                                    <OnboardingButton large={true} />
                                                                </Box>
                                                    }
                                                    {
                                                        freeMintPaused &&
                                                            <Box className={classes.actionContainer}>
                                                                <Typography variant="h5" align="right">Free minting is currently paused</Typography>
                                                            </Box>
                                                    }
                                                    {
                                                        !freeMintPaused && !mintStateLoading && canFreeMint &&
                                                            <>
                                                                <Grid container className={classes.actionContainer} spacing={2}>
                                                                    <Grid xs={6} item>
                                                                        <Typography className={classes.highlightTextAlt} variant={'h6'} gutterBottom>1 Parrot Pass</Typography>
                                                                    </Grid>
                                                                    <Grid xs={6} item container alignItems={'flex-end'} justifyContent={'flex-end'}>
                                                                        <Typography className={classes.highlightTextAlt} variant={'h6'} gutterBottom>FREE</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container justifyContent={'flex-end'}>
                                                                    {
                                                                        !isPassMinting ?
                                                                            <Button className={classes.button} color={'secondary'} variant={'contained'} onClick={() => { initiateMint({ amount: 1, freeMint: true }); }}>Mint Free Parrot Pass</Button>:
                                                                            <Button className={classes.button} color={'secondary'} variant={'contained'}><CircularProgress size={20} /></Button>
                                                                    }
                                                                </Grid>
                                                            </>
                                                    }
                                                    {
                                                        !canFreeMint && !mintStateLoading && <Typography className={classes.highlightTextAlt} gutterBottom>Wallet already claimed a free pass or doesn&lsquo;t have an eligible NFT required for claim.</Typography>
                                                    }
                                                </>
                                        }

                                    </>
                            }

                        </CWIContentContainer>
                    </> :
                    <>
                        <CWIContentTitle title={'Parrot Pass'} />
                        <CWIContentContainer>
                            <Typography>Owning a Parrot Pass grants you top tier access to Parrot Social Games and Events, a free Parrot (while supplies last) and 1,000 in $PSC tokens.</Typography>
                            <Typography>Play a little, live a lot.</Typography>
                            <Box className={classes.divider} />
                            {
                                totalMint >= MINTED_IN_TOTAL ?
                                    <Box className={classes.centerContent}>
                                        <Typography variant="h5">Parrot Passes are Sold Out!</Typography>
                                        <Typography>Check for a pass on OpenSea!</Typography>
                                    </Box> :
                                    <>
                                        {
                                            !config.metaMask.mintingEnabled ?
                                                <Box className={classes.centerContent}>
                                                    <Typography variant="h5">Great things are coming..</Typography>
                                                    <Typography>Parrot Pass minting will be available soon!</Typography>
                                                </Box> :
                                                network !== config.metaMask.preferredNetwork ?
                                                <Box>
                                                    <Typography align={'center'}>You must connect to the <span className={classes.highlightTextAlt}>{ config.metaMask.preferredNetwork }</span> network to mint</Typography>
                                                    <Button
                                                        className={classes.centeredButton}
                                                        variant={'contained'}
                                                        color={'primary'}
                                                        onClick={() => {
                                                            switchNetwork();
                                                        }}>
                                                        Switch Network
                                                    </Button>
                                                </Box>:
                                                <>
                                                    {
                                                        mintStateLoading && metaMaskInstalled ?
                                                            <Grid container justifyContent={'center'} alignContent={'center'} className={classes.loadingContainer}>
                                                                <CircularProgress />
                                                            </Grid> :
                                                            !metaMaskInstalled &&
                                                                <Box>
                                                                    <Typography variant="h4">To mint, install Metamask</Typography>
                                                                    <OnboardingButton large={true} />
                                                                </Box>
                                                    }
                                                    {
                                                        !mintPaused && !mintStateLoading &&
                                                            <>
                                                                <Grid container className={classes.actionContainer} spacing={2}>
                                                                    <Grid xs={12} item container>
                                                                        <FormControl fullWidth variant='filled'>
                                                                            <InputLabel id="demo-simple-select-label" className={classes.text}>How many passes to mint?</InputLabel>
                                                                            <Select
                                                                                className={classes.select}
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                value={numberToMint}
                                                                                label='How many passes to mint?'
                                                                                color='primary'
                                                                                onChange={(event) => { setNumberToMint(event.target.value as number); }}
                                                                                >
                                                                                <MenuItem value={1}>1</MenuItem>
                                                                                <MenuItem value={2}>2</MenuItem>
                                                                                <MenuItem value={3}>3</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid xs={12} item container alignItems={'flex-end'} justifyContent={'flex-end'}>
                                                                        <Typography className={classes.highlightTextAlt} variant={'h4'} gutterBottom>{PARROT_PASS_FEE*numberToMint} ETH</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container justifyContent={'flex-end'}>
                                                                    {
                                                                        !isPassMinting ?
                                                                            <Button className={classes.button} color={'secondary'} variant={'contained'} onClick={() => { initiateMint({ amount: numberToMint }); }}>Mint Parrot Pass{numberToMint > 1 && 'es'}</Button>:
                                                                            <Button className={classes.button} color={'secondary'} variant={'contained'}><CircularProgress size={20} /></Button>
                                                                    }
                                                                </Grid>
                                                            </>
                                                    }
                                                    {
                                                        mintPaused &&
                                                            <Box className={classes.actionContainer}>
                                                                <Typography variant="h5" align="right">Minting is currently paused</Typography>
                                                            </Box>
                                                    }

                                                </>
                                        }

                                    </>
                            }
                        </CWIContentContainer>
                    </>
            }

        </ContainerWithImage>
    );
}
