import { Typography } from '@material-ui/core';
import { useError, UseErrorProps } from '@ogsnetwork/opp-component-lib';
import React from 'react';

export function Error(props: UseErrorProps): JSX.Element | null {
    const messages = useError(props);
    const { length = 1 } = props;
    if (messages) {
        if (length === 1) {
            return <Typography color="error" variant="subtitle1">{messages[0]}</Typography>;
        }
        return (
            <ul>
                {messages.map((message, index) => (
                    <li key={index}>
                        <Typography color="error" variant="subtitle1">{message}</Typography>
                    </li>
                ))}
            </ul>
        );
    }
    return null;
}
