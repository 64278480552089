import './style.css';

import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLogout } from '@ogsnetwork/opp-component-lib';
import { useCustomer } from '@ogsnetwork/opp-component-lib';
import { HashLink as Link } from 'react-router-hash-link';

import {
    AppBar, Avatar, Box, Chip, Drawer, Grid, Hidden, IconButton, List, ListItem, ListItemIcon,
    makeStyles, MenuItem, MenuList, SvgIcon, Toolbar, Tooltip, Typography
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuIcon from '@material-ui/icons/Menu';
import TwitterIcon from '@material-ui/icons/Twitter';

import { BrandedChipIcon } from '../../../utils/components/icon';
import psocLogo from '../../../images/psocLogo.png';
import { CryptoSingleSignOn } from '../../../authentication/cryptoSingleSignOn';
import { AppNavigationRouteWithMenuItem } from '../../../navigation/navigation';
import { config } from '../../config';
import { ThemeWithOrgPalette } from '../../config';
import { FormatTokensForDisplay } from '../../../utils/tokenFormatter';

const drawerWidth = 60;

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    appBar: {
        height: 60,
        width: `calc(100% - ${drawerWidth}px)`
    },
    appBarMobile: {
        height: 65,
        paddingRight: 5,
        paddingLeft: 5
    },
    bold: {
        fontWeight: 'bold'
    },
    defaultDivider: {
        backgroundColor: theme.palette.divider,
        height: 1,
        margin: '0 auto',
        width: '80%'
    },
    drawer: {
        width: drawerWidth
    },
    drawerPaper: {
        backgroundColor: theme.orgPalette?.darkBackground,
        borderRight: `1px solid ${theme.orgPalette?.cardDivider}`,
        color: '#fff',
        width: `calc(${drawerWidth}px + 1px)`
    },
    flexBox: {
        display: 'flex'
    },
    activeIcon: {
        color: theme.palette.primary.main
    },
    activeIconHighlight: {
        color: theme.palette.secondary.main
    },
    icon: {
        color: theme.orgPalette?.iconUnused
    },
    iconContainer: {
       display: 'flex',
       flexDirection: 'column',
       alignItems: 'center',
       justifyContent: 'center',
       width: drawerWidth
    },
    listItem: {
        paddingLeft: 17,
        width: drawerWidth
    },
    logoBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: 59
    },
    mobileMenuLink: {
        color: theme.orgPalette?.darkBackgroundText
    },
    onBoardingIconButton: {
        width: drawerWidth,
        height: drawerWidth
    },
    toolBar: {
        minHeight: 60
    },
    typographyAlignWithIcon: {
        marginBottom: 5
    },
    typographyNoMargin: {
        marginBottom: 0
    }
}));

interface DefaultAppNavigationBarProps { routes: AppNavigationRouteWithMenuItem[] }

export default function DefaultAppNavigationBar(props: DefaultAppNavigationBarProps): JSX.Element {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const logout = useLogout({
        onLogout: () => {
            if (config.dynamicUserflow.logoutDestination) {
                history.push(config.dynamicUserflow.logoutDestination);
            }
        }
    });
    const { customer } = useCustomer({customerRefreshEnabled: true});
    const username = customer?.user.username;
    const { routes } = props;
    const [activeRoute, setActiveRoute] = React.useState(-1);
    const [menuRefElement, setMenuRefElement] = React.useState<HTMLAnchorElement | null | HTMLButtonElement>(null);
    const [showMenu, setShowMenu] = React.useState(false);

    useEffect(() => {
        const pathWithHash = `${location.pathname}${location.hash}`;
        routes.forEach((route, tabIndex) => {
            /**
             * The `route.path` may contain route param placeholder strings eg. /transactions/:page
             * Only grab the first part of the path to match for tab highlighting. Revisit this logic if paths look
             * like eg. /feature/:param1/something/:param2 where `something` needs to be highlighted (though
             * this doesn't seem like something foreseeable... deeper highlighting like that will probably take the
             * form of highlighting a tab or something)
             */
            let path = route.path.split(':')[0];
            if (path.endsWith('/')) {
                path = path.substr(0, path.length - 1);
            }
            if (
                (route.exact && pathWithHash === path) ||
                (!(route?.exact ?? false) && pathWithHash.startsWith(path))
            ) {
                setActiveRoute(tabIndex);
                return true;
            }
        });
    });

    return (
        <Box>
            <Hidden smDown>
                <AppBar className={classes.appBar}>
                    <Toolbar className={classes.toolBar}>
                        <Grid container alignItems={'center'}>
                            <Grid xs={8} item>
                                <Typography variant={'h4'} className={classes.typographyNoMargin}>{activeRoute !== -1 && logout.status === 'loggedIn' ? routes[activeRoute].navigation.label : 'Games Room'}</Typography>
                            </Grid>
                            {
                                typeof username !== 'undefined' ?
                                    <Grid xs={4} container item justifyContent={'flex-end'}>
                                        <BrandedChipIcon
                                            chipProps={
                                                {
                                                    color: 'primary',
                                                    label: FormatTokensForDisplay(customer?.accounts?.pointsAccount)
                                                }
                                            }
                                            svgIcon={ config.icons.pointsIcon } />
                                        <Chip
                                            avatar={<Avatar />}
                                            color={'primary'}
                                            clickable
                                            onClick={() => history.push('/myprofile')}
                                            label={ username.length > 8 ? username.substr(0, 8) + '..' : username }/>
                                        <Chip
                                            color={'primary'}
                                            clickable
                                            label={ 'logout' }
                                            onClick={() => logout.dispatchLogout()}/>
                                    </Grid> :
                                    logout.status === 'error' ||
                                    logout.status === 'init' ||
                                    logout.status === 'loggedOut' ||
                                    logout.status === 'registering' ?
                                        <Grid xs={4} container item justifyContent={'flex-end'}>
                                            <Chip
                                                avatar={<Avatar />}
                                                component={Link}
                                                color={ 'secondary' }
                                                clickable
                                                label={ 'login' }
                                                to={'/login'}
                                                onClick={ () => setActiveRoute(-1) }/>
                                        </Grid> :
                                        <Grid xs={4} container item justifyContent={'flex-end'}>
                                            <Chip
                                                color={'primary'}
                                                avatar={<Avatar />}
                                                label={ 'loading user..' }
                                                clickable/>
                                        </Grid>
                            }
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    anchor="left"
                    classes={
                        {
                            paper: classes.drawerPaper
                        }
                    }
                >
                    <a href='https://www.parrotsocial.club' target='_blank' rel='noreferrer' className={classes.logoBox}>
                        <img src={psocLogo} />
                    </a>
                    <Box className={classes.defaultDivider} />
                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                        <List>
                            {
                                routes.map((route, index) => {
                                    if (!route.requiresLogin || (route.requiresLogin && typeof username !== 'undefined')) {
                                        return <>
                                        { route.highlight && <Box className={classes.defaultDivider} />}
                                        <ListItem
                                            className={classes.listItem}
                                            disableGutters
                                            button
                                            component={Link}
                                            to={route.navigation.path}
                                            key={route.path}>
                                            <Tooltip
                                                title={typeof route.navigation.label !== 'undefined' ? route.navigation.label : ''}
                                                aria-label={typeof route.navigation.label !== 'undefined' ? route.navigation.label : ''}
                                                placement={'right'}>
                                                <ListItemIcon>
                                                    <Icon className={activeRoute === index ?
                                                        route.highlight ? classes.activeIconHighlight : classes.activeIcon : classes.icon}>{route.icon}</Icon>
                                                </ListItemIcon>
                                            </Tooltip>
                                        </ListItem></>;
                                    }
                                })
                            }
                        </List>
                    </Box>
                    <Grid container className={'icon-container'} justifyContent={'center'}>
                        <Grid item>
                            <Tooltip
                                title='Discord'
                                aria-label='Discord'
                                placement={'right'}>
                                <a href="https://discord.gg/CRD9tvukYP" rel="noreferrer" target="_blank">
                                    <IconButton>
                                        <Icon className={classes.icon}>
                                            discord
                                        </Icon>
                                    </IconButton>
                                </a>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip
                                title='@ParrotSocialApp'
                                aria-label='@ParrotSocialApp'
                                placement={'right'}>
                                <a href="https://twitter.com/ParrotSocialApp" rel="noreferrer" target="_blank">
                                    <IconButton>
                                        <SvgIcon component={TwitterIcon}  className={classes.icon} />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <IconButton className={classes.onBoardingIconButton}>
                                <CryptoSingleSignOn cryptoConnect={false} asIcon={true} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Drawer>
            </Hidden>
            {/* Mobile nav */}
            <Hidden mdUp>
                <AppBar className={classes.appBarMobile}>
                    <Grid className={classes.appBarMobile} container alignItems={'center'}>
                        <Grid xs={4} sm={4} container item>
                            <IconButton
                                color={'inherit'}
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                ref={(element) => setMenuRefElement(element)}
                                onClick={() => setShowMenu(true)}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Popper open={showMenu} anchorEl={menuRefElement} role={undefined} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={() => setShowMenu(false)}>
                                                <MenuList autoFocusItem={showMenu} id="menu-list-grow">
                                                    {
                                                        routes.map((route) => (
                                                            <MenuItem key={route.path}>
                                                                <Link
                                                                    className={classes.mobileMenuLink}
                                                                    onClick={() => setShowMenu(false)}
                                                                    to={route.navigation.path}
                                                                >
                                                                    {route.navigation.label}
                                                                </Link>
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Grid>
                        <Hidden xsDown>
                            <Grid xs={4} container item justifyContent={'center'} alignItems={'center'}>
                                <Grid container item xs={12} alignItems={'center'} justifyContent={'center'}>
                                    <IconButton className={classes.onBoardingIconButton}>
                                        <CryptoSingleSignOn cryptoConnect={false} asIcon={true} />
                                    </IconButton>
                                    <IconButton color={'inherit'} href="https://discord.gg/CRD9tvukYP" rel="noreferrer" target="_blank">
                                        <Icon>
                                            discord
                                        </Icon>
                                    </IconButton>
                                    <IconButton color={'inherit'} href="https://twitter.com/ParrotSocialApp" rel="noreferrer" target="_blank">
                                        <SvgIcon component={TwitterIcon} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Hidden>
                        {
                            typeof username !== 'undefined' ?
                                <Grid xs={8} sm={4} container item justifyContent={'flex-end'}>
                                    <BrandedChipIcon
                                        chipProps={
                                            {
                                                color: 'primary',
                                                label: FormatTokensForDisplay(customer?.accounts?.pointsAccount)
                                            }
                                        }
                                        svgIcon={ config.icons.pointsIcon } />
                                    <Chip
                                        avatar={<Avatar />}
                                        color={'primary'}
                                        clickable
                                        onClick={() => history.push('/myprofile')}
                                        label={ username.length > 8 ? username.substr(0, 8) + '..' : username }/>
                                    <Chip
                                        color={'primary'}
                                        clickable
                                        label={ 'logout' }
                                        onClick={() => logout.dispatchLogout()}/>
                                </Grid> :
                                logout.status === 'error' ||
                                logout.status === 'init' ||
                                logout.status === 'loggedOut' ||
                                logout.status === 'registering' ?
                                    <Grid xs={8} sm={4} container item justifyContent={'flex-end'}>
                                        <Chip
                                            avatar={<Avatar />}
                                            component={Link}
                                            color={ 'secondary' }
                                            clickable
                                            label={ 'login' }
                                            to={'/login'}
                                            onClick={ () => setActiveRoute(-1) }/>
                                    </Grid> :
                                    <Grid xs={8} sm={4} container item justifyContent={'flex-end'}>
                                        <Chip
                                            color={'primary'}
                                            avatar={<Avatar />}
                                            label={ 'loading user..' }
                                            clickable/>
                                    </Grid>
                            }
                    </Grid>
                </AppBar>
            </Hidden>
        </Box>
    );
}
