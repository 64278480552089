import { Box, Button, Card, CardActions, CardMedia, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { EVENT_STATUS_CLOSED, EVENT_STATUS_FINALIZED, EVENT_STATUS_OPEN } from '@ogsnetwork/opp-api-lib';
import { TicketWithEvent } from '@ogsnetwork/opp-redux-lib';
import moment from 'moment';
import ordinal from 'ordinal';
import React from 'react';
import { Link } from 'react-router-dom';
import { config, ThemeWithOrgPalette } from '../apps/config';
import defaultPropGameImage from '../propBets/media/defaultPropGameImage.png';
import { BrandedIcon } from '../utils/components/icon';
import { Timestamp } from '../utils/components/timestamp';
import { PrizeModal } from '../utils/prizeModal';
import { FormatTokensForDisplay } from '../utils/tokenFormatter';

export interface TicketCardProps {
    ticket: TicketWithEvent;
}

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    bold: {
        fontWeight: 'bold'
    },
    card: {
        borderRadius: 15,
        position: 'relative',
        display: 'block',
        margin: '0 auto',
        maxWidth: 400,
        minWidth: 300
    },
    cardInfoContainer: {
        padding: 15
    },
    cardMetaInfo: {
        color: theme.orgPalette?.lightGrey,
        borderRadius: 5,
        backgroundColor: theme.orgPalette?.cardOnCard,
        padding: 10,
        marginBottom: 15,
        marginTop: 10
    },
    cardMedia: {
        maxHeight: 245
    },
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        padding: 20,
    },
    highlightDivider: {
        backgroundColor: theme.palette.primary.main,
        height: 5,
        width: '100%'
    }
}));

export function TicketCard(props: TicketCardProps): JSX.Element {
    const classes = useStyles();
    const ticket = props.ticket;
    const event = ticket.event;
    let userPosition = ordinal(ticket.position ?? 0);
    if (ticket.positionTied) {
        userPosition = `T-${userPosition}`;
    }
    return (
        <Card className={classes.card}>
            <CardMedia
                className={classes.cardMedia}
                component="img"
                image={ typeof event.infoGraphicUrl !== 'undefined' && event.infoGraphicUrl !== '' ? event.infoGraphicUrl : defaultPropGameImage } />
            <Box className={classes.highlightDivider}></Box>
            <Box className={classes.cardInfoContainer}>
                <Typography variant='h5'>
                    { event.description }
                </Typography>
                <Divider/>
                <Grid container className={classes.cardMetaInfo}>
                    <Grid item xs={4}>
                        <Typography className={classes.bold}>
                            Position
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography align={'right'} gutterBottom>
                            {userPosition} of {event.ticketCount} Entries
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.bold}>
                            {event.status === EVENT_STATUS_OPEN && 'Start time'}
                            {event.status !== EVENT_STATUS_OPEN && 'Close time'}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Timestamp timestamp={event.closeTs} align={'right'} gutterBottom/>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.bold}>Entrants</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography align={'right'} gutterBottom>
                            { event.ticketCount }/{ event.ticketMax }
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.bold} gutterBottom>Ticket Cost</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography align={'right'} gutterBottom>
                            {FormatTokensForDisplay(event.ticketCost)} {event.ticketCostCurrency === 'PTS' ?
                                <BrandedIcon icon={config.icons.pointsIcon} /> : ''}
                        </Typography>
                    </Grid>
                </Grid>
                <CardActions>
                    <Grid container>
                        <Grid item xs={6}>
                            <PrizeModal fullWidth={true} event={event} />
                        </Grid>
                        <Grid item xs={6}>
                            {
                                event.status === EVENT_STATUS_OPEN && 
                                    <Link to={`/props/event/${event.id}`}>
                                        <Button fullWidth startIcon={<Send />} color='secondary'>
                                            Edit
                                        </Button>
                                    </Link>
                            }
                            {
                                (event.status === EVENT_STATUS_CLOSED || event.status === EVENT_STATUS_FINALIZED) && 
                                    <Link to={`/props/live/${event.id}/${ticket.id}`}>
                                        <Button fullWidth startIcon={<Send />} color='secondary'>
                                            View
                                        </Button>
                                    </Link>
                            }
                        </Grid>
                    </Grid>
                </CardActions>
            </Box>
        </Card>
    );
}
