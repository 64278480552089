import ordinal from 'ordinal';
import React from 'react';

import { Box, Button, Divider, List, ListItem, makeStyles, Modal, Paper, Typography, TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import { config, ThemeWithOrgPalette } from '../apps/config';
import { IBet, IEvent, IOutcomeScoringRanges, IOutcomeScoringRange, IOutcomeScoring } from '@ogsnetwork/opp-api-lib';

interface ScoringDetailsModalProps {
    bet: IBet;
    event?: IEvent;
    variant?: 'outlined' | 'contained' | 'text';
    buttonClassName?: string;
}

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        background: theme.orgPalette?.cardGradient,
        borderRadius: 10,
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        padding: 20,
    },
    table: {
        width: '100%',
        maxHeight: 300
    }
}));

export function ScoringDetailsModal(props: ScoringDetailsModalProps) {
    const classes = useStyles();
    const { bet, event, variant, buttonClassName } = props;
    const [scoringDetailsModalVisible, setScoringDetailsModalVisible] = React.useState(false);
    const handleOpenScoringDetailsModal = () => setScoringDetailsModalVisible(true);
    const handleCloseScoringDetailsModal = () => setScoringDetailsModalVisible(false);
    let scoringRules: IOutcomeScoring | IOutcomeScoringRanges | undefined;
    if (typeof bet.scoringRules !== 'undefined') {
        scoringRules = bet.scoringRules;
    } else if (typeof event?.defaultOutcomeScoring !== 'undefined') {
        scoringRules = event.defaultOutcomeScoring;
    }
    
    return (
        <Box>
            <Button
                fullWidth
                startIcon={<BarChartIcon color='primary' />}
                onClick={handleOpenScoringDetailsModal}
                variant={typeof variant !== 'undefined' ? variant : 'text'}
                className={buttonClassName}>
                Scoring Details
            </Button>
            <Modal
                disableScrollLock={true}
                open={scoringDetailsModalVisible}
                onClose={handleCloseScoringDetailsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box className={classes.modalBox}>
                    <Typography align='center' id="modal-modal-title" variant="h6">
                        Scoring Details
                    </Typography>
                    <Divider />
                    {
                        scoringRules?.type === 'win/lose' &&
                            <TableContainer className={classes.table} component={Paper}>
                                <Table stickyHeader aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Correct</TableCell>
                                            <TableCell align="right">{ scoringRules.correct } { config.scoringLabel }</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Incorrect</TableCell>
                                            <TableCell align="right">{ scoringRules.incorrect } { config.scoringLabel }</TableCell>
                                        </TableRow>
                                        {
                                            typeof scoringRules.push === 'number' &&
                                            <TableRow>
                                                <TableCell>Tied or Inconclusive</TableCell>
                                                <TableCell align="right">{ scoringRules.push} { config.scoringLabel }</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    }
                    {
                        scoringRules?.type === 'range' &&
                            <>
                                <Typography variant={'h6'} align={'center'}>{bet.summary}</Typography>
                                <Typography align={'center'}>Scoring:</Typography>
                                <TableContainer className={classes.table} component={Paper}>
                                    <Table stickyHeader aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>How close</TableCell>
                                                <TableCell align="right">{ config.scoringLabel }</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                scoringRules.ranges.map((scoringRule: IOutcomeScoringRange, index: number) => {
                                                    return <TableRow key={index}>
                                                        {
                                                            scoringRule.start === 0 && scoringRule.end === 0 &&
                                                                <>
                                                                    <TableCell>Exact Number</TableCell>
                                                                    <TableCell align="right">{ scoringRule.value }</TableCell>
                                                                </>
                                                        }
                                                        {
                                                            scoringRule.start === scoringRule.end && scoringRule.start !== 0 && scoringRule.end !== 0 ?
                                                                <>
                                                                    <TableCell>Within {scoringRule.start}</TableCell>
                                                                    <TableCell align="right">{ scoringRule.value }</TableCell>
                                                                </> :
                                                                scoringRule.start !== 0 && scoringRule.end !== 0 &&
                                                                    <>
                                                                        <TableCell>Within {scoringRule.start} - {scoringRule.end}</TableCell>
                                                                        <TableCell align="right">{ scoringRule.value }</TableCell>
                                                                    </>
                                                        }
                                                    </TableRow>;
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>                            
                            </>
                    }
                </Box>
            </Modal>
        </Box>
    );
}
