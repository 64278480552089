import React from 'react';
import {
    makeStyles, Typography, Box
} from '@material-ui/core';
import { OverridableSafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';
import polyBeach from '../images/polyBeach.png';
import { CryptoSingleSignOn } from '../authentication/cryptoSingleSignOn';
import { CenterScreenView } from '../utils/views/CenterScreenView';
import { ContainerWithImage, CWIContentHeaderImage, CWIContentTitle, CWIContentContainer } from '../utils/components/containerWithImage';
import connectWalletImage from '../images/connect.png';
import wallet from '../images/wallet.png';
import { ThemeWithOrgPalette } from '../apps/config';

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    card: {
        marginTop: 25,
        padding: 20
    },
    cardContainer: {
        width: 450,
        padding: 20
    },
    divider: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        height: 1,
        marginBottom: 10,
        width: '100%'
    },
    highlightText: {
        color: theme.palette.secondary.main
    },
    highlightTextAlt: {
        color: theme.palette.primary.main
    }
}));

export function WalletScreen() {
    const classes = useStyles();

    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <CenterScreenView fullHeight>
                <ContainerWithImage image={connectWalletImage}>
                    <CWIContentHeaderImage image={wallet} />
                    <CWIContentTitle title={'Connect Wallet'} />
                    <CWIContentContainer>
                        <Typography>Connect your Ethereum wallet to gain access to exclusive games and content!</Typography>
                        <Typography>Keep an eye out on the <a className={classes.highlightTextAlt} href="https://discord.gg/CRD9tvukYP" target="_blank" rel="noreferrer">Discord</a> and <a className={classes.highlightTextAlt} href="https://twitter.com/ParrotSocialApp" target="_blank" rel="noreferrer">Twitter</a> for future updates!</Typography>
                        <CryptoSingleSignOn cryptoConnect={true}/>
                    </CWIContentContainer>
                </ContainerWithImage>
            </CenterScreenView>
        </OverridableSafeAreaDiv>
    );
}
