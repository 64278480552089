import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import civilPfp from '../images/civil-pert.png';
import rabidusPfp from '../images/rabidus-pert.png';
import number34Pfp from '../images/number34-pert.png';
import ryPfp from '../images/ry-pert.png';
import chPfp from '../images/ch-pert.png';
import pandaPfp from '../images/panda-pert.png';
import palmTrees from '../images/palm-trees.png';
import sedan from '../images/sedan-car-front.png';
import roadmap from '../images/Stamps.png';
import parrotPass from '../images/parrot-pass.png';
import './style.css';
import { Link } from 'react-router-dom';
import { NavSectionTracker } from '../navigation/navSectionTracker';
import { OverridableSafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';

export class HomeScreen extends React.PureComponent {

    public navToLink = (nav: string): void => {
        window.open(nav);
    };

    public render(): React.ReactNode {
        const roadMapContent = [{
            complete: true,
            title: 'Parrot Pass Minting',
            text: 'Parrot Passes available for minting'
        },{
            complete: true,
            title: 'Games Room',
            text: 'The Games Room is open with exclusive prop games, pick\'em contests and bracket pools for Parrot Pass holders'
        },{
            complete: true,
            title: 'Web3 Integration',
            text: 'Add blockchain integration to the Games Room to run exclusive contests for NFT communities.'
        },{
            complete: true,
            title: 'Parrot Mint',
            text: '2,000 free Parrots with the remainder available for 0.02 ETH.'
        },{
            complete: false,
            title: 'Team Expansion',
            text: 'We add developers, artists and community and technical support'
        },{
            complete: false,
            title: 'Game Pieces',
            text: 'Collectible NFTs that can be used in an added assortment of games'
        },{
            complete: false,
            title: 'Parrot Social Token',
            text: 'Issuance of the Parrot Social Club token (PSC)'
        },{
            complete: false,
            title: 'Liquidity Pools & Token Bridges',
            text: 'Bridge in-app Parrot Social tokens to PSC tokens and incentivized liquidity pools'
        },{
            complete: false,
            title: 'Partnerships & Metaverse',
            text: 'Parrot Social Club comes to the Metaverse with a virtual games room and exciting partnerships'
        }];
        return (
            <OverridableSafeAreaDiv>
                <Box className="homepage-nav-offset">
                    <NavSectionTracker hashRoute='home'>
                        <Box className={'water-bg'}>
                            <Hidden only={['xs', 'sm']}>
                                <Box className={'postcard-bg'}>
                                    <Grid container className={'postcard-as-container'}>
                                        <Grid
                                            className={'postcard-buttons'}
                                            container
                                            direction='column'
                                            justifyContent='center'
                                            alignItems='center'
                                            spacing={2}
                                            xs={12}>
                                            <Grid item>
                                                <button
                                                    type="button"
                                                    className="postcard-btn"
                                                    onClick={() => { this.navToLink('https://discord.gg/CRD9tvukYP'); }}>
                                                        Join The Discord
                                                </button>
                                            </Grid>
                                            <Grid item>
                                                <Link className="mint-postcard-btn" to='/mint'>Mint Pass</Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Hidden>
                            <Hidden only={['xl', 'lg', 'md']}>
                                <Box className={'postcard-mobile-bg'}>
                                    <Grid container className={'postcard-as-container'} />
                                </Box>
                            </Hidden>
                        </Box>
                    </NavSectionTracker>
                    <Hidden only={['xl', 'lg', 'md']}>
                        <Box className={'under-postcard-button-container'}>
                            <Grid
                                className={'postcard-buttons'}
                                container
                                direction='row'
                                justifyContent='center'
                                spacing={2}
                                xs={12}>
                                <Grid item>
                                    <button
                                        type="button"
                                        className="postcard-btn"
                                        onClick={() => { this.navToLink('https://discord.gg/CRD9tvukYP'); }}>
                                            Join The Discord
                                    </button>
                                </Grid>
                                <Grid item>
                                    <Link className="mint-postcard-btn" to='/mint'>Mint Pass</Link>
                                </Grid>
                            </Grid>
                            <div className={'postcard-buttons-divider-line'}></div>
                        </Box>
                    </Hidden>
                    <NavSectionTracker hashRoute='parrot-pass'>
                        <Box id='parrot-pass' className={'parrot-pass-content'}>
                            <Container>
                                <Grid
                                    container
                                    direction='row'
                                    justifyContent='center'
                                    alignItems='center'
                                    className='parrot-pass-content-container'>
                                    <Grid item lg={6}>
                                        <img className={'parrot-pass-image'} src={ parrotPass } />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        lg={6}
                                        className={'parrot-pass-text-content'}>
                                        <Box className={'parrot-pass-text-container'}>
                                            <Typography className='parrot-pass-header-text' variant="h3" gutterBottom={ true }>
                                                Parrot Pass
                                            </Typography>
                                            <Typography gutterBottom={ true }>
                                                Your Parrot Pass is your ticket to unlocking the most from the Parrot Social Club. Gain access to exclusive games, automatic pre-mint of Parrots and receive PSC rewards. Your Parrot Pass also gives you voting rights on the direction of the club, prizes and community spending.
                                            </Typography>
                                            <Typography gutterBottom={ true }>
                                                There will be an initial 8,888 Parrot Passes for mint. 4,000 can be minted for free (plus gas) to select NFT communities.
                                            </Typography>
                                            <Typography gutterBottom={ true }>
                                                Each Parrot Pass holder is able to mint one Parrot Profile Picture (PFP) with traits generated at mint.
                                            </Typography>
                                            <Typography gutterBottom={ true }>
                                                Your Parrot Pass may be your ticket to paradise.
                                            </Typography>
                                        </Box>
                                        <Link className="mint-button" to='/mint'>Mint</Link>
                                    </Grid>
                                </Grid>
                            </Container>
                            <img className={'palm-trees'} src={ palmTrees } />
                        </Box>
                    </NavSectionTracker>
                    <NavSectionTracker hashRoute='road-map'>
                        <Box className={'road-map-content'}>
                            <Box className='section-header'>
                                <Typography variant='h4'>Road Map</Typography>
                            </Box>
                            <Container>
                                <Hidden only={['xs', 'sm']}>
                                    <Grid
                                        container
                                        direction='row'
                                        justifyContent='center'
                                        alignItems='center'
                                        className='road-map-content-container'>
                                        <Grid item xs={6}>
                                            <img className={'roadmap-image'} src={ roadmap } />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            className={'parrot-pass-text-content'}>
                                            <Box className={'parrot-pass-text-container'}>
                                                <Typography className="roadmap-completion-text" variant="h6" gutterBottom={ true }>{roadMapContent.filter((item) => item.complete).length}/9 Items Complete</Typography>
                                                {
                                                    roadMapContent.map((item, index) => {

                                                        return <Box key={index} className={'road-map-text-item'}>
                                                            <Typography
                                                                className={
                                                                    item.complete ?
                                                                        (index < 3 ? 'road-map-text-item-header-var1' :
                                                                        index >= 3 && index < 6 ? 'road-map-text-item-header-var2' :
                                                                        index >= 6 ? 'road-map-text-item-header-var3' :
                                                                        'road-map-text-item-header-var1') + ' roadmap-header-item' :
                                                                    'road-map-text-item-header-incomplete'}
                                                                    variant="h4"
                                                                    gutterBottom={ true }>
                                                                { item.title }
                                                            </Typography>
                                                            <Typography gutterBottom={ true }>
                                                                { item.text }
                                                            </Typography>
                                                        </Box>;
                                                    })
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Hidden>
                                <Hidden only={['xl', 'lg', 'md']}>
                                    <Box className={'parrot-pass-text-container road-map-content-container'}>
                                        <Typography className="roadmap-completion-text" variant="h6" gutterBottom={ true }>1/9 Items Complete</Typography>
                                        {
                                            roadMapContent.map((item, index) => {

                                                return <Box key={index} className={'road-map-text-item'}>
                                                    <Typography
                                                            className={
                                                                item.complete ?
                                                                    (index < 3 ? 'road-map-text-item-header-var1' :
                                                                    index >= 3 && index < 6 ? 'road-map-text-item-header-var2' :
                                                                    index >= 6 ? 'road-map-text-item-header-var3' :
                                                                    'road-map-text-item-header-var1') + ' roadmap-header-item' :
                                                                'road-map-text-item-header-incomplete roadmap-header-item'}
                                                            variant="h4"
                                                            gutterBottom={ true }>
                                                        { item.title }
                                                    </Typography>
                                                    <Typography gutterBottom={ true }>
                                                        { item.text }
                                                    </Typography>
                                                </Box>;
                                            })
                                        }
                                    </Box>
                                </Hidden>
                            </Container>
                            <img className={'sedan'} src={ sedan } />
                        </Box>
                    </NavSectionTracker>
                    <NavSectionTracker hashRoute='team'>
                        <Box className={'team-content'}>
                            <Container>
                                <Grid
                                    container
                                    direction='row'
                                    justifyContent='center'
                                    alignItems='center'
                                    className='team-content-container'>
                                    <Grid direction='column' container item lg={6} spacing={3}>
                                        <Grid container direction='row' justifyContent="center" alignItems='center' xs={12}>
                                            <img className={'team-pfp'} src={ rabidusPfp } />
                                            <img className={'team-pfp'} src={ number34Pfp } />
                                            <img className={'team-pfp'} src={ ryPfp } />
                                        </Grid>
                                        <Grid container direction='row' justifyContent="center" alignItems='center' xs={12}>
                                            <img className={'team-pfp'} src={ civilPfp } />
                                            <img className={'team-pfp'} src={ chPfp } />
                                            <img className={'team-pfp'} src={ pandaPfp } />
                                        </Grid>
                                    </Grid>
                                    <Grid container item lg={6}>
                                        <Box className={'team-text-container'}>
                                            <Typography className='team-header-text' variant="h3" gutterBottom={ true }>
                                                The Team
                                            </Typography>
                                            <Typography gutterBottom={ true }>
                                                The Parrot Social Club was developed by sports fanatics, TV and movie buffs, gamblers and gamers who thought it would be fun to bring that chill and chaotic lifestyle to a little resort online.
                                            </Typography>
                                            <Box className={'team-name-text'}>
                                                <Typography gutterBottom={ true }>
                                                    <span className={'name-highlight'}>Rabidus</span>- Sports Junkie, gave away all his 2011 bitcoin (by accident)
                                                </Typography>
                                                <Typography gutterBottom={ true }>
                                                    <span className={'name-highlight'}>number34</span>- Breaker of things, celebrating World Parrot Day (05/31)
                                                </Typography>
                                                <Typography gutterBottom={ true }>
                                                    <span className={'name-highlight'}>Yipy</span>- Contract creator, softball player, Opensea accumulator
                                                </Typography>
                                                <Typography gutterBottom={ true }>
                                                    <span className={'name-highlight'}>CIVIL</span>- Collector of sounds, am become sleepy, taker of naps
                                                </Typography>
                                                <Typography gutterBottom={ true }>
                                                    <span className={'name-highlight'}>Ch</span>- meow meow meow meow moew meow
                                                </Typography>
                                                <Typography gutterBottom={ true }>
                                                    <span className={'name-highlight'}>Panda</span>- Artist & graphic designer. Loves a good vidya game. A glitchy witch.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </NavSectionTracker>
                </Box>
            </OverridableSafeAreaDiv>
        );
    }
}
