import { LoginScreen } from '../../authentication/login';
import { RegisterScreen } from '../../authentication/register';
import { ForgotPasswordScreen } from '../../authentication/forgotPassword';
import { ResetPasswordScreen } from '../../authentication/resetPassword';
import { MyContests } from '../../myContests/myContests';
import { DraftPropBet } from '../../propBets/draft';
import { LivePropBet } from '../../propBets/live';
import { PropBetLobby } from '../../propBets/lobby';
import { TransactionsScreen } from '../../transactions';
import { AppConfig } from '../config';
import { theme } from './theme';
import { Success } from '../../utils/views/success';
import { WalletScreen } from '../../accounts/wallet';
import { FundsScreen } from '../../accounts/funds';
import { ClaimTokensScreen } from '../../accounts/claimTokens';
import { TermsAndConditionsScreen } from './info/termsAndConditions';
import { PrivacyPolicyScreen } from './info/privacyPolicy';
import { SweepsRulesScreen } from './info/sweepsRules';
import { ResponsibleGamingScreen } from './info/responsibleGaming';
import { ReactComponent as PointsIcon } from './icons/pointsIcon.svg';

export const config: Partial<AppConfig> = {
    dynamicUserflow: {
        loginDestination: '/props/lobby',
        logoutDestination: '/login',
        registerDestination: '/props/lobby',
        ticketPurchaseDestination: '/success/purchase',
        editTicketPurchaseDestination: '/success/edit',
        forgotPasswordDestination: '/success/forgotPassword',
        resetPasswordDestination: '/login'
    },
    icons: {
        pointsIcon: PointsIcon
    },
    navigation: {
        routes: [
            {
                component: LoginScreen,
                default: true,
                exact: true,
                icon: 'person_outline',
                path: '/login',
                unauthenticated: true
            },
            {
                component: RegisterScreen,
                exact: true,
                icon: 'person_add_alt',
                path: '/register',
                unauthenticated: true
            },
            {
                component: ForgotPasswordScreen,
                exact: true,
                icon: 'person_add_alt',
                path: '/forgotPassword',
                unauthenticated: true
            },
            {
                authenticated: true,
                component: TermsAndConditionsScreen,
                exact: true,
                icon: 'person_add_alt',
                path: '/termsAndConditions',
                unauthenticated: true
            },
            {
                authenticated: true,
                component: PrivacyPolicyScreen,
                exact: true,
                icon: 'person_add_alt',
                path: '/privacyPolicy',
                unauthenticated: true
            },
            {
                authenticated: true,
                component: SweepsRulesScreen,
                exact: true,
                icon: 'person_add_alt',
                path: '/sweepsRules',
                unauthenticated: true
            },
            {
                authenticated: true,
                component: ResponsibleGamingScreen,
                exact: true,
                icon: 'person_add_alt',
                path: '/responsibleGaming',
                unauthenticated: true
            },
            {
                component: ResetPasswordScreen,
                exact: true,
                path: '/forgotpasswordkey/:userId/:token',
                unauthenticated: true
            },
            {
                authenticated: true,
                component: PropBetLobby,
                default: true,
                exact: true,
                icon: 'thumbs_up_down',
                navigation: {
                    label: 'Props Lobby',
                    type: 'topNavigation'
                },
                path: '/props/lobby',
                unauthenticated: true
            },
            {
                authenticated: true,
                component: Success,
                exact: true,
                icon: 'thumbs_up_down',
                path: '/success/:successType'
            },
            {
                authenticated: true,
                component: DraftPropBet,
                exact: true,
                icon: 'thumbs_up_down',
                path: '/props/event/:eventId',
                unauthenticated: true
            },
            {
                authenticated: true,
                component: LivePropBet,
                exact: true,
                path: '/props/live/:eventId/:ticketId'
            },
            {
                authenticated: true,
                component: MyContests,
                icon: 'local_activity',
                navigation: {
                    path: '/mycontests/upcoming',
                    label: 'My Contests',
                    type: 'topNavigation'
                },
                path: '/mycontests/:status/:page?'
            },
            {
                authenticated: true,
                component: TransactionsScreen,
                icon: 'receipt',
                navigation: {
                    path: '/transactions',
                    label: 'Transactions',
                    type: 'topNavigation'
                },
                path: '/transactions/:page?'
            },
            {
                authenticated: true,
                component: FundsScreen,
                exact: true,
                icon: 'payments',
                navigation: {
                    label: 'Funds',
                    type: 'topNavigation'
                },
                path: '/managefunds',
            },
            {
                authenticated: true,
                component: WalletScreen,
                exact: true,
                icon: 'account_balance_wallet_icon',
                navigation: {
                    label: 'Wallet',
                    type: 'topNavigation'
                },
                path: '/mywallet'
            },
            {
                authenticated: true,
                component: ClaimTokensScreen,
                exact: true,
                highlight: true,
                icon: 'redeem',
                navigation: {
                    label: 'Claim Tokens',
                    type: 'topNavigation'
                },
                path: '/claimtokens',
            }
        ]
    },
    scoringLabel: 'pts',
    tokenShorthand: 'psc',
    theme
};
