import { Button, Card, CircularProgress, Divider, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { useAuthentication, useResetPassword } from '@ogsnetwork/opp-component-lib';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { config } from '../apps/config';
import polyBeach from '../images/polyBeach.png';
import { CenterScreenView } from '../utils/views/CenterScreenView';
import { Error } from '../utils/views/error';
import { OverridableSafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';

const useStyles = makeStyles(() => ({
    button: {
        marginTop: 10
    },
    card: {
        borderRadius: 10,
        width: 350,
        padding: 20
    },
    form: {
        marginTop: 20,
        marginBottom: 20
    }
}));

interface ResetPasswordScreenRouteParams {
    token: string;
    userId: string;
}

export function ResetPasswordScreen() {
    const { status } = useAuthentication();
    const classes = useStyles();
    const history = useHistory();
    const { token, userId } = useParams<ResetPasswordScreenRouteParams>();
    const onSuccess = () => {
        if (config.dynamicUserflow.resetPasswordDestination) {
            history.push(config.dynamicUserflow.resetPasswordDestination);
        }
    };
    const resetPassword = useResetPassword({ token, userId: parseInt(userId, 10), onSuccess });
    const handleKeypress = (e: React.KeyboardEvent) => {
        if (e.charCode === 13) {
            resetPassword.dispatchResetPassword();
        }
    };
    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <CenterScreenView fullHeight>
                <Card className={classes.card}>
                    <Typography variant={'h4'}>
                        Reset Password
                    </Typography>
                    <Divider />
                    <Grid container direction="column" spacing={2}>
                        <Grid item className={classes.form}>
                            <Typography>Password</Typography>
                            <TextField
                                id="password"
                                type="password"
                                onKeyPress={handleKeypress}
                                fullWidth
                                value={resetPassword.password}
                                onChange={(event) => resetPassword.setPassword(event.target.value)}
                            />
                            <Error error={resetPassword.errors} errorKey="password" />
                        </Grid>
                        <Grid item className={classes.form}>
                            <Typography>Confirm Password</Typography>
                            <TextField
                                id="passwordConfirm"
                                type="password"
                                onKeyPress={handleKeypress}
                                fullWidth
                                value={resetPassword.passwordConfirm}
                                onChange={(event) => resetPassword.setPasswordConfirm(event.target.value)}
                            />
                            <Error error={resetPassword.errors} errorKey="passwordConfirm" />
                        </Grid>
                        <Grid item>
                            {
                                status !== 'submitting' ?
                                    <Button
                                        fullWidth
                                        color="primary"
                                        onKeyPress={handleKeypress}
                                        disabled={!resetPassword.isValid}
                                        onClick={() => resetPassword.dispatchResetPassword()}
                                        variant="contained"
                                    >
                                        Submit
                                    </Button> :
                                    <Button
                                        fullWidth
                                        color="primary"
                                        variant={'contained'}
                                        disabled
                                    >
                                        <CircularProgress size={22} />
                                    </Button>
                            }
                            <Error error={resetPassword.errors} errorKey="key" />
                        </Grid>
                    </Grid>
                </Card>
            </CenterScreenView>
        </OverridableSafeAreaDiv>
    );
}
