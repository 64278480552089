import React from 'react';

import {
    Box, Grid, makeStyles, Typography
} from '@material-ui/core';
import { usePropBetSelection, UsePropBetSelectionProps } from '@ogsnetwork/opp-component-lib';
import { ThemeWithOrgPalette } from '../apps/config';
import { ScorePropBet } from './score';
import { ScoringDetailsModal } from './scoringRules';

export type PropBetSelectionProps = UsePropBetSelectionProps;

const usePropBetSelectionStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    rootActive: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        borderLeft: `5px solid ${theme.palette.success.main}`,
        borderRadius: '5px 5px 0 0',
        padding: '0.5em 1em 0em 1em',
        marginTop: 20
    },
    rootInactive: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        borderRight: `5px solid ${theme.palette.error.main}`,
        borderRadius: '5px 5px 0 0',
        padding: '0.5em 1em 0em 1em',
        marginTop: 20
    },
    rootUnscored: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        borderRight: `5px solid ${theme.palette.warning.main}`,
        borderRadius: 5,
        padding: '0.5em 1em 1em 1em',
        marginTop: 20
    },
    betContainer: {
        marginBottom: 5
    },
    correct: {
        backgroundColor: theme.palette.success.main,
        width: '100%',
        borderRadius: 20,
        height: 32
    },
    incorrect: {
        backgroundColor: theme.palette.error.main,
        width: '100%',
        borderRadius: 20,
        height: 32
    },
    unscored: {
        border: '1px solid rgba(255, 255, 255, 0.23)',
        width: '100%',
        borderRadius: 20,
        height: 32
    },
    unscoredAndChosen: {
        backgroundColor: theme.palette.warning.main,
        width: '100%',
        borderRadius: 20,
        height: 32
    },
    activeDivider: {
        backgroundColor: `${theme.palette.primary.main}85`,
        height: 1,
        margin: '0px auto 15px auto'
    },
    inactiveDivider: {
        backgroundColor: '#5b6376',
        height: 1,
        margin: '0px auto 15px auto'
    },
    text: {
        position: 'relative',
        top: 4
    }
}));

const usePropBetSelectionHeadToHeadStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    betChoice: {
        fontWeight: 'bold'
    },
    selected: {
        backgroundColor: theme.palette.secondary.main,
    },
    defaultDivider: {
        backgroundColor: `${theme.orgPalette?.card}aa`,
        height: 1,
        margin: '0px auto 15px auto'
    }
}));

export function LivePropBetSelectionHeadToHead(props: PropBetSelectionProps): JSX.Element {
    const baseClasses = usePropBetSelectionStyles();
    const classes = usePropBetSelectionHeadToHeadStyles();
    const propBetSelection = usePropBetSelection(props);
    const { bet, event } = props;
    const leftBetChoice = bet.betChoices[0];
    const rightBetChoice = bet.betChoices[1];
    const firstOutcomeChosen = leftBetChoice.outcomeId === propBetSelection.selection?.outcomeId;
    const secondOutcomeChosen = rightBetChoice.outcomeId === propBetSelection.selection?.outcomeId;
    const firstOutcomeCorrect = bet.finalValue === propBetSelection.selection?.outcomeId && leftBetChoice.outcomeId === propBetSelection.selection?.outcomeId;
    const secondOutcomeCorrect = bet.finalValue === propBetSelection.selection?.outcomeId && rightBetChoice.outcomeId === propBetSelection.selection?.outcomeId;
    const outcomeCorrect = bet.finalValue === propBetSelection.selection?.outcomeId;
    const betScored = typeof bet.finalValue !== 'undefined';
    return (
        <>
        {
            betScored ?
                <Box className={outcomeCorrect ? baseClasses.rootActive : baseClasses.rootInactive}>
                    <Typography variant="h6">{bet.description}</Typography>
                    <Box className={ baseClasses.inactiveDivider } />
                    <Grid className={ baseClasses.betContainer } container spacing={3}>
                        <Grid xs={12} item className={classes.betChoice}>
                            <Box className={
                                firstOutcomeChosen ?
                                    firstOutcomeCorrect ?
                                        baseClasses.correct :
                                        baseClasses.incorrect :
                                    baseClasses.unscored
                                }>
                                <Typography className={baseClasses.text} align={'center'}>{leftBetChoice.outcomeName}</Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item className={classes.betChoice}>
                            <Box className={
                                secondOutcomeChosen ?
                                    secondOutcomeCorrect ?
                                        baseClasses.correct :
                                        baseClasses.incorrect :
                                    baseClasses.unscored
                                }>
                                <Typography className={baseClasses.text} align={'center'}>{rightBetChoice.outcomeName}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className={ baseClasses.inactiveDivider } />
                    <ScoringDetailsModal bet={bet} event={event} />
                </Box> :
                <Box className={baseClasses.rootUnscored}>
                    <Typography variant="h6">{bet.description}</Typography>
                    <Box className={ baseClasses.inactiveDivider } />
                    <Grid className={ baseClasses.betContainer } container spacing={3}>
                        <Grid xs={12} item className={classes.betChoice}>
                            <Box className={firstOutcomeChosen ? baseClasses.unscoredAndChosen : baseClasses.unscored}>
                                <Typography className={baseClasses.text} align={'center'}>{leftBetChoice.outcomeName}</Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item className={classes.betChoice}>
                            <Box className={secondOutcomeChosen ? baseClasses.unscoredAndChosen : baseClasses.unscored}>
                                <Typography className={baseClasses.text} align={'center'}>{rightBetChoice.outcomeName}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className={ baseClasses.inactiveDivider } />
                    <ScoringDetailsModal bet={bet} event={event} />
                </Box> 
        }
        </>
    );
}

export function LivePropBetSelectionFreeForm(props: PropBetSelectionProps): JSX.Element {
    const baseClasses = usePropBetSelectionStyles();
    const propBetSelection = usePropBetSelection(props);
    const { bet, event } = props;
    const betScored = typeof bet.finalValue !== 'undefined';

    return (
        <>
            {
                betScored ?
                    <Box className={propBetSelection.selection?.value === bet.finalValue?.toString() ? baseClasses.rootActive : baseClasses.rootInactive}>
                        <Typography variant="h6">{props.bet.description}</Typography>
                        <Box className={ baseClasses.inactiveDivider } />
                        <Typography variant="h6" align={'center'}>{propBetSelection.selection?.value}</Typography>
                        <Box className={ baseClasses.inactiveDivider } />
                        <ScoringDetailsModal bet={bet} event={event} />
                        <ScorePropBet bet={bet} />
                    </Box> :
                    <Box className={baseClasses.rootUnscored}>
                        <Typography variant="h6">{props.bet.description}</Typography>
                        <Box className={ baseClasses.inactiveDivider } />
                        <Typography variant="h6" align={'center'}>{propBetSelection.selection?.value}</Typography>
                        <Box className={ baseClasses.inactiveDivider } />
                        <ScoringDetailsModal bet={bet} event={event} />
                    </Box>
            }
        </>
    );
}

export function LivePropBetSelectionDropDown(props: PropBetSelectionProps): JSX.Element {
    const baseClasses = usePropBetSelectionStyles();
    const propBetSelection = usePropBetSelection(props);
    const { bet, event } = props;
    const betScored = typeof bet.finalValue !== 'undefined';
    const chosenBet = props.bet.betChoices.find((betChoice) => propBetSelection.selection?.outcomeId === betChoice.outcomeId);
    return (
        <>
            {
                betScored ?
                    <Box className={propBetSelection.selection?.outcomeId === bet.finalValue? baseClasses.rootActive : baseClasses.rootInactive}>
                        <Typography variant="h6">{props.bet.description}</Typography>
                        <Box className={ baseClasses.inactiveDivider } />
                        <Typography>
                            { chosenBet?.outcomeName }
                        </Typography>
                        <ScoringDetailsModal bet={bet} event={event} />
                    </Box> :
                    <Box className={baseClasses.rootUnscored}>
                        <Typography variant="h6">{props.bet.description}</Typography>
                        <Box className={ baseClasses.inactiveDivider } />
                        <Typography>
                            { chosenBet?.outcomeName }
                        </Typography>
                        <ScoringDetailsModal bet={bet} event={event} />
                    </Box>
            }
        </>
    );
}
