import { Typography } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

export function tabsA11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

/**
 * Tabs and TabPanel taken from MaterialUI example:
 * see: https://v4.mui.com/components/tabs/#simple-tabs
 */
type TabPanelProps = PropsWithChildren<{
    index: number;
    value: number;
}>

export function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <>
                    <Typography>{children}</Typography>
                </>
            )}
        </div>
    );
}
