import React from 'react';

import { Avatar, Box, Button, Divider, makeStyles, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { AvatarGroup } from '@mui/material';
import { AccountBalanceWallet } from '@material-ui/icons';
import { IEvent } from '@ogsnetwork/opp-api-lib';
import { ThemeWithOrgPalette } from '../apps/config';

interface NFTPopupModalProps {
    event: IEvent;
    buttonVariant?: 'outlined' | 'contained' | 'text';
    buttonClassName?: string;
    fullWidth?: true;
    variant?: 'button' | 'avatars';
}

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        background: theme.orgPalette?.cardGradient,
        borderRadius: 10,
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        padding: 20,
    },
    nftImageContainer: {
        '& .MuiAvatar-root': {
            height: 24,
            width: 24,
            fontSize: 12
        },
        '& :hover': {
            cursor: 'pointer'
        }
    },
    table: {
        width: '100%',
        maxHeight: 300
    }
}));

export function NFTPopupModal(props: NFTPopupModalProps): JSX.Element {
    const classes = useStyles();
    const { event, variant = 'avatars', buttonClassName, buttonVariant } = props;
    const [nftPopupModalVisible, setNFTPopupModalVisible] = React.useState(false);
    const handleOpenNFTPopupModal = () => setNFTPopupModalVisible(true);
    const handleCloseNFTPopupModal = () => setNFTPopupModalVisible(false);
    
    return (
        <>
            {
                variant === 'button' &&
                    <Button
                        fullWidth={props.fullWidth}
                        startIcon={<AccountBalanceWallet color='primary' />}
                        onClick={handleOpenNFTPopupModal}
                        variant={typeof buttonVariant !== 'undefined' ? buttonVariant : 'text'}
                        className={buttonClassName}>
                        Required NFTs
                    </Button>
            }
            {
                variant === 'avatars' &&
                    <AvatarGroup
                        className={classes.nftImageContainer}
                        onClick={handleOpenNFTPopupModal}
                        max={4}>
                        {
                            event.requiredNFTs?.map((nft, index) => 
                                <Avatar key={index} alt={nft.name} src={nft.logoImageUrl} />
                            )
                        }
                    </AvatarGroup>
            }
            <Modal
                disableScrollLock={true}
                open={nftPopupModalVisible}
                onClose={handleCloseNFTPopupModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box className={classes.modalBox}>
                    <Typography align='center' id="modal-modal-title" variant="h6">
                        { event.description }
                    </Typography>
                    <Typography align='center' variant="h6">
                        Required NFTs
                    </Typography>
                    <TableContainer className={classes.table} component={Paper}>
                        <Table stickyHeader aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Project</TableCell>
                                    <TableCell align="right">Where to mint</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    event.requiredNFTs?.map((nft, index) => 
                                        <TableRow key={index}>
                                            <TableCell>{ nft.name }</TableCell>
                                            <TableCell align="right"><a href={nft.mintLink} target="_blank" rel="noreferrer">{ nft.mintLink ?? 'acquire on secondary market'}</a></TableCell>
                                        </TableRow>)
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>
        </>
    );
}
