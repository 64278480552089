import React from 'react';
import {
    Box, Card, Container, makeStyles, Typography
} from '@material-ui/core';
import polyBeach from '../../../images/polyBeach.png';
import { Link } from 'react-router-dom';
import { ThemeWithOrgPalette } from '../../config';
import { OverridableSafeAreaDiv } from '../../../utils/views/overridableSafeAreaDiv';
import { BrandedLink } from '../../../utils/components/BrandedLink';

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    card: {
        padding: 20
    },
    containerHeader: {
        background: theme.orgPalette?.cardGradientOpacity,
        padding: 20,
        marginTop: 20,
        borderBottom: `5px solid ${theme.palette.primary.main}`,
        borderTop: `1px solid ${theme.palette.primary.main}`
    },
    link: {
        color: theme.palette.secondary.main
    }
}));

export function TermsAndConditionsScreen(): JSX.Element {
    const classes = useStyles();
    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <Container>
                <Box className={classes.containerHeader}>
                    <Typography variant="h4" gutterBottom>Terms &amp; Conditions</Typography>
                    <Typography>Version - 1.0</Typography>
                    <Typography>Date of last update - 09 March 2022</Typography>
                </Box>
                <Card className={classes.card}>
                    <Typography>You understand that you are providing information to Open Gaming Solutions Inc.. 
                        The information you provide will only be used to administer this promotion. NO PURCHASE NECESSARY to enter Sweepstakes.
                        SWEEPSTAKES ARE VOID WHERE PROHIBITED BY LAW. For detailed rules, see <BrandedLink to={'/sweepsRules'} text={'Sweeps Rules'} />.</Typography>
                    <Typography>These Terms and Conditions form a binding legal agreement between you and us and apply to your use of any of our
                        Contests or our Platform in any way, through any electronic device (web, mobile, tablet or any other device).</Typography>
                    <Typography>PLEASE NOTE THAT THESE TERMS AND CONDITIONS INCLUDE A PROVISION WAIVING THE RIGHT TO PURSUE ANY CLASS,
                        GROUP OR REPRESENTATIVE CLAIM AND REQUIRING YOU TO PURSUE PAST, PENDING, AND FUTURE DISPUTES BETWEEN YOU AND US THROUGH
                        INDIVIDUAL ARBITRATION UNLESS YOU OPT OUT WITHIN THE SPECIFIED TIME FRAME. SEE CLAUSE 21 FOR MORE INFORMATION.</Typography>
                    <Typography>You must read these Terms and Conditions carefully in their entirety before checking the box for acceptance.
                        By checking the box for acceptance during the registration process, or by accessing the Contests or creating a Customer
                        Account, you confirm that you have read and agree to be bound by these Terms and Conditions, which include and are
                        inseparably linked to our <BrandedLink to={'/privacyPolicy'} text={'Privacy Policy'} />, <BrandedLink to={'/responsibleGaming'} text={'Responsible Social Gaming Policy'} />, <BrandedLink to={'/sweepsRules'} text={'Sweeps Rules'} /> and other game-specific or
                        promotion-specific terms relevant to your Participation. If you do not agree with any provision of these Terms and Conditions
                        or any other linked policy, rules or terms you may not install or use the Platform or play any Contest.</Typography>
                    <Typography>THE CONTESTS AND PLATFORM DO NOT OFFER REAL MONEY GAMBLING, AND NO ACTUAL MONEY IS REQUIRED TO PLAY. ONLY PLAYERS
                        IN THE UNITED STATES OF AMERICA (EXCLUDING OUTLYING TERRITORIES) AND CANADA (EXCLUDING QUEBEC) ARE ELIGIBLE TO REDEEM PRIZES.
                        PLAYERS IN WASHINGTON, U.S.A., ARE NOT ELIGIBLE TO PLAY THE GAMES OR REDEEM PRIZES. SUBJECT TO THESE TERMS AND CONDITIONS,
                        PLAYERS IN OTHER JURISDICTIONS MAY PLAY THE GAMES BUT ARE NOT ABLE TO REDEEM PRIZES.</Typography>
                    <Typography>YOU CAN REQUEST REDEMPTION OF ANY PRIZES BY SELECTING THE “WITHDRAWAL” BUTTON ON THE PLATFORM. WHERE PLAYERS REDEEM
                        PRIZES FOR GIFT CARDS, SUCH PRIZES WILL BE ALLOCATED TO THE EMAIL ADDRESS REGISTERED AGAINST THE PLAYER’S CUSTOMER ACCOUNT,
                        AND IF THIS IS NOT TECHNICALLY POSSIBLE, THEN TO A NOMINATED EMAIL ADDRESS. WHERE PLAYERS REDEEM PRIZES FOR CASH, SUCH PRIZES
                        WILL BE PAID TO THE FINANCIAL ACCOUNT OR ONLINE WALLET FROM WHICH YOU PURCHASED PARROT TOKENS, AND IF THIS IS NOT TECHNICALLY
                        POSSIBLE, THEN TO A NOMINATED BANK ACCOUNT.</Typography>
                    <Typography>1. Definitions</Typography>
                    <ul>
                        <li><Typography>Collective Action means any claim as part of a class, group, collective, coordinated, consolidated, mass, or representative proceeding, whether by arbitration or in court.</Typography></li>
                        <li><Typography>Content means text, graphics, user interfaces, visual interfaces, photographs, trade marks, logos, sounds, music, artwork, computer code and other material used, displayed or available as part of the Contests and Platform.</Typography></li>
                        <li><Typography>Customer Account means an account held by a Registered Customer.</Typography></li>
                        <li><Typography>Fraudulent Conduct means any of the conduct described in clause 10.1.</Typography></li>
                        <li><Typography>Game means any one or more Parrot Token Contest(s) or Sweepstakes Game(s) available on the Platform. We reserve the right to add and remove Contests and Games from the Platform at our sole discretion.</Typography></li>
                        <li><Typography>Parrot Token means the virtual social gaming currency which enables you to play the Parrot Token Contests. Parrot Tokens have no monetary value.</Typography></li>
                        <li><Typography>Parrot Token Contest means any contest or game played with Parrot Token currency. We may give you Parrot Tokens free of charge when you sign up to a Platform and thereafter at regular intervals when you log into a Platform. You may win more Parrot Tokens when you play the Parrot Token Contests or Games.</Typography></li>
                        <li><Typography>Inactive Account means a Customer Account which has not recorded any log in or log out for a period exceeding 12 consecutive months.</Typography></li>
                        <li><Typography>Participate means playing any Contests or Games or using our Platform in any manner whatsoever, including any of the conduct described in clause 3.</Typography></li>
                        <li><Typography>Payment Administration Agent means any related body corporate, affiliate, or third party we appoint to act as our agent, including but not limited to Open Gaming Solutions Inc. and Open Gaming Sports Network Inc. (the “OGS Group”)</Typography></li>
                        <li><Typography>Platform means any URL or mobile application belonging to, or licensed to, OGS Group, and branded as part of the “Parrot Social” family of games, including the website located at https://parrotsocial.club, and all subdomains, subpages and successor sites thereof, as well as all Contests, Games, features, tools and services available thereon.</Typography></li>
                        <li><Typography>Player or you means any person who Participates, whether or not a Registered Customer.</Typography></li>
                        <li><Typography>Prizes means prizes won playing Sweepstakes Contests and Games with Parrot Tokens which are redeemable for valuable prizes in accordance with the <BrandedLink to={'/sweepsRules'} text={'Sweeps Rules'} />.</Typography></li>
                        <li><Typography>Registered Customer means a Player who has successfully registered a Customer Account, whether that account is considered active or not.</Typography></li>
                        <li><Typography>Parrot Tokens means sweepstakes entries subject to the <BrandedLink to={'/sweepsRules'} text={'Sweeps Rules'} />.  We may give you Parrot Tokens free of charge when you sign up to a Platform, as a bonus or via each of our free alternative methods of entry as set out in the <BrandedLink to={'/sweepsRules'} text={'Sweeps Rules'} />. You may win more Parrot Tokens when you play Sweepstakes Games. YOU CANNOT PURCHASE PARROT TOKENS.</Typography></li>
                        <li><Typography>Sweeps Rules means the <BrandedLink to={'/sweepsRules'} text={'Sweeps Rules'} /> available on the Platform.</Typography></li>
                        <li><Typography>Sweepstakes Game means any game played with Parrot Tokens.</Typography></li>
                        <li><Typography>Terms and Conditions means these terms and conditions, as amended from time to time.</Typography></li>
                        <li><Typography>Third Party Websites means a third party website not controlled by us.</Typography></li>
                        <li><Typography>OGS means Open Gaming Solutions Inc. (company registration number BC0960914) a company incorporated in British Columbia Canada, and subject to British Columbia law, having its registered address at PO Box 28047 Lansdowne PO Kamloops, BC V2C 0C9.</Typography></li>
                        <li><Typography>OGSN means Open Gaming Sports Network Inc. (company registration number 5362442) a company incorporated in Delaware, USA and subject to Delaware law having its registered address at 304 S. Jones Blvd #2799 Las Vegas NV 89107.</Typography></li>
                    </ul>
                    <Typography>2. LICENSING AND THE PROTECTION OF FUNDS</Typography>
                    <Typography>2.1 The online social game known as “Parrot Social” is owned and operated by OGS. All payments are processed by OGS. The sweepstakes promotions and Prizes offered by Parrot Social are operated by OGS.</Typography>
                    <Typography>3. YOUR PARTICIPATION</Typography>
                    <Typography>Restrictions</Typography>
                    <Typography>3.1 You hereby declare and warrant that:</Typography>
                    <ol type='a'>
                        <li>you are over 18 years of age or such higher minimum legal age of majority as stipulated in the jurisdiction of your residence and are, under the laws applicable to you, legally allowed to participate in the Contests and Games offered on the Platform;</li>
                        <li>YOU DO NOT RESIDE IN, OR ACCESS THE PLATFORM FROM, THE STATE OF WASHINGTON IN THE UNITED STATES OF AMERICA;</li>
                        <li>you participate in the Contests and Games strictly in your personal capacity for recreational and entertainment purposes only;</li>
                        <li>you participate in the Contests and Games on your own behalf and not on the behalf of any other person;</li>
                        <li>all information that you provide to us during the term of validity of these Terms and Conditions is true, complete and correct, and you will immediately notify us of any change to such information;</li>
                        <li>you will not be involved in any fraudulent, collusive, fixing or other unlawful activity in relation to your or third parties’ participation in any of the Contests or Games and you will not use any software-assisted methods or techniques (including but not limited to bots designed to play automatically) or hardware devices for your participation in any of the Contests or Games. We reserve the right to invalidate any participation in the event of such behaviour.</li>
                    </ol>
                    <Typography>3.2 It is a Player’s responsibility to ensure that their Participation is lawful in their jurisdiction. Any person who is knowingly in breach of clause 3, including any attempt to circumvent this restriction, for example, by using a VPN, proxy or similar service that masks or manipulates the identification of your real location, or by otherwise providing false or misleading information regarding your location or place of residence, or by Participating through a third party or on behalf of a third party located in a jurisdiction where it is unlawful to Participate, is in breach of these Terms and Conditions. You may be committing fraud and may be subject to criminal prosecution.Eligible Players</Typography>
                    <Typography>3.4 Employees of OGS Group, any of their respective affiliates, subsidiaries, holding companies, advertising agencies, or any other company or individual involved with the design, production, execution or distribution of the Contests or Games and their immediate family (spouse, parents, siblings and children, whether the relationship is by birth, marriage or adoption) and household members (people who share the same residence at least 3 months of the year) are not eligible to Participate.Acceptance</Typography>
                    <Typography>3.5 By accepting these Terms and Conditions you agree that your Participation is at your sole option, discretion and risk. You will have no claims whatsoever against OGS Group or any of its partners, or respective directors, officers or employees in relation to any losses you incur.</Typography>
                    <Typography>4. LICENCE</Typography>
                    <Typography>4.1 Subject to your agreement and continuing compliancy with these Terms and Conditions, OGS Group grants you a personal, non-exclusive, non-transferable, non-sublicensable, revocable, limited licence to access and use the Platform, including Parrot Tokens, through a supported Web browser or mobile device, solely for your personal, private entertainment and no other reason.</Typography>
                    <Typography>4.2 These Terms and Conditions do not grant you any right, title or interest in the Platform or Content.</Typography>
                    <Typography>4.3 You acknowledge and agree that your licence to use the Platform is limited by these Terms and Conditions and if you do not agree to, or act in contravention of, these Terms and Conditions, your licence to use the Platform (including the Games and Content) may be immediately terminated.</Typography>
                    <Typography>4.4 Where the Platform or any Game is deemed to be illegal under the laws of the jurisdiction in which you reside or are situated, you will not be granted any licence to, and must refrain from accessing, the Platform or relevant Game.</Typography>
                    <Typography>5. YOUR CUSTOMER ACCOUNT</Typography>
                    <Typography>Single Account</Typography>
                    <Typography>5.1 You are allowed to have only one Customer Account, including any Inactive Account, on the Platform. If you attempt to open more than one Customer Account, all accounts you have opened or try to open may be cancelled or suspended and the consequences described in clause 18.3 may be enforced.</Typography>
                    <Typography>5.2 You must notify us immediately if you notice that you have more than one registered Customer Account, whether active or not, on the Platform.</Typography>
                    <Typography>Accuracy</Typography>
                    <Typography>5.3 You are required to keep your registration details up to date at all times. If you change your address, email, phone number or any other contact details or personal information contact Customer Support via this <a className={classes.link} href='https://parrotsocial.app/contact/' target='_blank' rel='noreferrer'>form</a> and choose ‘Request Support’ from the drop down menu in order to update your details. The name that you provide to us at registration must be identical to that listed on your government issued identification.</Typography>
                    <Typography>Security and Responsibility of Your Customer Account</Typography>
                    <Typography>5.4 As part of the registration process, you may have to choose a password to login into the Platform, unless you login to your Customer Account using a trusted, 3rd party wallet.</Typography>
                    <Typography>5.5 It is your sole and exclusive responsibility to ensure that your Member Account login details and any payment methods are kept secure and are only accessible by you. You accept full responsibility for any unauthorised use of your Member Account and any activity linked to your Member Account, including by a minor (which in all events is prohibited).</Typography>
                    <Typography>5.6 You must not share your Customer Account or password with another person, let anyone else access or use your Customer Account or do any other thing that may jeopardise the security of your Customer Account.</Typography>
                    <Typography>5.7 If you become aware of, or reasonably suspect that security in your Customer Account has been compromised, including loss, theft or unauthorised disclosure of your password and Customer Account details, you must notify us immediately.</Typography>
                    <Typography>5.8 You are solely responsible for maintaining the confidentiality of your password and you will be held responsible for all uses of your Customer Account, including any purchases made under the Customer Account, whether those purchases were authorised by you or not.</Typography>
                    <Typography>5.9 You are solely responsible for anything that happens through your Customer Account, whether or not you undertook those actions. You acknowledge that your Customer Account may be terminated if someone else uses it and engages in any activity that breaches these Terms and Conditions or is otherwise illegal.</Typography>
                    <Typography>5.10 We are not responsible for any abuse or misuse of your Customer Account by third parties due to your disclosure of your login details to any third party, whether such disclosure is intentional or accidental, active or passive.Account Transfers</Typography>
                    <Typography>5.11 You are not allowed to transfer Parrot Tokens between Customer Accounts, or from your Customer Account to other players, or to receive Parrot Tokens from other Customer Accounts into your Customer Account, or to transfer, sell and/or acquire Customer Accounts.</Typography>
                    <Typography>Inactive Customer Accounts</Typography>
                    <Typography>5.12 We reserve the right to deactivate your Customer Account if it is deemed to be an Inactive Account.</Typography>
                    <Typography>5.13 If no transaction has been recorded on your Customer Account for 30 consecutive months, we will remit the balance of the redeemed value of the Prizes in your Customer Account to you if you can be located.</Typography>
                    <Typography>Closing of Customer Accounts</Typography>
                    <Typography>5.14 If you wish to close your Customer Account you may do so at any time by selecting the “Support and Feedback” link on the Platform and submitting a request to close your Customer Account. Closing your Customer Account will forfeit all continued access to and right to use, enjoy or benefit from any Parrot Tokens and unredeemed Prizes associated with your Customer Account.</Typography>
                    <Typography>5.15 If the reason behind the closure of your Customer Account is related to concerns about possible responsible gaming issues you must indicate this in your request to close your Customer Account. Our time-out and exclusion procedures are summarised at clause 9.4 of these Terms and Conditions and are set out in detail in our <BrandedLink to={'/responsibleGaming'} text={'Responsible Social Gaming Policy'} />.</Typography>
                    <Typography>5.16 You will be able to open your Customer Account again by sending a request to the Customer Support team. All requests for the re-opening of an account will be evaluated by our Customer Support and Compliance teams, which shall abide by strict customer protection guidelines.</Typography>
                    <Typography>Discretion to Refuse or Close Accounts</Typography>
                    <Typography>5.17 We reserve the right to refuse or close a Customer Account in our sole discretion.</Typography>
                    <Typography>6. CONTESTS</Typography>
                    <Typography>Rules</Typography>
                    <Typography>6.1 Contests and Games offered on the Platform may have their own rules which are available on the Platform. It is your responsibility to read the rules of a Contest or Game before playing or ask a team member in discord. You must familiarise yourself with the applicable terms of play and read the relevant rules before playing any Game.Parrot Tokens Balance</Typography>
                    <Typography>6.2 You may participate in any Contest or Game only if you have sufficient Parrot Tokens in your Customer Account for such Participation. This excludes free games.</Typography>
                    <Typography>6.3 Parrot Tokens that have been submitted for play and accepted cannot be changed, withdrawn or cancelled and the Parrot Tokens will be drawn from your Parrot Tokens balance instantly.</Typography>
                    <Typography>Void Games</Typography>
                    <Typography>6.4 We reserve the right to declare Participation in a Contest or Game void, partially or in full, if, in our sole discretion, we deem it obvious that there was an error, mistake, misprint or technical error on the pay-table, win-table, minimum or maximum stakes, odds or software.</Typography>
                    <Typography>Final Decision</Typography>
                    <Typography>6.5 In the event of a discrepancy between the result showing on a user’s device and the OGS Group server software, the result showing on the OGS Group server software will be the official and governing result.</Typography>
                    <Typography>7. PROMOTIONS</Typography>
                    <Typography>7.1 All promotions, including Sweepstakes Games, contests, special offers and bonuses are subject to these Terms and Conditions, the <BrandedLink to={'/sweepsRules'} text={'Sweeps Rules'} /> and to additional terms that may be published at the time of the promotion.</Typography>
                    <Typography>7.2 In the event and to the extent of any conflict between these Terms and Conditions and any promotion-specific terms and conditions, the promotion-specific terms and conditions will prevail.</Typography>
                    <Typography>7.3 OGS Group reserves the right to withdraw or modify such promotions without prior notice to you.</Typography>
                    <Typography>7.4 If, in the reasonable opinion of OGS Group, we form the view that a Registered Customer is abusing any promotion, to derive any advantage or gain for themselves or other Registered Customers, including by way of Fraudulent Conduct, we may, at our sole discretion, withhold, deny or cancel any advantage, bonus or Prize as we see fit.</Typography>
                    <Typography>7.5 Without limiting clause 11.4, you confirm that you grant OGS Group an irrevocable, perpetual, worldwide, non-exclusive, royalty-free licence to use in whatever way we see fit, and without further acknowledgement of you as the author, any Content you post or publish as part of a promotion, contest or competition.</Typography>
                    <Typography>8. REDEMPTION OF PRIZES</Typography>
                    <Typography>Prize Redemption Methods</Typography>
                    <Typography>8.1 Subject to these Terms and Conditions:</Typography>
                    <ol type='a'>
                        <li>When you choose to redeem Prizes for gift cards, the gift cards shall be allocated to the email address that you have registered against your Customer Account, or if this is not technically possible, then to an alternative email address you nominate; and</li>
                        <li>if you choose to withdrawal to ERC-20 “PSC” tokens, the tokens are to be withdrawn to the Ethereum wallet that you registered with.</li>
                    </ol>
                    <Typography>Verification Checks</Typography>
                    <Typography>8.2 You agree that we are entitled to conduct any identification, credit and other verification checks that we may reasonably require and/or that are required of us under applicable laws and regulations or by relevant regulatory authorities.</Typography>
                    <Typography>8.3 Until all required verification checks are completed to our satisfaction:</Typography>
                    <ol type='a'>
                        <li>any request you have made for redemption of Prizes will remain pending; and</li>
                        <li>we are entitled to restrict your Customer Account in any manner that we may reasonably deem appropriate, including by suspending or deactivating your Customer Account.</li>
                    </ol>
                    <Typography>8.4 We will carry out additional verification procedures for any cumulative or single redemption of Prizes exceeding a value of USD$2, or equivalent and reserve the right to carry out such additional verification procedures in the case of a request to redeem a Prize of a lower amount. Additional verification procedures may, for example, include requests for and our examination of copies of your identification documentation (including photo identification) such as a passport and proof of your address such as a utility bill.</Typography>
                    <Typography>8.5 Where any identification, credit or other verification check we require cannot be completed to our satisfaction because you have not provided any document we request from you in the form that we require within 30 days of the date the document was first requested, then we are under no obligation to continue with the verification check and we may, in our sole discretion, deactivate your Customer Account.</Typography>
                    <Typography>8.6 Players who request the redemption of Prizes held in a deactivated or suspended account should contact Customer Support.</Typography>
                    <Typography>Limits and Fees</Typography>
                    <Typography>8.7 We reserve the right to charge fees for processing the redemption of Prizes to you and to set a minimum redemption value of USD$100 for Prize redemptions.</Typography>
                    <Typography>8.8 In New York and Florida, the maximum redemption value for a Prize won on any one Contest or Game is USD$5,000 and any Prize with a value in excess of USD$5,000 will not be redeemed.</Typography>
                    <Typography>8.9 We reserve the right, in our sole discretion, to limit the value of Prize redemptions for each Participant to USD$10,000 per day.</Typography>
                    <Typography>Your Responsibility for Prize Redemptions and Accuracy of Details</Typography>
                    <Typography>8.10 When you choose to redeem Prizes for gift cards pursuant to clause 8.1(a), it is your sole responsibility to ensure that the email address and all relevant details you provide are accurate.  If the details you have provided are not accurate, and we have processed the redemption using the details you have provided, the redemption of that Prize is complete and we are not required to reissue the gift cards.</Typography>
                    <Typography>8.11 If no valid email address is provided to us within 60 days of a request from us to do so, OGS Group is not obliged to allocate the gift cards to you and may in its discretion deem the Prizes to be void.</Typography>
                    <Typography>8.12 When you choose to redeem Prizes for cash or other methods, it is your sole responsibility to ensure that your financial institution will accept payment from us into your bank account. OGS Group has no obligation to check whether your financial institution will accept payments from us to your nominated bank account.</Typography>
                    <Typography>8.13 Subject to clause 8.15, we will not make payments into an account or online wallet which does not match the name you provided when registering your Customer Account.</Typography>
                    <Typography>8.14 Prizes redeemed for cash:</Typography>
                    <ol type='a'>
                        <li>will be paid into a joint account or joint wallet provided that one of the names on the joint account or joint wallet matches the name you provided when registering your Customer Account and all verification checks we require in relation to you and the other account holder are completed to our satisfaction. For the avoidance of doubt, if either joint account holder does not satisfy our verification requirements, as determined solely at our discretion, we will not make payments into the nominated joint account;</li>
                        <li>will not be paid into:</li>
                        <ol type='i'>
                            <li>a joint account or joint wallet where one of the joint owners is a minor; or</li>
                            <li>custodial accounts; or</li>
                            <li>any account held on trust for, or for the benefit of, a minor.</li>
                        </ol>
                    </ol>
                    <Typography>8.15 Where you are required to provide the details of your financial institution, bank account or online wallet, you agree that you are solely responsible for the accuracy of those details. You further agree that, where you have chosen to redeem a Prize for cash and the details you have provided are not accurate, and we have processed the payment using the details you have provided, the redemption of that Prize is complete and we cannot and are not required to reverse or reissue the payment.</Typography>
                    <Typography>8.16 You acknowledge and agree that, if your financial institution will not accept payments from OGS Group or where your bank account or online wallet does not meet the requirements in these Terms and Conditions:</Typography>
                    <ol type='a'>
                        <li>you will be required to nominate an alternative bank account for the payment;</li>
                        <li>there will be delays in the processing of the payment to you; and</li>
                        <li>if you are unable to nominate an alternative bank account which meets the requirements set out in these Terms and Conditions within 60 days of a request from us to do so, OGS Group is not obliged to make relevant payments to you and may in its discretion deem the Prizes to be void.</li>
                    </ol>
                    <Typography>Currency</Typography>
                    <Typography>8.17 Direct bank transfer payments are executed in USD. It is a Player’s responsibility to ensure that their nominated bank account can accept transactions in USD.</Typography>
                    <Typography>8.18 All foreign exchange transaction fees, charges or related costs that you may incur as a result of, or in relation to, payments made by the OGS Group to you are to be borne solely by you, including but not limited to any losses or additional costs arising from foreign exchange fluctuations.</Typography>
                    <Typography>Timing and Frequency for Prize Redemptions</Typography>
                    <Typography>8.19 We process requests to redeem Prizes in the order in which they are received. Our goal is to process your request as soon as practicable.</Typography>
                    <Typography>8.20 We will only process one Prize redemption request per Customer Account in any 24 hour period.</Typography>
                    <Typography>8.21 Where you choose to redeem Prizes for cash you acknowledge and agree that it may take up to 10 business days to process the relevant payment into your nominated bank account.</Typography>
                    <Typography>8.22 There may be delays in payments due to our identity verification process and certain payment methods will require additional verification at time of redemption.</Typography>
                    <Typography>8.23 Payments of over USD$10,000 may require a longer processing time than usual due to bank clearance and security and fraud checks and may also be paid in more than one lump sum. This may add up to 14 days to the normal processing time, but is dependent on the circumstances of each individual case.</Typography>
                    <Typography>8.24 Without limiting clause 8.8, Players can request to redeem Prizes of any value, however we reserve the right to allocate or pay Prizes in smaller increments over a number of days until all of the Prize has been allocated or paid.</Typography>
                    <Typography>Payment Administration Agent</Typography>
                    <Typography>8.25 You acknowledge and agree that we may in our sole discretion, from time to time, appoint one or more Payment Administration Agents to accept or make payments (including merchant facilities) from or to Players on our behalf.</Typography>
                    <Typography>8.26 A Payment Administration Agent will have the same rights, powers and privileges that we have under these Terms and Conditions and will be entitled to exercise or enforce their rights, powers and privileges as our agent or in their own name. In no event shall we be liable to any Player for any loss, damage or liability resulting from the Payment Administration Agent’s negligence and/or acts beyond the authority given by OGS Group.</Typography>
                    <Typography>8.27 All transactions between you and OGSN (if applicable) occur within the United States.Expiry and Forfeiture</Typography>
                    <Typography>8.28 Parrot Tokens are only valid for 180 days from the date you last logged on to your Customer Account and will thereafter automatically expire.</Typography>
                    <Typography>8.29 Parrot Tokens may be forfeited if a Customer Account is deactivated for any reason, or at our discretion.</Typography>
                    <Typography>Updating Payment Details</Typography>
                    <Typography>8.30 Updating or adding additional payment details for the sole purpose of redeeming Prizes may only be done by you when logged into your Customer Account and when you are undergoing the process of redeeming a Prize. We cannot update or add additional payment details on your behalf.</Typography>
                    <Typography>Refused Prizes</Typography>
                    <Typography>8.31 If you choose to redeem Prizes for cash but refuse to accept payments made to your nominated bank account by OGS Group, you must refuse the amount in its entirety. Where you refuse to accept payment to your nominated bank account more than twice in any 3 month period, OGS Group reserves the right to suspend your Customer Account to undertake investigations to ensure that the Platform is not being used as a vehicle for fraudulent activity.</Typography>
                    <Typography>Mistaken Credits</Typography>
                    <Typography>8.32 If we mistakenly credit your Customer Account from time to time with Prizes that do not belong to you, whether due to a technical error, human error or otherwise, the amount credited will remain OGS Group property and will be deducted from your Customer Account. If you have been transferred cash or gift cards that do not belong to you prior to us becoming aware of the error, the mistakenly paid amount will (without prejudice to other remedies and actions that may be available at law) constitute a debt owed by you to us. In the event of an incorrect crediting, you are obliged to notify Customer Support by using the “Contact” link on the Platform without delay.</Typography>
                    <Typography>9. RESPONSIBLE SOCIAL GAMING</Typography>
                    <Typography>Policy</Typography>
                    <Typography>9.1 OGS Group actively supports responsible gaming and encourages its Players to make use of a variety of responsible gaming features so as to better manage their Customer Account.</Typography>
                    <Typography>9.2 We refer to you our <BrandedLink to={'/responsibleGaming'} text={'Responsible Social Gaming Policy'} /> for full details.</Typography>
                    <Typography>9.3 OGS Group is committed to providing excellent customer service. As part of that pledge, OGS Group is committed to supporting responsible gaming. Although OGS Group will use all reasonable endeavours to enforce its responsible gaming policies, OGS Group does not accept any responsibility or liability if you nevertheless continue gaming and/or seek to use the Platform with the intention of deliberately avoiding the relevant measures in place and/or OGS Group is unable to enforce its measures/policies for reasons outside of OGS Group’s reasonable control.</Typography>
                    <Typography>Take a Break (Time-Out) and Self-Exclusion</Typography>
                    <Typography>9.4 You may, at any time, request a time-out or self-exclusion from our Contests and Games. To view the various options available refer to our <BrandedLink to={'/responsibleGaming'} text={'Responsible Social Gaming Policy'} />.</Typography>
                    <Typography>Player Protection Policy</Typography>
                    <Typography>9.5 We want to ensure that you enjoy your experience playing our Games in a safe and responsible manner. On the Responsible Social Gaming page of our Platform’s principal website, we have a detailed <BrandedLink to={'/responsibleGaming'} text={'Responsible Social Gaming Policy'} />, which includes a list of mechanisms which you can enlist in order to ensure a safer gaming experience.</Typography>
                    <Typography>10. FRAUDULENT CONDUCT</Typography>
                    <Typography>10.1 You will not, directly or indirectly:</Typography>
                    <ol type='a'>
                        <li>hack into any part of the Contests, Games or Platform through password mining, phishing, or any other means;</li>
                        <li>attempt to modify, reverse engineer, or reverse-assemble any part of the Contests, Games or Platform;</li>
                        <li>knowingly introduce viruses, Trojans, worms, logic bombs, spyware, malware, or other similar material;</li>
                        <li>circumvent the structure, presentation or navigational function of any Game so as to obtain information that OGS Group has chosen not to make publicly available on the Platform;</li>
                        <li>engage in any form of cheating or collusion;</li>
                        <li>use the Platform and the systems of OGS Group to facilitate any type of illegal money transfer (including money laundering proceeds of crime); or</li>
                        <li>participate in or take advantage of, or encourage others to participate in or take advantage of schemes, organisations, agreements, or groups designed to share:</li>
                        <ol type='i'>
                            <li>special offers or packages emailed to a specific set of players and redeemable by URL; or</li>
                            <li>identification documents (including, but not limited to, photographs, bills and lease documents) for the purpose of misleading OGS Group as to a Player’s identity.</li>
                        </ol>
                    </ol>
                    <Typography>10.2 You must not use the Platform for any unlawful or fraudulent activity or prohibited transaction (including Fraudulent Conduct) under the laws of any jurisdiction that applies to you.  We monitor all transactions in order to prevent money laundering.</Typography>
                    <Typography>10.3 If OGS Group suspects that you may be engaging in, or have engaged in fraudulent, unlawful or improper activity, including money laundering activities or any conduct which violates these Terms and Conditions, your access to the Platform will be deactivated immediately and your Customer Account may be suspended. If your Customer Account is deactivated or suspended under such circumstances, OGS Group is under no obligation to redeem any Parrot Tokens or Prizes that may be in your Customer Account. In addition, OGS Group may pass any necessary information on to the relevant authorities, other online service providers, banks, credit card companies, electronic payment providers or other financial institutions. You will cooperate fully with any OGS Group investigation into such activity.</Typography>
                    <Typography>10.4 If you suspect any unlawful or fraudulent activity or prohibited transaction by another Player, please notify us immediately via the means of communication listed in the Customer Complaints procedure (described in clause 17).</Typography>
                    <Typography>11. INTELLECTUAL PROPERTY</Typography>
                    <Typography>11.1 The computer software, the computer graphics, the Platform and the user interface that we make available to you is owned by, or licensed to, OGS or its associates and protected by copyright laws. You may only use the software for your own personal, recreational uses in accordance with all rules, terms and conditions we have established (including these Terms and Conditions and the <BrandedLink to={'/sweepsRules'} text={'Sweeps Rules'} />) and in accordance with all applicable laws, rules and regulations.</Typography>
                    <Typography>11.2 You acknowledge that OGS Group is the proprietor or authorized licensee of all intellectual property in relation to any Content.</Typography>
                    <Typography>11.3 Your use of the Contests, Games and Platform does not provide you with any intellectual property rights in the Content, Contests, Games or Platform.</Typography>
                    <Typography>11.4 You grant us, and represent and warrant that you have the right to grant us, an irrevocable, perpetual, worldwide, non-exclusive, royalty-free licence to use in whatever way we see fit, any information, images, videos, comments, messages, music or profiles you publish or upload to any website or social media page controlled and operated by OGS Group.</Typography>
                    <Typography>11.5 You must not reproduce or modify the Content in any way, including by removing any copyright or trade mark notice.</Typography>
                    <Typography>11.6 All trade marks and logos displayed in the Contests, Games and Platform are the property of their respective owners and are protected by applicable trade mark and copyright laws.</Typography>
                    <Typography>12. THIRD PARTY WEBSITES AND LINKS</Typography>
                    <Typography>Third Party Websites</Typography>
                    <Typography>12.1 You acknowledge and agree that OGS Group:</Typography>
                    <ol type='a'>
                        <li>is not responsible for Third Party Websites; and</li>
                        <li>makes no guarantee as to the content, functionality, or accuracy of any Third Party Website.</li>
                    </ol>
                    <Typography>12.2 You further acknowledge that some Third Party Websites may be fraudulent in nature, offering Parrot Tokens which the operators of those websites are not authorized to provide, in an effort to induce you to reveal personal information (including passwords, account information and credit card details). You agree that OGS Group is not responsible for any actions you take at the request or direction of these, or any other Third Party Websites.</Typography>
                    <Typography>12.3 Third Party Websites are subject to the terms and conditions outlined by that third party.</Typography>
                    <Typography>12.4 Any links to Third Party Websites do not:</Typography>
                    <ol type='a'>
                        <li>indicate a relationship between OGS Group and the third party; or</li>
                        <li>indicate any endorsement or sponsorship by OGS Group of the Third Party Website, or the goods or services it provides, unless specifically indicated by OGS Group.</li>
                    </ol>
                    <Typography>12.5 Where a website controlled and operated by OGS Group contains links to various social networking sites, such as Facebook® and Twitter®, you acknowledge and agree that:</Typography>
                    <ol>
                        <li>any comments or content that you post on such social networking sites are subject to the terms and conditions of that particular social networking site;</li>
                        <li>you will not post any comments that are false, misleading or deceptive or defamatory to us, our employees, agents, officers or other players; and</li>
                        <li>we are not responsible or liable for any comments or content that you or others post on social networking sites.</li>
                    </ol>
                    <Typography>13. DISRUPTIONS AND CHANGE</Typography>
                    <Typography>No warranties</Typography>
                    <Typography>13.1 The Platform is provided on an “as is” basis and to the fullest extent permitted by law, we make no warranty or representation, whether express or implied, in relation to the satisfactory quality, fitness for purpose, completeness or accuracy of the Platform (including the Contests, Games and Content).</Typography>
                    <Typography>Malfunctions</Typography>
                    <Typography>13.2 OGS Group is not liable for any downtime, server disruptions, lagging, or any technical or political disturbance to Game play, nor attempts by you to Participate by methods, means or ways not intended by us.</Typography>
                    <Typography>13.3 OGS Group accepts no liability for any damages or losses which are deemed or alleged to have arisen out of or in connection with any Platform or its content including, without limitation, delays or interruptions in operation or transmission, loss or corruption of data, communication or lines failure, any person’s misuse of a Platform or its content or any errors or omissions in content.</Typography>
                    <Typography>13.4 In the event of a Platform system malfunction all Game play on that Platform is void.</Typography>
                    <Typography>13.5 In the event a Game is started but fails to conclude because of a failure of the system, OGS Group will use commercially reasonable efforts to reinstate the amount of Parrot Tokens played in the Contest or Game to you by crediting it to your Customer Account. OGS Group reserves the right to alter Player balances and account details to correct such mistakes.</Typography>
                    <Typography>13.6 OGS Group reserves the right to remove any part of the Contests or Games from the Platform at any time. Any part of the Games that indicate incorrect behaviour affecting Prize redemption, game data, Parrot Token balances or other balances, that may be due to misconfiguration or a bug, will be cancelled and removed from the Platform. Player balances and account details may be altered by OGS Group in such cases in order to correct any mistake.</Typography>
                    <Typography>Change</Typography>
                    <Typography>13.7 OGS Group reserves the right to suspend, modify, remove or add Content to the Platform at its sole discretion with immediate effect and without notice to you. We will not be liable to you for any loss suffered as a result of any changes made or for any modification or suspension of or discontinuance of the Platform (including any Contest or Game thereon) and you will have no claims against OGS Group in such regard.Service Suspension</Typography>
                    <Typography>13.8 We may temporarily suspend the whole or any part of the Platform for any reason at our sole discretion. We may, but will not be obliged to, give you as much notice as is reasonably practicable of such suspension. We will restore the Platform, as soon as is reasonably practicable, after such temporary suspension.</Typography>
                    <Typography>14. VIRUSES</Typography>
                    <Typography>14.1 Although we take all reasonable measures to ensure that the Platform is free from computer viruses we cannot and do not guarantee that the Platform is free of such problems. It is your responsibility to protect your systems and have in place the ability to reinstall any data or programs lost due to a virus.</Typography>
                    <Typography>15. <BrandedLink to={'/privacyPolicy'} text={'Privacy Policy'} /></Typography>
                    <Typography>15.1 OGS Group is committed to protecting and respecting your privacy and complying with all applicable data protection and privacy laws.</Typography>
                    <Typography>15.2 Our <BrandedLink to={'/privacyPolicy'} text={'Privacy Policy'} /> is inseparably linked to these Terms and Conditions and its acceptance is a prerequisite to account registration.</Typography>
                    <Typography>15.3 You hereby consent to receive marketing communications from OGS Group in respect of its offerings by way of email, post, SMS and telephone notifications, any of which you may unsubscribe from at any time by contacting Customer Support via this <a className={classes.link} href='https://parrotsocial.app/contact/' target='_blank' rel='noreferrer'>form</a>.</Typography>
                    <Typography>16. USE OF LIVE CHAT SERVICES</Typography>
                    <Typography>16.1 We may provide you with a Live Chat service to talk to our Customer Support representatives or to talk to other Players.  This may include use of our Facebook® wall. It is your responsibility to use these services only for their intended purposes.  You are not permitted to use our Live Chat services for illegal purposes.</Typography>
                    <Typography>16.2 Be careful what you post on any Live Chat service. We review and moderate chats and keep a log and record of statements. Your use of the Live Chat service should be for recreational and social purposes only.</Typography>
                    <Typography>16.3 Spamming on Live Chat is prohibited. You are prohibited from intimidating, harassing or abusing other Players or OGS Group employees and representatives.</Typography>
                    <Typography>16.4 You will not use any Live Chat service to engage in any form of harassment or offensive behaviour, including but not limited to, threatening, derogatory, abusive or defamatory statements, or racist, sexually explicit, pornographic, obscene, or offensive language.</Typography>
                    <Typography>16.5 You will not use any Live Chat service to infringe the privacy rights, property rights, or any other rights of any person.</Typography>
                    <Typography>16.6 You will not submit any kind of material or information on any Live Chat service that is fraudulent or otherwise unlawful or violates any law.</Typography>
                    <Typography>16.7 You will not use any Live Chat service to distribute, promote or otherwise publish any material containing any solicitation for funds, advertising or solicitation for goods or services of other forums.</Typography>
                    <Typography>16.8 You will not use any Live Chat service to distribute, promote or otherwise publish any kind of malicious code or do anything else that might cause harm to the Platform or to other Player’s systems in any way.</Typography>
                    <Typography>16.9 We reserve the right to monitor anything and everything submitted by you to any Live Chat service to ensure that it  conforms to content guidelines that are monitored by us and subject to change from time to time.</Typography>
                    <Typography>16.10 If you breach any of the provisions relating to a Live Chat service, we may ban you from using that Live Chat service or all Live Chat services and/or suspend or deactivate your Customer Account. If we deactivate your Customer Account, we reserve the right to cancel or refuse to redeem any Prizes.</Typography>
                    <Typography>16.11 We reserve the right to remove the Live Chat service from any Platform if abused.</Typography>
                    <Typography>16.12 We will not be liable if damage arises out of the Live Chat service.</Typography>
                    <Typography>16.13 You agree to indemnify us against any damage arising out of your illegal, unlawful or inappropriate conduct or arising out of violation of the provisions in this clause 16 or any other rules on the Platform applying to the Live Chat service.</Typography>
                    <Typography>16.14 You will not collude in any way through the Live Chat service.  Players are encouraged to report any suspicious behaviour to Customer Support via this <a className={classes.link} href='https://parrotsocial.app/contact/' target='_blank' rel='noreferrer'>form</a>.</Typography>
                    <Typography>16.15 We reserve the right to report any suspicious behaviour or chats on the Live Chat service to the authorities.</Typography>
                    <Typography>17. COMPLAINTS AND CUSTOMER SUPPORT</Typography>
                    <Typography>17.1 If you would like to contact our Customer Support department or have a complaint regarding our Platform (including any Contest or Game), you may contact us via this <a className={classes.link} href='https://parrotsocial.app/contact/' target='_blank' rel='noreferrer'>form</a>.</Typography>
                    <Typography>17.2 TO PROTECT YOUR PRIVACY, ALL EMAIL COMMUNICATIONS BETWEEN YOU AND OGS GROUP SHOULD BE CARRIED OUT USING THE EMAIL ADDRESS THAT YOU HAVE REGISTERED AGAINST YOUR CUSTOMER ACCOUNT HELD WITH OGS GROUP. FAILURE TO DO SO MAY RESULT IN OUR RESPONSE BEING DELAYED.</Typography>
                    <Typography>17.3 The following information must be included in any written communication with OGS Group (including a complaint):</Typography>
                    <ol type='a'>
                        <li>your username;</li>
                        <li>your first and last name, as registered on your Customer Account;</li>
                        <li>a detailed explanation of the complaint/claim; and</li>
                        <li>any specific dates and times associated with the complaint/claim (if applicable).</li>
                    </ol>
                    <Typography>17.4 Failure to submit a written communication with the information outlined above may result in a delay in our ability to identify and respond to your complaint/claim in a timely manner. The Parrot Social Player Support Team (PST) will inquire into official complaints immediately. The PST will endeavour to respond to official complaints within 10 calendar days of lodgement.</Typography>
                    <Typography>17.5 In some circumstances, the PST will require up to 20 days to respond to a complaint. In this case, the player will be informed of the delay within 10 days of lodging the complaint.</Typography>
                    <Typography>18. DEACTIVATION / SUSPENSION OF ACCOUNT</Typography>
                    <Typography>18.1 OGS Group hereby reserves the right to deactivate or suspend your Customer Account for any reason whatsoever at any time without notifying you.</Typography>
                    <Typography>18.2 Without limiting clause 18.1, we hereby reserve the right, at our sole discretion, to deactivate or suspend your Customer Account (notwithstanding any other provision contained in these Terms and Conditions) where we have reason to believe that you have engaged or are likely to engage in any of the following activities:</Typography>
                    <ol type='a'>
                        <li>you breached, or assisted another party to breach, any provision of these Terms and Conditions or the <BrandedLink to={'/sweepsRules'} text={'Sweeps Rules'} />, or we have a reasonable ground to suspect such breach;</li>
                        <li>you have more than one Customer Account, including any Inactive Account, on any Platform;</li>
                        <li>the name registered on your Customer Account does not match the name on the financial/bank account and/or the credit/debit card(s) used to make purchases on the said Customer Account;</li>
                        <li>your communication with us consists of harassment or offensive behaviour, including (but not limited to) threatening, derogatory, abusive or defamatory statements, or racist, sexually explicit, pornographic, obscene or offensive language;</li>
                        <li>your Customer Account is deemed to be an Inactive Account;</li>
                        <li>you become bankrupt;</li>
                        <li>you provide incorrect or misleading information while registering a Customer Account;</li>
                        <li>your identity cannot be verified;</li>
                        <li>you attempt to use your Customer Account through a VPN, proxy or similar service that masks or manipulates the identification of your real location, or by otherwise providing false or misleading information regarding your citizenship, location or place of residence, or by playing Contests or Games using the Platform through a third party or on behalf of a third party;</li>
                        <li>you are not over 18 years of age or such higher minimum legal age of majority as stipulated in the jurisdiction of your residence;</li>
                        <li>you are located in a jurisdiction where Participation is illegal;</li>
                        <li>you have allowed or permitted (whether intentionally or unintentionally) someone else to Participate using your Customer Account;</li>
                        <li>you have played in tandem with other Player(s) as part of a club, group, etc., or played the Contests or Games in a coordinated manner with other Player(s) involving the same (or materially the same) selections;</li>
                        <li>without limiting clause 8.11, where OGS Group has received a “charge back”, claim or dispute and/or a “return” notification via a payment mechanism used on your financial/bank account or online wallet;</li>
                        <li>you have failed our due diligence procedures, or are found to be colluding, cheating, money laundering or undertaking any kind of fraudulent activity; or</li>
                        <li>it is determined by OGS Group that you have employed or made use of a system (including machines, computers, software or other automated systems such as bots) designed specifically to gain an unfair advantage.</li>
                    </ol>
                    <Typography>18.3 If OGS Group deactivates or suspends your Customer Account for any of the reasons referred to in clause 18.2 above, you will be liable for any and all claims, losses, liabilities, damages, costs and expenses incurred or suffered by OGS Group (together “Claims”) arising therefrom and you will indemnify and hold OGS Group harmless on demand for such Claims.</Typography>
                    <Typography>18.4 If we have reasonable grounds to believe that you have participated in any of the activities set out in clause 18.2 above then we reserve the right to withhold all or part of the balance and/or recover from your Customer Account any Prizes or Parrot Tokens that are attributable to any of the activities contemplated in clause 18.2. In such circumstances, your details may be passed on to any applicable regulatory authority, regulatory body or any other relevant external third parties.</Typography>
                    <Typography>18.5 If your Customer Account is deactivated as a result of closure of the Platform or similar event, we will make commercially reasonable efforts to enable redemption of any existing Prizes associated with your Customer Account, but all of your rights to use, enjoy or benefit from the Parrot Tokens will be terminated.</Typography>
                    <Typography>18.6 The rights set out in clause 18 are without prejudice to any other rights that we may have against you under these Terms and Conditions or otherwise.</Typography>
                    <Typography>19. INDEMNITY AND LIMITATION OF LIABILITY</Typography>
                    <Typography>Indemnity</Typography>
                    <Typography>19.1 YOU HEREBY AGREE TO INDEMNIFY AND HOLD HARMLESS US, OUR DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, AGENTS AND AFFILIATES, OUR ULTIMATE PARENT AND PARENT COMPANIES AND ANY OF OUR SUBSIDIARIES AGAINST ANY AND ALL COSTS, EXPENSES, LIABILITIES AND DAMAGES (WHETHER DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE OR OTHER) ARISING FROM ANY PARTICIPATION BY YOU, INCLUDING WITHOUT LIMITATION:</Typography>
                    <ol type='a'>
                        <li>ACCESSING OR USING THE PLATFORM;</li>
                        <li>RE-USE OF ANY CONTENT AT, OR OBTAINED FROM, THE PLATFORM OR ANY OTHER SOURCE WHATSOEVER;</li>
                        <li>FACILITATING OR MAKING A PAYMENT INTO YOUR CUSTOMER ACCOUNT;</li>
                        <li>PLAYING THE GAMES THROUGH ANY DELIVERY MECHANISM OFFERED; AND</li>
                        <li>ACCEPTANCE AND USE OF ANY PRIZE.Limitation of Liability</li>
                    </ol>
                    <Typography>Limitation of Liability</Typography>
                    <Typography>19.2 TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO 
                        CIRCUMSTANCES WHATEVER WILL WE OR OUR AFFILIATES, SUBSIDIARIES, PARTNERS, 
                        OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS, AGENTS, LICENSORS, 
                        SUBCONTRACTORS AND SUPPLIERS, BE RESPONSIBLE OR LIABLE TO YOU OR TO ANY 
                        OTHER ENTITY, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, UNDER ANY
                        LEGAL THEORY, WHETHER CONTRACT, TORT OR OTHERWISE, FOR ANY INDIRECT, 
                        INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES, 
                        INCLUDING ANY LOST PROFITS AND LOST BUSINESS OPPORTUNITIES, BUSINESS 
                        INTERRUPTION, LOST REVENUE, INCOME, GOODWILL, USE OF DATA OR OTHER 
                        INTANGIBLE LOSSES, IN EACH CASE THAT RESULT FROM OR RELATE IN ANY MANNER 
                        TO YOUR PARTICIPATION OR ANY OTHER ACT OR OMISSION BY US.</Typography>
                    <Typography>19.3 TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO 
                        CIRCUMSTANCES WILL WE, OUR AFFILIATES, SUBSIDIARIES, PARTNERS, OFFICERS, 
                        DIRECTORS, EMPLOYEES, SHAREHOLDERS, AGENTS, LICENSORS, SUBCONTRACTORS OR 
                        SUPPLIERS, BE LIABLE TO YOU FOR MORE THAN THE AMOUNT YOU HAVE PAID US IN 
                        THE THIRTY (30) DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST 
                        ASSERT ANY SUCH CLAIM. YOU ACKNOWLEDGE AND AGREE THAT IF YOU HAVE NOT 
                        PAID US ANY AMOUNTS IN THE THIRTY (30) DAYS IMMEDIATELY PRECEDING THE DATE 
                        ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM, YOUR SOLE AND EXCLUSIVE REMEDY 
                        FOR ANY DISPUTE WITH US IS TO STOP USING THE PLATFORM AND TO CLOSE YOUR 
                        CUSTOMER ACCOUNT.</Typography>
                    <Typography>19.4 YOU RECOGNIZE AND AGREE THAT THE WARRANTY DISCLAIMERS IN CLAUSES 13 
                        AND 14, AND THE INDEMNITIES AND LIMITATIONS OF LIABILITY IN THIS CLAUSE 19, 
                        ARE MATERIAL AND BARGAINED-FOR BASES OF THESE TERMS AND THAT THEY HAVE 
                        BEEN TAKEN INTO ACCOUNT AND REFLECTED IN THE DECISION BY YOU TO ENTER INTO 
                        THESE TERMS AND CONDITIONS. Depending on where you reside and use the Platform, 
                        some of the limitations contained in this clause 19 may not be permissible. In such case, 
                        they will not apply to you, solely to the extent so prohibited.</Typography>
                    <Typography>Negligence and Wilful Misconduct</Typography>
                    <Typography>19.5 NOTHING IN THESE TERMS AND CONDITIONS WILL OPERATE SO AS TO EXCLUDE 
                        ANY LIABILITY OF OGS GROUP FOR DEATH OR PERSONAL PHYSICAL INJURY THAT IS 
                        DIRECTLY AND PROXIMATELY CAUSED BY OGS GROUP’S NEGLIGENCE OR WILFUL 
                        MISCONDUCT.</Typography>
                    <Typography>Survival of Obligations</Typography>
                    <Typography>19.6 CLAUSE 19 SURVIVES THE TERMINATION OF THESE TERMS AND CONDITIONS FOR 
                        ANY REASON.</Typography>
                    <Typography>20. OGS GROUP NOT A FINANCIAL INSTITUTION</Typography>
                    <Typography>Interest</Typography>
                    <Typography>20.1 You will not receive any interest on outstanding Prizes and you will not treat OGS 
                        Group as a financial institution.</Typography>
                    <Typography>No legal or tax advice</Typography>
                    <Typography>20.2 OGS Group does not provide advice regarding tax and/or legal matters. Players who 
                        wish to obtain advice regarding tax and legal matters are advised to contact appropriate 
                        advisors.</Typography>
                    <Typography>21. DISPUTE RESOLUTION AND AGREEMENT TO ARBITRATE</Typography>
                    <Typography>PLEASE READ THIS CLAUSE 21 CAREFULLY AS IT REQUIRES YOU TO ARBITRATE CERTAIN DISPUTES AND CLAIMS ON AN INDIVIDUAL BASIS AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM OGS GROUP. This clause 21 will be construed under and be subject to the Federal Arbitration Act, notwithstanding any other choice of law set out in these Terms and Conditions.</Typography>
                    <Typography>21.1 By agreeing to these Terms and Conditions, you agree that any and all past, present and future disputes, claims or causes of action between you and OGS or OGSN, which arise out of, or are related to, these Terms and Conditions, the formation of these Terms and Conditions, the validity or scope of this clause 21, your Participation or other access to or use of the Platform, or any other dispute between you and OGS or OGSN, and whether arising prior to or after your agreement to this clause 21 (Dispute Resolution and Agreement to Arbitrate) (collectively, “Disputes”), will be governed by the procedure set out below.</Typography>
                    <Typography>Complaint Resolution</Typography>
                    <Typography>21.2 We want to address any concerns you may have without needing a formal legal case.</Typography>
                    <Typography>21.3 Before filing a claim against OGS Group, you agree to try to resolve any complaint in accordance with clause 17. If your complaint is not resolved after exhausting the internal complaints process outlined in clause 17, you may initiate Dispute resolution as set out in this clause 21.</Typography>
                    <Typography>21.4 OGS Group agrees that it will take all reasonable efforts to contact you and resolve any claim it may possess informally prior to taking any formal action.Arbitration</Typography>
                    <Typography>21.5 We Both Agree to Arbitrate. By agreeing to these Terms and Conditions, both you and OGS Group agree to resolve any Disputes through final and binding arbitration.</Typography>
                    <Typography>21.6 Opt-out of Agreement to Arbitrate. You may decline this agreement to arbitrate by contacting <a className={classes.link} href='mailto:support@parrotsocial.app?subject=Parrot Social'>support@parrotsocial.app</a> within 30 days of first accepting these Terms and Conditions. Your email must include your first and last name and a statement that you decline this arbitration clause. By opting out of this clause, you will not be precluded from using the Platform, but neither you nor OGS Group will be able to invoke the mutual agreement to arbitrate to resolve Disputes. Whether to agree to arbitration is an important decision. It is your decision to make and you ARE NOT REQUIRED TO rely solely on the information provided in these Terms and Conditions. You should take reasonable steps to conduct further research and, if you wish, to consult with counsel of your choice.</Typography>
                    <Typography>Arbitration Procedures and Fees</Typography>
                    <Typography>21.7 You and OGS Group agree that:</Typography>
                    <ol type='a'>
                        <li>the American Arbitration Association (AAA) will administer the arbitration under its Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes, or successor rules, which are in effect at the time arbitration is sought (the AAA Rules). Those rules are available at <a className={classes.link} href='https://www.adr.org' target='_blank' rel='noreferrer'>www.adr.org</a>;</li>
                        <li>arbitration will proceed on an individual basis;</li>
                        <li>arbitration will be handled by a sole arbitrator in accordance with the AAA Rules;</li>
                        <li>the AAA rules will govern payment of all arbitration fees; and</li>
                        <li>except as otherwise may be required by the AAA Rules, the arbitration will be held in New York, New York, or, at your election, conducted via telephone or other remote electronic means.</li>
                    </ol>
                    <Typography>21.8 If you are a resident of Canada, this clause 21 fully applies to you in all respects, except that “AAA Rules” shall be construed to mean the Canadian Dispute Resolution Procedures and Canadian Expedited Procedures of the International Centre for Dispute Resolution Canada, or successor rules, which are in effect at the time arbitration is sought. Those rules are available at <a className={classes.link} href='https://www.adr.org' target='_blank' rel='noreferrer'>www.adr.org</a>. Residents of all other countries shall be subject to the AAA Rules as defined in clause 21.7(a).</Typography>
                    <Typography>Arbitration to Proceed Individually</Typography>
                    <Typography>21.9 You and OGS Group agree that the arbitration of a Dispute will proceed on an individual basis and neither you nor OGS Group may bring a claim as a Collective Action.</Typography>
                    <Typography>21.10 Without limiting the generality of clause 21.9, and as an example only, a claim to resolve a Dispute against OGS Group will be deemed a Collective Arbitration if:</Typography>
                    <ol type='a'>
                        <li>two or more similar claims for arbitration are filed concurrently by or on behalf of one or more person; and</li>
                        <li>counsel for the two or more persons is the same, or share fees, or coordinate in any way across the arbitrations.</li>
                    </ol>
                    <Typography>21.11 For the purposes of clause 21.10, the term ‘concurrently’ means that both arbitrations are pending (filed but not resolved) at the same time.</Typography>
                    <Typography>Waiver of Class Action and Collective Arbitration</Typography>
                    <Typography>21.12 TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WHETHER THE DISPUTE IS HEARD IN ARBITRATION OR IN COURT, NEITHER YOU NOR OGS GROUP WILL BE ENTITLED TO CONSOLIDATE, JOIN OR COORDINATE DISPUTES WITH OR INVOLVING OTHER INDIVIDUALS OR ENTITIES, OR PARTICIPATE IN ANY COLLECTIVE ARBITRATION (AS DEFINED ABOVE) OR ARBITRATE OR LITIGATE ANY DISPUTE IN A REPRESENTATIVE CAPACITY, INCLUDING AS A REPRESENTATIVE MEMBER OF A CLASS OR IN A PRIVATE ATTORNEY GENERAL CAPACITY. IN CONNECTION WITH ANY DISPUTE (AS DEFINED ABOVE), ALL SUCH RIGHTS ARE EXPRESSLY AND UNCONDITIONALLY WAIVED. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS AND CONDITIONS, IN THE EVENT ALL OR ANY PORTION OF CLAUSES 21.9 OR 22.12 ARE FOUND TO BE INVALID OR LESS THAN FULLY ENFORCEABLE, THEN THE ENTIRETY OF THIS CLAUSE 21 MAY BE DEEMED VOID AND AS HAVING NO EFFECT UPON EITHER YOUR OR OUR ELECTION.</Typography>
                    <Typography>22. OTHER</Typography>
                    <Typography>Entire Agreement</Typography>
                    <Typography>22.1 These Terms and Conditions constitute the entire agreement between you and us with respect to your Participation and, save in the case of fraud, supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and us with respect to your Participation.Amendments</Typography>
                    <Typography>22.2 OGS Group hereby reserves the right to amend these Terms and Conditions, or to implement or amend any procedures, at any time. Any amendments will be published on the Platform and such changes will be binding and effective immediately.</Typography>
                    <Typography>22.3 Whenever we amend these Terms and Conditions in a way that would limit your current rights or which may be to your detriment, we will notify you upon your next visit to the Platform and you will be required to re-confirm your acceptance prior to playing any Contests or Games. If you do not agree to the amended Terms and Conditions, you must stop using the Platform.</Typography>
                    <Typography>Tax</Typography>
                    <Typography>22.4 You are solely responsible for any taxes which apply to any Prizes that you collect from your Participation.</Typography>
                    <Typography>Force Majeure</Typography>
                    <Typography>22.5 OGS Group will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under these Terms and Conditions that is caused by events outside of our reasonable control.</Typography>
                    <Typography>No agency</Typography>
                    <Typography>22.6 Nothing in these Terms and Conditions will be construed as creating any agency, partnership, trust arrangement, fiduciary relationship or any other form of joint enterprise between you and us.</Typography>
                    <Typography>Severability</Typography>
                    <Typography>22.7 If any of the Terms and Conditions are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will, to that extent, be severed from these Terms and Conditions. All remaining terms, conditions and provisions will continue to be valid to the fullest extent permitted by law. In such cases, the part deemed invalid or unenforceable will be amended in a manner consistent with the applicable law to reflect, as closely as possible, OGS Group’s original intent.</Typography>
                    <Typography>Explanation of Terms and Conditions</Typography>
                    <Typography>22.8 We consider these Terms and Conditions to be open and fair. If you need any explanation regarding these Terms and Conditions or any other part of our Platform contact Customer Support via this <a className={classes.link} href='https://parrotsocial.app/contact/' target='_blank' rel='noreferrer'>form</a>.</Typography>
                    <Typography>22.9 The Terms and Conditions prevail over any communication via email or chat.</Typography>
                    <Typography>22.10 All correspondence between you and us may be recorded.</Typography>
                    <Typography>Assignment</Typography>
                    <Typography>22.11 These Terms and Conditions are personal to you, and are not assignable, transferable or sub-licensable by you except with our prior written consent. We reserve the right to assign, transfer or delegate any of our rights and obligations hereunder to any third party without notice to you.</Typography>
                    <Typography>Business Transfers</Typography>
                    <Typography>22.12 In the event of a change of control, merger, acquisition, or sale of assets of the OGS Group, your Customer Account and associated data may be part of the assets transferred to the purchaser or acquiring party. In such an event, we will provide you with notice via email or via our Platform explaining your options with regard to the transfer of your Customer Account.</Typography>
                    <Typography>Language</Typography>
                    <Typography>22.13 These Terms and Conditions may be published in several languages for information purposes and ease of access by players but will all reflect the same principles. It is only the English version that is the legal basis of the relationship between you and us and in case of any discrepancy between a non-English version and the English version of these Terms and Conditions, the English version will prevail.</Typography>
                    <Typography>Applicable Law and Jurisdiction</Typography>
                    <Typography>22.14 These Terms and Conditions will be governed, and interpreted in accordance with, the Laws of Delaware, without regard for its choice or conflict of law principles. The application of the United Nations Convention on Contracts for the International Sale of Goods is specifically excluded.</Typography>
                    <Typography>22.15 You acknowledge that, unless stated otherwise, the Contests and Games are operated from USA and your Participation takes place within the aforementioned territory. Subject to clause 21, the parties agree that any dispute, controversy or claim arising out of or in connection with these Terms and Conditions, or the breach, termination or invalidity thereof, will be submitted exclusively to the courts in Delaware, and you and we consent to the venue and personal jurisdiction of those courts. Notwithstanding the foregoing, any motion to compel arbitration or to enforce an arbitral award issued hereunder may be brought before any court of competent jurisdiction.</Typography>
                    <Typography>You understand that you are providing information to Open Gaming Solutions Inc. The information you provide will only be used to administer this promotion.  NO PURCHASE NECESSARY to enter Sweepstakes. SWEEPSTAKES ARE VOID WHERE PROHIBITED BY LAW. For detailed rules, see Sweeps R[n]ules .</Typography>
                    <Typography variant={'caption'}>Copyright © 2020- 2022 Parrot Social. All rights reserved. Parrot Social is owned and operated by Open Gaming Solutions Inc.. All payments are processed by Open Gaming Solutions Inc. or Open Gaming Sports Network Inc.. The sweepstakes promotions and prizes offered at Parrot Social are operated by Open Gaming Solutions Inc.. The registered address of Open Gaming Solutions is PO Box 28047 Lansdowne PO Kamloops, BC V2C 0C9.</Typography>
                </Card>
            </Container>
        </OverridableSafeAreaDiv>
    );
}
