import {
    Box,
    Button,
    Card,
    CircularProgress,
    Divider,
    Grid,
    makeStyles,
    Modal,
    TextField,
    Typography
} from '@material-ui/core';
import { useAuthentication, useLogin } from '@ogsnetwork/opp-component-lib';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { config, ThemeWithOrgPalette } from '../apps/config';
import polyBeach from '../images/polyBeach.png';
import { CenterScreenView } from '../utils/views/CenterScreenView';
import { Error } from '../utils/views/error';
import { OverridableSafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';
import { CryptoSingleSignOn } from './cryptoSingleSignOn';
import { RegisterCard } from './register';

interface AuthenticationModalProps {
    showModal: boolean;
    onClose: () => void;
    noRedirect: true;
}

interface LoginCardProps {
    onClose?: () => void;
    noRedirect?: true;
    onClickRegisterOpen?: () => void;
}

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    button: {
        marginTop: 10,
        marginBottom: 10
    },
    card: {
        width: 350,
        padding: 20,
        borderRadius: 10
    },
    link: {
        textAlign: 'center',
        color: theme.palette.secondary.main,
        marginTop: 20
    },
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400
    },
    cryptoContainer: {
        marginTop: 20,
        marginBottom: 10
    }
}));

export function LoginScreen(): JSX.Element {
    const classes = useStyles();
    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <CenterScreenView fullHeight>
                <LoginCard />
            </CenterScreenView>
        </OverridableSafeAreaDiv>
    );
}

export function AuthenticationModal(props: AuthenticationModalProps) {
    const classes = useStyles();
    const [authState, setAuthState] = useState<'login' | 'register'>('login');
    return (
        <Modal
            disableScrollLock={true}
            open={props.showModal}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box className={classes.modalBox}>
                {
                    authState === 'login' && <LoginCard onClose={props.onClose} noRedirect={props.noRedirect} onClickRegisterOpen={ () => { setAuthState('register'); } } />
                }
                {
                    authState === 'register' && <RegisterCard onClose={props.onClose} noRedirect={props.noRedirect} onClickLoginOpen={ () => { setAuthState('login'); } } />
                }
            </Box>
        </Modal>
    );
}

function LoginCard(props: LoginCardProps) {
    const classes = useStyles();
    const history = useHistory();
    const onLogin = () => {
        if (typeof props.onClose !== 'undefined') {
            props.onClose();
        }
        if (config.dynamicUserflow.loginDestination && !props.noRedirect) {
            history.push(config.dynamicUserflow.loginDestination);
        }
    };
    const login = useLogin({ onLogin });
    const { status } = useAuthentication();
    const handleKeypress = (e: React.KeyboardEvent) => {
        if (e.charCode === 13) {
            login.dispatchLogin();
        }
    };
    return (
        <Card className={classes.card}>
            <Typography variant={'h3'}>
                Login
            </Typography>
            <Divider />
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant='h6'>Username</Typography>
                    <TextField
                        id="username"
                        type="username"
                        fullWidth
                        value={login.username}
                        onChange={(event) => login.setUsername(event.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Typography variant='h6'>Password</Typography>
                    <TextField
                        onKeyPress={handleKeypress}
                        id="password"
                        type="password"
                        fullWidth
                        value={login.password}
                        onChange={(event) => login.setPassword(event.target.value)}
                    />
                    <Error error={login.error} errorKey="general" />
                </Grid>
                <Grid item>
                    {
                        status !== 'submitting' ?
                            <Button
                                fullWidth
                                color="primary"
                                onKeyPress={handleKeypress}
                                disabled={ login.disableLogin }
                                onClick={() => login.dispatchLogin()}
                                variant="contained">Login</Button> :
                            <Button
                                fullWidth
                                color="primary"
                                variant={'contained'}
                                disabled><CircularProgress size={22} /></Button>
                    }
                    {
                        typeof props.onClickRegisterOpen !== 'undefined' ?
                            <Button onClick={props.onClickRegisterOpen} className={classes.button} fullWidth color="secondary" variant="text">Sign Up</Button>:
                            <Link to="/register">
                                <Button className={classes.button} fullWidth color="secondary" variant="text">Sign Up</Button>
                            </Link>
                    }
                    <Divider />
                    <Typography align={'center'}>- or -</Typography>
                    <Box className={classes.cryptoContainer}>
                        <CryptoSingleSignOn cryptoConnect={false} />
                    </Box>
                </Grid>
                <Link className={classes.link} to={'/forgotPassword'}>Forgot Password?</Link>
            </Grid>
        </Card>
    );
}
