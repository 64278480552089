import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import metamask from '../images/metamask-logo-large.png';
import { web3 } from '../providers';
import './style.css';

interface RPCError extends Error { code: number; }
interface OnboardingButtonProps {
    large?: boolean;
}

interface UseMetaMaskOnboard {
    accounts: string[];
    onboard: (onRequestAccounts?: ((accounts: string[]) => void) | undefined) => void;
}

export function useMetaMaskOnboard(): UseMetaMaskOnboard {
    const [accounts, setAccounts] = React.useState<string[]>([]);
    const onboarding = React.useRef<MetaMaskOnboarding>();
    const provider = web3?.givenProvider;

    React.useEffect(() => {
        if (!onboarding.current) {
            onboarding.current = new MetaMaskOnboarding();
        }
    }, []);

    React.useEffect(() => {
        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
            if (accounts && accounts.length > 0) {
                onboarding.current?.stopOnboarding();
            }
        }
    }, [accounts]);

    function onboard(onRequestAccounts?: (accounts: string[]) => void) {
        if (MetaMaskOnboarding.isMetaMaskInstalled() && provider && web3) {
            web3.eth.requestAccounts()
                .then((accounts) => {
                    setAccounts(accounts);
                    onRequestAccounts?.(accounts);
                })
                .catch((error: RPCError) => {
                    if(error.code === -32002) {
                        alert('Permission request already pending. Complete this action via MetaMask.');
                    }
                });
        } else {
            onboarding.current?.startOnboarding();
        }
    }

    return {
        accounts,
        onboard
    };
}

export function OnboardingButton(props: OnboardingButtonProps): JSX.Element {
    const metaMaskOnboard = useMetaMaskOnboard();
    return (
        <React.Fragment>
            <img
                className={props.large ? 'icon metamask-icon-large' : 'icon metamask-icon'}
                src={ metamask }
                onClick={() => metaMaskOnboard.onboard()}
            />
        </React.Fragment>
    );
}
