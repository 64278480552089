import { Box, makeStyles, Typography } from '@material-ui/core';
import { useTicketFromEvent } from '@ogsnetwork/opp-component-lib';
import { Link } from 'react-router-dom';
import React from 'react';
import { IEvent} from '@ogsnetwork/opp-api-lib';

interface NotificationBarProps {
    event: IEvent;
}

const useStyles = makeStyles(() => ({
    container: {
        width: '84%',
        marginLeft: '8%',
        marginRight: '8%'
    },
    highlightedMessageBox: {
        position: 'fixed',
        backgroundColor: 'orange',
        width: '100%',
        paddingBottom: 1,
        paddingTop: 5,
        marginBottom: 20,
        zIndex: 1
    },
    link: {
        color: 'purple',
        fontWeight: 'bold',
        '&:visited': {
            color: 'purple'
        }
    }
}));

export function NotificationBar(props: NotificationBarProps): JSX.Element {
    const classes = useStyles();
    const { event } = props;
    const { ticket } = useTicketFromEvent({ event });

    return (
        <Box className={classes.highlightedMessageBox}>
            <Typography align={'center'} gutterBottom>Next Event: 
                <Link className={classes.link} to={`/props/event/${event.id}`}>{
                    event.description
                } </Link>
            </Typography>
        </Box>
    );
}
