import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, IconButton, LinearProgress, makeStyles, Modal, Typography, Paper } from '@material-ui/core';
import { ThemeWithOrgPalette } from '../apps/config';
import { web3 } from '../providers';
import MetaMaskOnboarding from '@metamask/onboarding';
import { getParrotPassContract } from '../contracts/ParrotPass';
import { getParrotPFPContract } from '../contracts/ParrotPFP';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { cycle } from './cycle';
import unknownPert from '../images/unknown-pert.png';
import parrotPassGlowing from '../images/parrot-pass-glowing.png';

interface ContractInfoModalProps {
    variant?: 'outlined' | 'contained' | 'text';
    buttonClassName?: string;
    fullWidth?: true;
}

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    button: {
        height: 35,
        width: 35,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            '&:hover': {
                backgroundColor: theme.palette.primary.dark
            }
        },
        [theme.breakpoints.down('sm')]: {
            '&:hover': {
                backgroundColor: theme.palette.primary.main
            }
        }
    },
    buttonContainer: {
        width: 75,
        margin: '25px auto 5px auto'
    },
    image: {
        display: 'block',
        margin: '0px auto 10px auto',
        height: 200
    },
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        background: theme.orgPalette?.cardGradient,
        borderRadius: 10,
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        padding: 20
    },
    paper: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        marginTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 2
    },
    table: {
        width: '100%',
        maxHeight: 300
    },
    text: {
        color: theme.orgPalette?.darkBackgroundText
    }
}));

export function ContractInfoModal(props: ContractInfoModalProps): JSX.Element {
    const classes = useStyles();
    const { variant, buttonClassName } = props;
    const [contractInfoModalVisible, setContractInfoModalVisible] = React.useState(false);
    const handleOpenContractInfoModal = () => setContractInfoModalVisible(true);
    const handleCloseContractInfoModal = () => setContractInfoModalVisible(false);
    const [totalPassSupply, setTotalPassSupply] = useState<number>(0);
    const [totalFreePassesMinted, setTotalFreePassesMinted] = useState<number>(0);
    const [totalPaidPassesMinted, setTotalPaidPassesMinted] = useState<number>(0);
    const [totalParrotSupply, setTotalParrotSupply] = useState<number>(0);
    const [freeParrotsMinted, setFreeParrotsMinted] = useState<number>(0);
    const [paidParrotsMinted, setPaidParrotsMinted] = useState<number>(0);
    const [MAX_PARROTS, setMAX_PARROTS] = useState<number>(0);
    const [MAX_FREE_PARROTS, setMAX_FREE_PARROTS] = useState<number>(0);
    const [MAX_PAID_PARROTS, setMAX_PAID_PARROTS] = useState<number>(0);
    const [MAX_PARROT_PASSES, setMAX_PARROT_PASSES] = useState<number>(0);
    const [MAX_FREE_PASSES_PER_ROUND, setMAX_FREE_PASSES_PER_ROUND] = useState<number>(0);
    const [MAX_PAID_MINT, setMAX_PAID_MINT] = useState<number>(0);
    const [reservedParrots, setReservedParrots] = useState<number>(0);
    const [reservedPasses, setReservedPasses] = useState<number>(0);
    const [viewIndex, setViewIndex] = useState<number>(0);

    async function checkPassAmounts(): Promise<void> {
        if (web3 && MetaMaskOnboarding.isMetaMaskInstalled()) {
            const pfpContract = getParrotPFPContract();
            const passContract = getParrotPassContract();
            if (!pfpContract || !passContract) {
                return;
            }

            const accounts = await web3.eth.requestAccounts();
            const account = accounts[0];

            const accountMint = await pfpContract?.methods.balanceOf(account).call();
            const MAX_PARROTS = await pfpContract?.methods.MAX_PARROTS().call();
            const MAX_FREE_PARROTS = await pfpContract?.methods.MAX_FREE_PARROTS().call();
            const MAX_PAID_PARROTS = await pfpContract?.methods.MAX_PAID_PARROTS().call();

            const totalParrotSupply = await pfpContract?.methods.totalSupply().call();
            const freeParrotsMinted = await pfpContract?.methods.freeParrotsMinted().call();
            const paidParrotsMinted = await pfpContract?.methods.paidParrotsMinted().call();

            const MAX_PARROT_PASSES = await passContract?.methods.MAX_PARROT_PASSES().call();
            const MAX_FREE_PASSES_PER_ROUND = await passContract?.methods.MAX_FREE_PASSES_PER_ROUND().call();
            const MAX_PAID_MINT = await passContract?.methods.MAX_PAID_MINT().call();

            const totalPassSupply = await passContract?.methods.totalSupply().call();
            const freePassesMinted = await passContract?.methods.freePassesMinted().call();
            const paidPassesMinted = await passContract?.methods.paidPassesMinted().call();

            setMAX_PARROTS(MAX_PARROTS);
            setMAX_FREE_PARROTS(MAX_FREE_PARROTS);
            setMAX_PAID_PARROTS(MAX_PAID_PARROTS);

            setTotalParrotSupply(totalParrotSupply);
            setFreeParrotsMinted(freeParrotsMinted);
            setPaidParrotsMinted(paidParrotsMinted);
            setReservedParrots(totalParrotSupply - (parseInt(paidParrotsMinted, 10) + parseInt(freeParrotsMinted, 10)));

            setMAX_PARROT_PASSES(MAX_PARROT_PASSES);
            setMAX_FREE_PASSES_PER_ROUND(MAX_FREE_PASSES_PER_ROUND);
            setMAX_PAID_MINT(MAX_PAID_MINT);

            setTotalPassSupply(totalPassSupply);
            setTotalFreePassesMinted(freePassesMinted);
            setTotalPaidPassesMinted(paidPassesMinted);
            setReservedPasses(totalPassSupply - (parseInt(paidPassesMinted, 10) + parseInt(freePassesMinted, 10)));
        }
    }

    function cycleView(left?: true) {
        setViewIndex(cycle(viewIndex, 1, left));
    }

    useEffect(() => {
        const refreshInterval = setInterval(() => {
                checkPassAmounts();
            }, 5000);
            checkPassAmounts();
        return () => {
            if (refreshInterval) {
                clearInterval(refreshInterval);
            }
        };
    }, []);

    return (
        <>
            <Button
                fullWidth={props.fullWidth}
                color={'primary'}
                onClick={handleOpenContractInfoModal}
                variant={typeof variant !== 'undefined' ? variant : 'text'}
                className={buttonClassName}>
                Info
            </Button>
            <Modal
                disableScrollLock={true}
                open={contractInfoModalVisible}
                onClose={handleCloseContractInfoModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box className={classes.modalBox}>
                    {
                        viewIndex === 0 &&
                            <>
                                <img src={parrotPassGlowing} className={classes.image} />
                                <Typography align='center' id="modal-modal-title" variant="h5" className={classes.text}>
                                    Parrot Pass Stats
                                </Typography>
                                <Grid container component={Paper} className={classes.paper}>
                                    <Grid item xs={12}>
                                        <Typography variant={'h6'} className={classes.text}>Total Parrot Passes Minted</Typography>
                                        <LinearProgress variant="determinate" value={(totalPassSupply / MAX_PARROT_PASSES) * 100} />
                                        <Typography align={'right'} className={classes.text}>{totalPassSupply} / {MAX_PARROT_PASSES}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                    }
                    {
                        viewIndex === 1 &&
                            <>
                                <img src={unknownPert} className={classes.image} />
                                <Typography align='center' id="modal-modal-title" variant="h5" className={classes.text}>
                                    Parrot PFP Stats
                                </Typography>
                                <Grid container component={Paper} className={classes.paper}>
                                    <Grid item xs={12}>
                                        <Typography variant={'h6'} className={classes.text}>Total Parrots Minted</Typography>
                                        <LinearProgress variant="determinate" value={(totalParrotSupply / MAX_PARROTS) * 100} />
                                        <Typography align={'right'} className={classes.text}>{totalParrotSupply} / {MAX_PARROTS}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                    }
                    <Grid container className={classes.buttonContainer}>
                        <Grid item xs={6}>
                            <IconButton
                                className={classes.button}
                                onClick={() => { cycleView(true); }}
                                size={'small'}
                            >
                                <ArrowBack />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} container justify={'flex-end'}>
                            <IconButton
                                className={classes.button}
                                onClick={() => { cycleView(); }}
                                size={'small'}
                            >
                                <ArrowForward />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}
