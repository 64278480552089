import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';
import MetaMaskOnboarding from '@metamask/onboarding';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { config, ThemeWithOrgPalette } from '../../apps/config';
import { getParrotPassContract } from '../../contracts/ParrotPass';
import { getParrotPFPContract } from '../../contracts/ParrotPFP';
import parrotSocial from '../../images/parrotSocialHero.png';
import unknownPert from '../../images/unknown-pert.png';
import { META_MASK_ERROR_CODE } from '../../metaMask/constants';
import { OnboardingButton } from '../../metaMask/onboarding';
import { web3 } from '../../providers';
import { BrandedLink } from '../../utils/components/BrandedLink';
import {
    ContainerWithImage,
    CWIContentContainer,
    CWIContentHeaderImage
} from '../../utils/components/containerWithImage';
import { tabsA11yProps } from '../../utils/components/tabPanel';

const PARROT_PFP_FEE = 0.02;
const PRE_MINT = false;
const TAB_MINT = 0;
const TAB_FREE_MINT = 1;


interface MintParrotPFPProps {
    freePFPVariant?: true;
}

interface RPCError extends Error { code: number; }

type MintOption = 'free' | 'freeWithPass' | 'paid';

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    actionContainerFree: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        borderRadius: 10,
        marginTop: 25,
        padding: 15
    },
    actionContainerPaid: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        borderRadius: 10,
        marginTop: 25,
        padding: '15px 15px 0px 15px'
    },
    button: {
        textTransform: 'none',
        marginTop: 30
    },
    card: {
        marginTop: 25,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20
    },
    cardContainer: {
        width: 450,
        padding: 20
    },
    centerContent: {
        display: 'block',
        margin: '0 auto',
        textAlign: 'center'
    },
    centeredButton:{
        display: 'block',
        margin: '30px auto'
    },
    container: {
        height: 'calc(100vh - 100px)'
    },
    divider: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        height: 1,
        marginBottom: 5,
        marginTop: 7,
        width: '100%'
    },
    highlightText: {
        color: theme.palette.secondary.main
    },
    highlightTextAlt: {
        color: theme.palette.primary.main
    },
    loadingContainer: {
        height: '100%'
    },
    paper: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        borderRadius: 10,
        backgroundColor: theme.orgPalette?.darkBackground,
        marginBottom: 10
    },
    select: {
        color: 'white',
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
    },
    text: {
        color: 'white'
    },
    tab: {
        color: theme.orgPalette?.darkBackgroundText
    }
}));

export function MintParrotPFP(props?: MintParrotPFPProps) {
    const history = useHistory();
    const classes = useStyles();
    const [numberToMint, setNumberToMint] = useState<number>(1);
    const [totalMint, setTotalMint] = useState<number>(0);
    const [totalFreeMint, setTotalFreeMint] = useState<number>(0);
    const [totalPassMint, setTotalPassMint] = useState<number>(0);
    const [totalPaidMint, setTotalPaidMint] = useState<number>(0);
    const [parrotPasses, setParrotPasses] = useState<number[]>([]);
    const [eligibleFreeMintParrotPasses, setEligibleFreeMintParrotPasses] = useState<number[]>([]);
    const [passesToFreeMint, setPassesToFreeMint] = useState<number[]>([]);
    const [mintPaused, setMintPaused] = useState<boolean>(true);
    const [mintStateLoading, setMintStateLoading] = useState<boolean>(true);
    const [metaMaskInstalled, setMetaMaskInstalled] = useState<boolean>(false);
    const [isPFPMinting, setIsPFPMinting] = useState<boolean>(false);
    const [network, setNetwork] = useState<string>('');
    const [maxParrots, setMaxParrots] = useState<number>(0);
    const [maxFreeParrots, setMaxFreeParrots] = useState<number>(0);
    const [maxPassParrots, setMaxPassParrots] = useState<number>(0);
    const [maxPaidParrots, setMaxPaidParrots] = useState<number>(0);
    const [tab, setTab] = useState(TAB_MINT);

    async function checkPassAmounts(): Promise<void> {
        if (web3 && MetaMaskOnboarding.isMetaMaskInstalled()) {
            const contract = getParrotPFPContract();
            const passContract = getParrotPassContract();
            if (!contract || !passContract) {
                return;
            }
            const mintPaused = await contract?.methods.mintPaused().call();
            const maxParrots = await contract?.methods.MAX_PARROTS().call();
            const maxFreeParrots = await contract?.methods.MAX_FREE_PARROTS().call();
            const maxPassParrots = await contract?.methods.MAX_PASS_PARROTS().call();
            const maxPaidParrots = await contract?.methods.MAX_PAID_PARROTS().call();
            const totalSupply = await contract?.methods.totalSupply().call();
            const freeParrotsMinted = await contract?.methods.freeParrotsMinted().call();
            const passParrotsMinted = await contract?.methods.passParrotsMinted().call();
            const paidParrotsMinted = await contract?.methods.paidParrotsMinted().call();

            const accounts = await web3.eth.requestAccounts();
            const account = accounts[0];

            if (tab === TAB_FREE_MINT) {
                const parrotPassIds: string[] = await passContract?.methods.tokensOfOwner(account).call();
                setParrotPasses(parrotPassIds.map(parseInt));

                const passesThatCanFreeMint: number[] = [];
                const claimedPassesIterable = {
                    [Symbol.asyncIterator]() {
                        let index = 0;
                        return {
                            async next() {
                                const done = index === parrotPassIds.length;
                                const passId = parrotPassIds[index];
                                const value = done ?
                                    undefined :
                                    {
                                        isClaimed: await contract?.methods.isClaimed(passId).call(),
                                        passId: parseInt(passId, 10)
                                    };
                                index++;
                                return { done, value };
                            },
                            return() {
                                // This will be reached if the consumer called 'break' or 'return' early in the loop.
                                return { done: true, value: undefined };
                            }
                        };
                    }
                };
                for await (const result of claimedPassesIterable) {
                    if (result && !result.isClaimed) {
                        passesThatCanFreeMint.push(result.passId);
                    }
                }
                setEligibleFreeMintParrotPasses(passesThatCanFreeMint);
            }

            setMaxParrots(parseInt(maxParrots, 10));
            setMaxFreeParrots(parseInt(maxFreeParrots, 10));
            setMaxPassParrots(parseInt(maxPassParrots, 10));
            setMaxPaidParrots(parseInt(maxPaidParrots, 10));
            setTotalMint(parseInt(totalSupply, 10));
            setTotalFreeMint(parseInt(freeParrotsMinted, 10));
            setTotalPassMint(parseInt(passParrotsMinted, 10));
            setTotalPaidMint(parseInt(paidParrotsMinted, 10));
            setMintPaused(mintPaused);
            setMintStateLoading(false);
        }
    }

    async function setupSubscriptions() {
        const provider = web3?.givenProvider;
        provider.on('networkChanged', () => {
            detectEthereumNetwork();
        });
    }

    useEffect(() => {
        let refreshInterval: NodeJS.Timer;
        if (config.metaMask.mintingEnabled) {
            setMetaMaskInstalled(MetaMaskOnboarding.isMetaMaskInstalled());
            refreshInterval = setInterval(() => {
                if (metaMaskInstalled) {
                    setMetaMaskInstalled(MetaMaskOnboarding.isMetaMaskInstalled());
                }
                checkPassAmounts();
            }, 5000);
            checkPassAmounts();
            detectEthereumNetwork();
            setupSubscriptions();
        }
        return () => {
            if (refreshInterval) {
                clearInterval(refreshInterval);
            }
        };
    }, []);

    useEffect(() => {
        checkPassAmounts();
    }, [tab]);

    async function detectEthereumNetwork() {
        web3?.eth.net.getNetworkType().then(async (netId) => {
            setNetwork(netId);
        });
    }

    async function switchNetwork() {
        const provider = web3?.givenProvider;
        provider.request({ method: 'wallet_switchEthereumChain', params:[{ chainId: config.metaMask.preferredNetworkAddress }]});
    }

    async function initiateMint({ amount, mintOption = 'paid' }: { amount: number, mintOption?: MintOption }): Promise<void> {
        setIsPFPMinting(true);
        if (web3 && MetaMaskOnboarding.isMetaMaskInstalled()) {
            const contract = getParrotPFPContract();
            if (!contract) {
                return;
            }

            try {
                const accounts = await web3.eth.requestAccounts();
                const account = accounts[0];
                let transaction;
                if (mintOption === 'freeWithPass') {
                    if (amount > 1) {
                        transaction = await contract.methods.passClaimMultiple(passesToFreeMint).send({from: account});
                    } else {
                        transaction = await contract.methods.passClaim(passesToFreeMint[0]).send({from: account});
                    }
                } else if (mintOption === 'free') {
                    transaction = await contract.methods.freemint(amount).send({value: 0, from: account});
                } else {
                    const passPrice = await contract.methods.price().call();
                    transaction = await contract.methods.mint(amount).send({value: passPrice * amount, from: account});
                }
                if (transaction) {
                    setIsPFPMinting(false);
                    setPassesToFreeMint([]);
                    if (config.dynamicUserflow.parrotPFPPurchaseDestination) {
                        history.push(config.dynamicUserflow.parrotPFPPurchaseDestination);
                    }
                }
            } catch (error) {
                setIsPFPMinting(false);
                const { code } = error as RPCError;
                if (code === META_MASK_ERROR_CODE.PENDING_REQUEST) {
                    alert('Permission request already pending. Complete this action via MetaMask.');
                } else if (code !== META_MASK_ERROR_CODE.REJECTED_BY_USER) {
                    alert('There was an issue with minting. Check MetaMask and try again.');
                }
            }
        }
    }

    const handleFreePassMintChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const {
            target: { value },
        } = event;
        if (totalPassMint < maxPassParrots - 1 && eligibleFreeMintParrotPasses.length > 1) {
            setPassesToFreeMint(value as number[]);
        } else {
            setPassesToFreeMint([value as number]);
        }
    };

    const handleNumberToMintChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const {
            target: { value },
        } = event;
        setNumberToMint(value as number);
    };

    const numberToMintOptions: number[] = [];
    let maxNumberToMintOption = 0;
    if (totalFreeMint < maxFreeParrots) {
        maxNumberToMintOption = totalFreeMint >= maxFreeParrots - 1 ? 1 : 2;
    } else if (totalPaidMint < maxPaidParrots) {
        maxNumberToMintOption = Math.min(20, maxPaidParrots - totalPaidMint);
    }
    for (let numberToMintOption = 1; numberToMintOption <= maxNumberToMintOption; numberToMintOption++) {
        numberToMintOptions.push(numberToMintOption);
    }

    return (
        <ContainerWithImage image={parrotSocial}>
            <CWIContentHeaderImage image={unknownPert} />
            <Paper className={classes.paper}>
                <Tabs
                    indicatorColor="secondary"
                    textColor="secondary"
                    value={tab}
                    onChange={(event, newTab) => setTab(newTab)}
                    aria-label="tabs"
                    variant={'fullWidth'}>
                    <Tab className={classes.tab} label="Mint" {...tabsA11yProps(0)} />
                    <Tab className={classes.tab} label="Mint with Pass" {...tabsA11yProps(1)} />
                </Tabs>
            </Paper>
            <CWIContentContainer>
                { !metaMaskInstalled &&
                    <Box className={classes.centerContent}>
                        <Typography variant="h5">To mint, install Metamask</Typography>
                        <OnboardingButton large={true} />
                    </Box>
                }
                { typeof network !== 'undefined' && network !== '' && network !== config.metaMask.preferredNetwork &&
                    <Box>
                        <Typography align={'center'}>
                            You must connect to the&nbsp;
                            <span className={classes.highlightTextAlt}>{ config.metaMask.preferredNetwork }</span>&nbsp;
                            network to mint
                        </Typography>
                        <Button
                            className={classes.centeredButton}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => switchNetwork()}
                        >
                            Switch Network
                        </Button>
                    </Box>
                }
                { metaMaskInstalled && (network === '' || mintStateLoading) &&
                    <Grid container justifyContent={'center'} alignContent={'center'} className={classes.loadingContainer}>
                        <CircularProgress />
                    </Grid>
                }
                { metaMaskInstalled && typeof network !== 'undefined' && network === config.metaMask.preferredNetwork && !mintStateLoading && mintPaused &&
                    <>
                        <Typography>
                            Mint your Parrot to join the club. Parrots give you access to the Parrot Social Club
                            and entry to the Games Room.
                        </Typography>
                        <Box className={classes.divider} />
                        <Box className={classes.actionContainerFree}>
                            <Typography variant="h5" gutterBottom>Parrot PFP minting is paused!</Typography>
                            <Typography>Check for a Parrot PFP on OpenSea!</Typography>
                        </Box>
                    </>
                }
                { PRE_MINT &&
                    <Box className={classes.centerContent}>
                        <Typography variant="h5">Parrot PFP sales are in Pre-Mint</Typography>
                        <Typography>If you&apos;re on the whitelist you&apos;ll be able to mint parrots here, if not, use a parrot pass to mint a parrot at the Free Parrot Mint section.</Typography>
                    </Box>
                }
                { !PRE_MINT && tab === TAB_MINT && metaMaskInstalled && typeof network !== 'undefined' && network === config.metaMask.preferredNetwork && !mintStateLoading && !mintPaused &&
                    <>
                        <Typography>
                            Mint your Parrot to join the club. Parrots give you access to the Parrot Social Club
                            and entry to the Games Room.
                        </Typography>
                        { totalFreeMint >= maxFreeParrots && totalPaidMint < maxPaidParrots &&
                            <Typography>
                                All free Parrots have been claimed. You can now mint up to 20.
                            </Typography>
                        }
                        { totalPaidMint >= maxPaidParrots &&
                            <Typography>
                                All parrots have been minted, see you in the club!
                            </Typography>
                        }
                        <Typography>Parrots Remaining: {maxParrots - totalMint}</Typography>
                        <Box className={classes.divider} />
                        <Grid container className={classes.actionContainerPaid} spacing={2}>
                            <Grid xs={12} item container>
                                <FormControl fullWidth variant='filled'>
                                    <InputLabel id="select-multiple-parrots" className={classes.text}>Parrots to Mint</InputLabel>
                                    <Select
                                        className={classes.select}
                                        disabled={totalPaidMint >= maxPaidParrots}
                                        labelId="select-multiple-parrots"
                                        value={numberToMint}
                                        onChange={handleNumberToMintChange}
                                        label='Parrots to Mint'
                                    >
                                        {numberToMintOptions.map((mintNumber) => (
                                            <MenuItem
                                                value={Number(mintNumber)}
                                                key={mintNumber}
                                            >
                                                {mintNumber}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} item container alignItems={'flex-end'} justifyContent={'flex-end'}>
                                { totalPaidMint < maxPaidParrots &&
                                    <Typography className={classes.highlightTextAlt} variant={'h4'} gutterBottom>
                                        {totalFreeMint < maxFreeParrots ? 0 : PARROT_PFP_FEE * numberToMint} ETH
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'flex-end'}>
                            {
                                !isPFPMinting ?
                                    <Button
                                        className={classes.button}
                                        color={'secondary'}
                                        variant={'contained'}
                                        disabled={numberToMint === 0 || totalPaidMint >= maxPaidParrots}
                                        onClick={() => initiateMint({
                                            amount: numberToMint,
                                            mintOption: totalFreeMint < maxFreeParrots ? 'free' : 'paid'
                                        })}>
                                        Mint Parrot PFP{numberToMint > 1 && 's'}
                                    </Button>:
                                    <Button className={classes.button} color={'secondary'} variant={'contained'}>
                                        <CircularProgress size={20} />
                                    </Button>
                            }
                        </Grid>
                    </>
                }
                { !PRE_MINT && tab === TAB_FREE_MINT && metaMaskInstalled && typeof network !== 'undefined' && network === config.metaMask.preferredNetwork && !mintStateLoading && !mintPaused &&
                    metaMaskInstalled && typeof network !== 'undefined' && network === config.metaMask.preferredNetwork && !mintStateLoading && !mintPaused &&
                    <>
                        <Typography>
                            Every member of the club needs a Parrot! Use your Parrot Pass to mint your Parrot for free.
                        </Typography>
                        { totalPassMint >= maxPassParrots &&
                            <Typography>
                                All Parrots available to Pass holders have been claimed.
                            </Typography>
                        }
                        <Typography>Parrots Remaining: {maxParrots - totalMint}</Typography>
                        { totalPassMint < maxPassParrots &&
                            <>
                                <Box className={classes.divider} />
                                <Grid container className={classes.actionContainerFree} spacing={2}>
                                    {eligibleFreeMintParrotPasses.length > 0 ?
                                        <>
                                            <FormControl fullWidth variant='filled'>
                                                <InputLabel id="select-multiple-parrots">Parrot Passes available to free mint</InputLabel>
                                                <Select
                                                    className={classes.select}
                                                    disabled={totalPassMint >= maxPassParrots}
                                                    labelId="select-multiple-parrots"
                                                    multiple={
                                                        eligibleFreeMintParrotPasses.length > 1 && totalPassMint < maxPassParrots - 1
                                                    }
                                                    value={passesToFreeMint.length === 0 ?
                                                        eligibleFreeMintParrotPasses.length > 1 &&
                                                        totalPassMint < maxPassParrots - 1 ? [] : '' :
                                                        passesToFreeMint
                                                    }
                                                    onChange={handleFreePassMintChange}
                                                    label='Parrot Passes available to free mint'
                                                >
                                                    {eligibleFreeMintParrotPasses.map((passId) => (
                                                        <MenuItem
                                                            value={Number(passId)}
                                                            key={passId}
                                                        >
                                                            {`Parrot Pass #${passId}`}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </> :
                                        <Box className={classes.centerContent}>
                                            { parrotPasses.length === 0 ?
                                                <Typography>
                                                    Your connected wallet has no Parrot Passes
                                                </Typography> :
                                                <Typography>
                                                    Your Parrot Passes have already claimed a free Parrot.
                                                </Typography>
                                            }
                                            {totalFreeMint < maxFreeParrots  ?
                                                <Typography>
                                                    You can still <BrandedLink
                                                    text="Mint"
                                                    to="/mint"
                                                    onClick={() => setTab(TAB_MINT)} /> your Parrot for free!
                                                </Typography> :
                                                <Typography>
                                                    You can still <BrandedLink
                                                    text="Mint"
                                                    to="/mint"
                                                    onClick={() => setTab(TAB_MINT)} /> your Parrot!
                                                </Typography>
                                            }
                                        </Box>
                                    }
                                </Grid>
                                {eligibleFreeMintParrotPasses.length > 0 ?
                                    <Grid container justifyContent={'flex-end'}>
                                        {
                                            !isPFPMinting ?
                                                <Button
                                                    className={classes.button}
                                                    color={'secondary'}
                                                    variant={'contained'}
                                                    disabled={passesToFreeMint.length === 0 || totalPassMint >= maxPassParrots}
                                                    onClick={() => {
                                                        initiateMint({
                                                            amount: passesToFreeMint.length,
                                                            mintOption: 'freeWithPass'
                                                        });
                                                    }}>
                                                    Mint Parrot PFP{passesToFreeMint.length > 1 && 's'}
                                                </Button>:
                                                <Button
                                                    className={classes.button}
                                                    color={'secondary'}
                                                    variant={'contained'}>
                                                    <CircularProgress size={20} />
                                                </Button>
                                        }
                                    </Grid> :
                                    <></>
                                }
                            </>
                        }
                    </>
                }
            </CWIContentContainer>
        </ContainerWithImage>
    );
}
