import { Box, Container, Divider, Grid, makeStyles, Paper, SvgIcon, Tab, TableContainer, Table, TableRow, TableBody, TableCell, TableHead, Tabs, Typography, Tooltip, Button, CircularProgress } from '@material-ui/core';
import { useAppSelector, useLivePropBet } from '@ogsnetwork/opp-component-lib';
import polyBeach from '../images/polyBeach.png';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CenterScreenView } from '../utils/views/CenterScreenView';
import { OverridableSafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';
import { Link } from 'react-router-dom';
import defaultPropGameImage from './media/defaultPropGameImage.png';
import { config, ThemeWithOrgPalette } from '../apps/config';
import moment from 'moment';
import { PrizeModal } from '../utils/prizeModal';
import { WinningsModal } from '../utils/winningsModal';
import Masonry from 'react-masonry-css';
import { LivePropBetSelectionHeadToHead, LivePropBetSelectionFreeForm, LivePropBetSelectionDropDown } from './liveSelection';
import { PointsEarnedPropBet } from './pointsEarned';
import { useCustomer } from '@ogsnetwork/opp-component-lib';
import classNames from 'classnames';
import ordinal from 'ordinal';
import { BrandedIcon } from '../utils/components/icon';
import { FormatTokensForDisplay } from '../utils/tokenFormatter';
import { TabPanel, tabsA11yProps } from '../utils/components/tabPanel';

export interface LivePropBetRouteParams {
    eventId: string;
    ticketId: string;
}

const useLivePropBetStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    button: {
        width: 150,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20
        },
        fontWeight: 'bold'
    },
    card: {
        margin: '2em',
        padding: '1em'
    },
    externalPrize: {
        color: theme.palette.secondary.main
    },
    notLiveContainer: {
        height: 'calc(100vh - 120px)'
    },
    italix: {
        color: '#676767',
        fontStyle: 'italic'
    },
    clickable: {
        cursor: 'pointer'
    },
    containerSpace: {
        marginBottom: 15,
        marginTop: 15
    },
    centerTextXS: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        }
    },
    gridItemCenterXS: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            textAlign: 'center'
        }
    },
    gridItemRightSM: {
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'rght',
            textAlign: 'right'
        }
    },
    icon: {
        position: 'relative',
        bottom: 1,
        fontSize: 14,
        height: '1em',
        width: '1em'
    },
    loadingContainer: {
        borderRadius: 5,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.orgPalette?.cardOnCard,
        padding: 25,
        marginTop: 50
    },
    masonryBox: {
        padding: 20
    },
    masonryGrid: {
        display: 'flex',
        marginLeft: -30,
        width: 'auto'
    },
    masonryGridColumn: {
        paddingLeft: 30,
        backgroundClip: 'paddingBox'
    },
    noEventContainer: {
        height: 'calc(100vh - 120px)'
    },
    propBetContainerHeader: {
        background: theme.orgPalette?.cardGradientOpacity,
        padding: 20,
        marginTop: 20,
        borderTop: `1px solid ${theme.palette.primary.main}`
    },
    table: {
        width: '100%',
        '& p': {
            marginBottom: 0
        }
    },
    viewTicketMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 35
    }
}));

const breakPoints = {
    default: 3,
    1100: 2,
    700: 1
};

export function LivePropBet(): JSX.Element {
    const classes = useLivePropBetStyles();
    const routeParams = useParams<LivePropBetRouteParams>();
    const eventId = parseInt(routeParams.eventId, 10);
    const [ticketId, setTicketId] = useState<number>(parseInt(routeParams.ticketId, 10));
    const [usersOwnTicketVisible, setUsersOwnTicketVisible] = useState<boolean>(false);
    const event = useAppSelector((state) => state.events.byId[eventId]);
    const ticket = useAppSelector((state) => state.tickets.byId[ticketId]);

    // don't load useLivePropBet until `ticket` and `event` exist
    if (!ticket || !event) {
        return (
            <OverridableSafeAreaDiv imageSrc={polyBeach}>
                <CenterScreenView className={classes.notLiveContainer}>
                    <Typography variant={'h4'}>This ticket is not live</Typography>
                    <Divider />
                    <Typography variant={'h6'}>You can view your tickets in <Link to={'/mycontests/upcoming'}>My Contests</Link></Typography>
                    <Typography variant={'h6'}>- or -</Typography>
                    <Typography variant={'h6'}>Purchase more tickets in the <Link to={'/props/lobby'}>Game Lobby</Link></Typography>
                </CenterScreenView>
            </OverridableSafeAreaDiv>
        );
    }
    const viewTicketUsername = ticket.user?.username;
    const [tab, setTab] = useState<number>(0);
    const { customer } = useCustomer();
    const username = customer?.user.username;
    const livePropBet = useLivePropBet({ event, ticket });
    useEffect(() => {
        livePropBet.refresh();
        setTab(0);
        setUsersOwnTicketVisible(false);
    }, [ticketId]);
    const showUsersOwnTicket = (ticketId: number) => {
        setUsersOwnTicketVisible(true);
        setTicketId(ticketId);
    };
    return (
        <OverridableSafeAreaDiv imageSrc={event.infoGraphicUrl ?? defaultPropGameImage}>
            <Container>
                <Paper className={classes.containerSpace}>
                    <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        value={tab}
                        onChange={(event, newTab) => setTab(newTab)}
                        aria-label="tabs"
                        variant={'fullWidth'}>
                        <Tab label="Ticket" {...tabsA11yProps(0)} />
                        <Tab label="Standings" {...tabsA11yProps(1)} />
                    </Tabs>
                </Paper>
                <Box className={classes.propBetContainerHeader}>
                    <TabPanel value={tab} index={0}>
                        {
                            customer?.user?.username !== viewTicketUsername && typeof viewTicketUsername !== 'undefined' &&
                                <Box className={classes.viewTicketMessage}>
                                    <Typography variant={'h4'} align={'center'}>Viewing { viewTicketUsername }&apos;s ticket</Typography>
                                    <Button onClick={() => showUsersOwnTicket(parseInt(routeParams.ticketId, 10))} color={'secondary'} variant={'contained'}>View Your Ticket</Button>
                                </Box>
                        }
                        <Grid container>
                            <Grid xs={12} sm={6} container item>
                                <Grid xs={12} direction={'column'} container item>
                                    <Typography className={classes.centerTextXS} variant="h4" gutterBottom>{event.description}</Typography>
                                    <Typography className={classes.centerTextXS} variant="h5">
                                        { moment(event.closeTs).format('LLL') }
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={6} container item>
                                <Grid xs={12} direction={'column'} container item className={classes.gridItemRightSM}>
                                    <Typography className={classes.centerTextXS} variant="h5"><span className={classes.italix}>points: </span> 
                                    {FormatTokensForDisplay(event.ticketCost)} <BrandedIcon className={classes.icon} icon={config.icons.pointsIcon} /></Typography>
                                    <Typography className={classes.centerTextXS} variant="h5"><span className={classes.italix}>entrants:</span> {event.ticketCount}/{event.ticketMax}</Typography>
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={6} item className={classes.gridItemCenterXS}>
                                <PrizeModal buttonClassName={classes.button} event={event} variant={'outlined'} />
                            </Grid>
                            <Grid xs={12} sm={6} item className={classNames(classes.gridItemCenterXS, classes.gridItemRightSM)}>
                                <WinningsModal buttonClassName={classes.button} event={event} ticket={ticket} variant={'contained'} />
                            </Grid>
                        </Grid>
                        {
                            livePropBet.bets.length > 0 && Object.keys(livePropBet.selectionsByBetId).length > 0 && !usersOwnTicketVisible ?
                                <Masonry
                                        breakpointCols={breakPoints}
                                        className={classes.masonryGrid}
                                        columnClassName={classes.masonryGridColumn}
                                    >
                                    {
                                        livePropBet.bets.map((bet) => (
                                            <Box key={bet.id}>
                                                {bet.widgetChoice === 'radio_image' &&
                                                    <LivePropBetSelectionHeadToHead bet={bet} event={event} editable={false} propBetComponent={livePropBet} ticket={ticket} />
                                                }
                                                {['free_form_int', 'free_form_float'].includes(bet.widgetChoice) &&
                                                    <LivePropBetSelectionFreeForm bet={bet} event={event} editable={false} propBetComponent={livePropBet} ticket={ticket} />
                                                }
                                                {bet.widgetChoice === 'name_dropdown' &&
                                                    <LivePropBetSelectionDropDown bet={bet} event={event} editable={false} propBetComponent={livePropBet} ticket={ticket} />
                                                }
                                                {
                                                    typeof bet.finalValue !== 'undefined' && <PointsEarnedPropBet bet={bet} editable={false} propBetComponent={livePropBet} ticket={ticket} />
                                                }
                                            </Box>
                                        ))
                                    }
                                </Masonry> :
                                <Box className={classes.loadingContainer}>
                                    <CircularProgress />
                                </Box>
                        }
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Typography variant='h4'>Standings</Typography>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>Rk</TableCell>
                                    <TableCell align="left">User</TableCell>
                                    <TableCell align="center">Score</TableCell>
                                    <TableCell align="right">Prize</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    livePropBet.orderedTicketsForEvent.map((ticket) => (
                                        ticket.user && <TableRow
                                            hover
                                            selected={ ticket.user?.username === username }
                                            className={classes.clickable} onClick={() => setTicketId(ticket.id)}
                                            key={ticket.id}>
                                            <TableCell component="th" scope="row">
                                                {ticket.positionTied ? `T-${ordinal(ticket.position ?? 0)}` : ordinal(ticket.position ?? 0)}
                                            </TableCell>
                                            <TableCell align="left">{ticket.user?.username}</TableCell>
                                            <TableCell align="center">{ticket.pointsEarned}</TableCell>
                                            <TableCell align="right">
                                            {
                                                ticket.prizes?.map((prize, index: number): React.ReactNode => (
                                                    <Box key={index}>
                                                        {
                                                            prize.type === 'external' ?
                                                                <Tooltip
                                                                    title={'Tiebreaker rules apply'}
                                                                    aria-label={'Tiebreaker rules apply'}>
                                                                    <Typography className={classes.externalPrize}>
                                                                        { prize.description }
                                                                    </Typography>
                                                                </Tooltip>:
                                                                <Typography>
                                                                    { FormatTokensForDisplay(prize.amount) } <BrandedIcon icon={config.icons.pointsIcon} />
                                                                </Typography>
                                                        }
                                                    </Box>
                                                ))
                                            }</TableCell>
                                        </TableRow>
                                    ))
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                </Box>
            </Container>
        </OverridableSafeAreaDiv>
    );
}
