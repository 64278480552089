import React from 'react';
import {
    Box, Card, Container, makeStyles, Typography
} from '@material-ui/core';
import { ThemeWithOrgPalette } from '../../config';
import { OverridableSafeAreaDiv } from '../../../utils/views/overridableSafeAreaDiv';
import polyBeach from '../../../images/polyBeach.png';

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    card: {
        padding: 20
    },
    containerHeader: {
        background: theme.orgPalette?.cardGradientOpacity,
        padding: 20,
        marginTop: 20,
        borderBottom: `5px solid ${theme.palette.primary.main}`,
        borderTop: `1px solid ${theme.palette.primary.main}`
    },
    link: {
        color: theme.palette.secondary.main
    }
}));

export function ResponsibleGamingScreen(): JSX.Element {
    const classes = useStyles();
    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <Container>
                <Box className={classes.containerHeader}>
                    <Typography variant={'h4'} gutterBottom>Responsible Gaming</Typography>
                    <Typography>Version - 1.0</Typography>
                    <Typography>Date of Last Update: 09 March 2022</Typography>
                    <Typography>Parrot Social is committed to responsible gaming and is dedicated to an enjoyable and positive gaming experience for all of our players. Most people play online for fun and entertainment, but we recognize that a small percentage of people will attempt to play when they’re underage or allow playing online to control them and affect their lives.</Typography>
                </Box>
                <Card className={classes.card}>
                    <Typography variant={'h6'}>Preventing Underage Gaming</Typography>
                    <Typography>It is illegal for anyone under 18 (19 in Alabama and Canada, 21 in Massachusetts) to participate in sweepstakes contests on Parrot Social.</Typography>
                    <Typography>Upon prize redemption, we may perform a verification to validate the age and identity of all customers. We reserve the right to ask for additional age verification information from the player and suspend the player’s account until they provide information that verifies appropriate age to our satisfaction.</Typography>
                    <Typography>If you know an underaged person who is registered at Parrot Social, please <a className={classes.link} href='mailto:support@parrotsocial.app?subject=Parrot Social'>support@parrotsocial.app</a>. If a person with an account is found to be underage, that user’s account will be terminated. Other legal ramifications may also apply.</Typography>
                    <Typography variant={'h6'}>Protecting Minors</Typography>
                    <Typography>Responsible gaming means making sure parents understand the importance of supervising their children’s online activities. The internet is readily accessible in many homes around the world. As a result, gaming companies and parents must work together to protect children from underage gambling. To ensure the safety of your children, we recommend installing filtering software to block minors from accessing certain websites and programs.</Typography>
                    <Typography>Tips for parents:</Typography>
                    <ul>
                        <li>Do not leave children unattended near your computer when you are logged into your Parrot Social account.</li>
                        <li>Do not allow underaged children to participate in any contests involving prizes.</li>
                        <li>Keep your Parrot Social username and password details private and out of the reach of children.</li>
                    </ul>
                    <Typography variant={'h6'}>Setting Limits</Typography>
                    <Typography>We want players to have a great time on Parrot Social and participate responsibly. By contacting customer support, a player can set the following limits on their account:</Typography>
                    <ul>
                        <li>Temporary Self-Exclusion – Short-term playing time limits (less than 6 months)</li>
                        <li>Extended Self-Exclusion – Extended playing time limits (1-3 years)</li>
                    </ul>
                    <Typography variant={'h6'}>Temporary Self-Exclusion</Typography>
                    <Typography>If a player wishes to take a temporary break from Parrot Social, they may request a Self-Exclusion period. This period can be 1 day, 1 week, 1 month, or 6 months. This request can be made by contacting <a className={classes.link} href='mailto:support@parrotsocial.app?subject=Parrot Social'>support@parrotsocial.app</a>.</Typography>
                    <Typography>During the “Temporary Self-Exclusion” period, a player’s Parrot Social account will be suspended and Parrot Social will take all reasonable measures to make sure the player does not receive any promotional offers. This suspension is irrevocable during the “Temporary Self-Exclusion” period. The player’s account will automatically re-open at the end of the selected period. Players are able to redeem parrot tokens during this period.</Typography>
                    <Typography variant={'h6'}>Extended Self-Exclusion</Typography>
                    <Typography>If a player becomes concerned about their gaming habits, they may make a request online to be self-excluded from the Parrot Social platform for a period of 1 year or 3 years. A player may request Long Term Self-Exclusion by contacting <a className={classes.link} href='mailto:support@parrotsocial.app?subject=Parrot Social'>support@parrotsocial.app</a>.Once a player long-term self-excludes, we will block their account. Any new accounts they attempt to open during the period of self-exclusion will also be blocked as soon as they are detected. In addition, we will take all reasonable measures to make sure the player does not receive any promotional material during this time.</Typography>
                    <Typography>Finally, we reserve the right to exclude a player at our discretion if we determine there is a reasonable risk that the player is not gaming responsibly and they refuses to self-restrict or self-exclude.</Typography>
                    <Typography variant={'h6'}>Do I have a gaming Problem?</Typography>
                    <Typography>The following highlights several questions a player should ask themselves and their loved ones to help identify potential signs of an online gaming problem. While these questions are not a diagnostic, psychological, or medical test and should not be used as such, many people who have called help lines have answered yes to one or more of the following questions:</Typography>
                    <ul>
                        <li>Do you lose time from work due to contest participation?</li>
                        <li>Is online gaming making your home life unhappy?</li>
                        <li>Is online gaming affecting your reputation?</li>
                        <li>Have you ever felt remorse after playing?</li>
                        <li>Do you ever play contests to get money to help pay debts or solve other financial difficulties?</li>
                        <li>Does online gaming decrease your ambition or efficiency?</li>
                        <li>After losing, do you feel you must return as soon as possible to win back your losses?</li>
                        <li>After a win, do you have a strong urge to continue and win more?</li>
                        <li>Do you ever borrow to finance your online gaming?</li>
                        <li>Have you ever sold any real estate or personal property to finance online gaming?</li>
                        <li>Are you reluctant to use “online gaming money” for normal expenditures?</li>
                        <li>Does gaming ever make you careless about the welfare of your family?</li>
                        <li>Do you ever play to escape worries or trouble?</li>
                        <li>Have you ever committed or considered committing an illegal act to finance gambling?</li>
                        <li>Does playing make you have difficulty sleeping?</li>
                        <li>Do arguments, disappointments, or frustrations create an urge to play?</li>
                        <li>Have you ever considered self-destructive behavior as a result of your online gaming?</li>
                    </ul>
                    <Typography>If you think you or someone you care about may have a gambling problem please call 1-800-522-4700.</Typography>
                    <Typography>You may also seek professional help from these organizations:</Typography>
                    <ul>
                        <li>National Center for Responsible Gambling</li>
                        <li>National Council on Problem Gambling</li>
                    </ul>
                    <Typography variant={'caption'}>Copyright © 2020- 2022 Parrot Social. All rights reserved. Parrot Social is owned and operated by Open Gaming Solutions Inc.. All payments are processed by Open Gaming Solutions Inc. or Open Gaming Sports Network Inc.. The sweepstakes promotions and prizes offered at Parrot Social are operated by Open Gaming Solutions Inc.. The registered address of Open Gaming Solutions is PO Box 28047 Lansdowne PO Kamloops, BC V2C 0C9.</Typography>
                </Card>
            </Container>
        </OverridableSafeAreaDiv>
    );
}
