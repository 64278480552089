import makeStyles from '@material-ui/core/styles/makeStyles';
import classnames from 'classnames';
import React from 'react';

interface CenterScreenViewProps extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
    fullHeight?: true;
}

const useStyles = makeStyles(() => ({
    container: {
        marginTop: 15,
        minHeight: 'calc(100vh - 100px)'
    },
    centerScreenView: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

export function CenterScreenView(props: CenterScreenViewProps): JSX.Element {
    const classes = useStyles();
    return <div className={ classnames(classes.centerScreenView, props.className, props.fullHeight && classes.container) }>
        { props.children }
    </div>;
}