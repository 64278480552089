import Montserrat from './montserrat/Montserrat-Regular.ttf';
import Kabel from './kabel/KabelBd-Normal.ttf';

export const montserratReg = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `url(${Montserrat}) format('truetype')`,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const kabel = {
    fontFamily: 'Kabel',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `url(${Kabel}) format('truetype')`,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
