import React, { useEffect, useState } from 'react';
import {
    Box, Button, makeStyles, MenuItem, Select, FormControl, InputLabel, TextField, Typography, Paper, Tabs, Tab
} from '@material-ui/core';
import { useClaimParrotTokens, useCustomer } from '@ogsnetwork/opp-component-lib';
import { config, ThemeWithOrgPalette } from '../apps/config';
import { CenterScreenView } from '../utils/views/CenterScreenView';
import { web3 } from '../providers';
import { OverridableSafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';
import polyBeach from '../images/polyBeach.png';
import { BrandedLink } from '../utils/components/BrandedLink';
import claimGift from '../images/claimGift.png';
import giftBox from '../images/gift.png';
import { ContainerWithImage, CWIContentHeaderImage, CWIContentTitle, CWIContentContainer } from '../utils/components/containerWithImage';
import { TabPanel, tabsA11yProps } from '../utils/components/tabPanel';

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    boldText: {
        fontWeight: 'bold'
    },
    button: {
        marginBottom: 10,
        marginTop: 10
    },
    card: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        borderRadius: '0px 0px 10px 10px',
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 10,
        paddingTop: 10
    },
    cardMetaInfo: {
        borderRadius: 5,
        backgroundColor: theme.orgPalette?.cardOnCard,
        padding: 10,
        marginBottom: 15,
        marginTop: 10
    },
    divider: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        height: 1,
        marginBottom: 10,
        width: '100%'
    },
    error: {
        color: theme.palette.error.main,
        width: '100%'
    },
    highlightText: {
        color: theme.palette.secondary.main
    },
    paper: {
        borderRadius: '10px 10px 0px 0px',
        backgroundColor: theme.orgPalette?.darkBackground
    },
    passInfoContainer: {
        width: '100%'
    }
}));

export function ClaimTokensScreen() {
    const classes = useStyles();
    const { customer } = useCustomer();
    const claimParrotTokens = useClaimParrotTokens();
    const [checkPassId, setCheckPassId] = useState('');
    const [passesToClaim, setPassesToClaim] = useState<number[]>([]);
    const [tab, setTab] = useState<number>(0);

    useEffect(() => {
        async function fetchUserPasses() {
            if (web3) {
                const accounts = await web3.eth.requestAccounts();
                const account = accounts[0];
                if (account && account === customer?.ethAddress) {
                    claimParrotTokens.dispatchGetPassTokenClaimStatus();
                }
            }
        }
        fetchUserPasses();
    }, [customer]);

    function checkPass() {
        if (checkPassId) {
            claimParrotTokens.dispatchGetPassTokenClaimStatus(Number(checkPassId));
        }
    }

    function claimTokens() {
        claimParrotTokens.dispatchClaimParrotTokens(passesToClaim);
    }

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const {
            target: { value },
        } = event;
        setPassesToClaim(value as number[]);
    };

    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <CenterScreenView fullHeight>
                <ContainerWithImage image={claimGift}>
                    <CWIContentHeaderImage image={giftBox} />
                    <CWIContentTitle title={'Claim Tokens'} />
                    <CWIContentContainer>
                    <Typography>Every Parrot Pass in your wallet is eligible for a one time gift of <span className={classes.boldText}>1000 psc tokens</span>.</Typography>
                    <Typography>Choose which Parrot Passes you wish to claim for and get your tokens now!</Typography>
                        <Box className={classes.passInfoContainer}>
                            <Paper className={classes.paper}>
                                <Tabs
                                    indicatorColor="primary"
                                    textColor="primary"
                                    value={tab}
                                    onChange={(event, newTab) => setTab(newTab)}
                                    aria-label="tabs"
                                    variant={'fullWidth'}>
                                    <Tab label="Claim" {...tabsA11yProps(0)} />
                                    <Tab label="Check" {...tabsA11yProps(1)} />
                                </Tabs>
                            </Paper>
                            <TabPanel value={tab} index={0}>
                                { customer?.ethAddress ?
                                    Object.keys(claimParrotTokens.passes).length > 0 ?
                                        <Box className={classes.card}>
                                            <FormControl fullWidth variant='outlined'>
                                                <InputLabel id="demo-mutiple-name-label">Your Parrot Passes</InputLabel>
                                                <Select
                                                    id="demo-mutiple-name-label"
                                                    multiple={Object.keys(claimParrotTokens.passes).length > 1}
                                                    value={passesToClaim}
                                                    onChange={handleChange}
                                                    label='Your Parrot Passes'
                                                >
                                                    {Object.keys(claimParrotTokens.passes).map((passId) => (
                                                        <MenuItem
                                                            value={Number(passId)}
                                                            key={passId}
                                                            >
                                                            {`Parrot Pass #${passId}`}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <Button className={classes.button} color="primary" onClick={() => claimTokens()} variant="contained" fullWidth>Claim PSC Tokens</Button>
                                        </Box> :
                                        <Box className={classes.card}>
                                            <Typography className={classes.error} align={'center'}>
                                                Your attached wallet has no Parrot Passes
                                            </Typography>
                                            <Typography align={'center'}>Mint a Parrot Pass <BrandedLink to='/mint' text={'Here'}/>!</Typography>
                                        </Box> :
                                        <Box className={classes.card}>
                                            <Box className={classes.divider} />
                                            <Typography>
                                                Before you can claim { config.tokenShorthand } tokens, first go to your <BrandedLink to='/mywallet' text={'Wallet'}/> and connect an Ethereum Address to your account.
                                            </Typography>
                                        </Box>
                                }
                                { claimParrotTokens.successes.length > 0 &&
                                    <Typography align={'center'}>
                                        Successfully claimed PSC tokens for the following Parrot Passes: { claimParrotTokens.successes.join(', ') }
                                    </Typography>
                                }
                                { claimParrotTokens.failures.length > 0 &&
                                    <Typography align={'center'}>
                                        The following Parrot Passes have already claimed PSC tokens: { claimParrotTokens.failures.join(', ') }
                                    </Typography>
                                }
                            </TabPanel>
                            <TabPanel value={tab} index={1}>
                                <Box className={classes.card}>
                                    <Typography>
                                        Check if Parrot Pass has Claimed Tokens
                                    </Typography>
                                    <TextField
                                        id="passId"
                                        type="passId"
                                        label='Parrot Pass Id'
                                        fullWidth
                                        value={checkPassId}
                                        variant='outlined'
                                        onChange={(event) => setCheckPassId(event.target.value)}
                                        />
                                    <Button className={classes.button} color="primary" onClick={() => checkPass()} variant="contained" fullWidth>Check</Button>
                                    { claimParrotTokens.passStatus &&
                                        <Typography color={'secondary'} gutterBottom align={'center'}>
                                            Parrot Pass #{claimParrotTokens.passStatus.passId} { claimParrotTokens.passStatus.claimedTokens ? 'has already' : 'has not' } claimed the free PSC Tokens
                                        </Typography>
                                    }
                                </Box>
                            </TabPanel>
                        </Box>
                    </CWIContentContainer>
                </ContainerWithImage>
            </CenterScreenView>
        </OverridableSafeAreaDiv>
    );

}
