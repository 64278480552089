import { Box, Card, Container, Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { ThemeWithOrgPalette } from '../../apps/config';

interface ContainerWithImageProps {
    image?: string;
}

interface CWIHeaderImageProps {
    image?: string;
}

interface CWITitleProps {
    title?: string;
}

const useContainerWithImageStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    card: {
        alignItems: 'center',
        display: 'flex',
        background: theme.orgPalette?.cardGradient,
        borderRadius: 25,
        height: 700,
        margin: '0 auto',
        maxWidth: '80%',
        [theme.breakpoints.down('xs')]: {
            margin: '0 auto',
            maxWidth: '100%'
        }
    },
    childContentContainer: {
        alignItems: 'flex-start',
        padding: '0 5%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 auto'
    },
    image: {
        position: 'relative',
        borderRadius: '25px 0px 0px 25px',
        height: 700,
        width: '100%',
        objectFit: 'cover',
        top: '3px'
    }
}));

const useCWIHeaderImageStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    image: {
        display: 'block',
        margin: '10px auto 10px auto',
        height: 200
    }
}));

const useCWITitleStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    text: {
        color: theme.orgPalette?.darkBackgroundText
    },
    highlightText: {
        color: theme.palette.secondary.main
    },
    divider: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        height: 1,
        marginBottom: 10,
        width: '100%'
    }
}));

const useCWIContentContainerStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    container: {
        color: theme.orgPalette?.darkBackgroundText,
        minHeight: 330,
        width: '100%'
    }
}));

export function ContainerWithImage(props: PropsWithChildren<ContainerWithImageProps>): JSX.Element {
    const classes = useContainerWithImageStyles();
    const { image } = props;

    return (
        <Container>
            <Card className={classes.card}>
                <Grid container>
                    <Hidden smDown>
                        <Grid item md={6}>
                            <img src={image} className={classes.image}/>
                        </Grid>
                    </Hidden>
                    <Grid item md={6} className={classes.childContentContainer} alignItems={'center'}>
                        {
                            props.children
                        }
                    </Grid>
                </Grid>
            </Card>
        </Container>
    );
}

export function CWIContentHeaderImage(props: CWIHeaderImageProps): JSX.Element {
    const classes = useCWIHeaderImageStyles();
    const { image } = props;

    return (
        <img src={image} className={classes.image} />
    );
}

export function CWIContentTitle(props: CWITitleProps): JSX.Element {
    const classes = useCWITitleStyles();
    return (
        <>
            <Typography variant={'h4'} className={classes.text}>{props.title}<span className={classes.highlightText}>.</span></Typography>
            <Box className={classes.divider} />
        </>
    );
}

export function CWIContentContainer(props: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>): JSX.Element {
    const classes = useCWIContentContainerStyles();
    return (
        <Box className={classes.container}>
            { props.children }
        </Box>
    );
}
