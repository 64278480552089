import { Chip, ChipProps, makeStyles, SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

interface BrandedIconProps {
    icon: React.FunctionComponent;
}

interface BrandedChipIconProps {
    chipProps?: ChipProps;
    svgIconProps?: SvgIconProps;
    svgIcon: React.FunctionComponent;
}

const useStyles = makeStyles(() => ({
    icon: {
        position: 'relative',
        top: 2,
        fontSize: 14,
        height: '1em',
        width: '1em'
    },
    chipIcon: {
        fontSize: 14,
        height: '1em',
        width: '1em'
    }
}));

export function BrandedIcon(props: BrandedIconProps & SvgIconProps): JSX.Element {
    const classes = useStyles();
    const { icon, ...remainingProps } = props;

    return (
        <SvgIcon
            viewBox='0 0 14 14'
            className={classes.icon}
            component={icon}
            htmlColor={'white'}
            {...remainingProps} />
    );
}

export function BrandedChipIcon(props: BrandedChipIconProps): JSX.Element {
    const classes = useStyles();
    const { chipProps, svgIconProps, svgIcon } = props;

    return (
        <Chip
            icon={
                <SvgIcon
                    viewBox='0 0 14 14'
                    className={classes.chipIcon}
                    component={svgIcon}
                    htmlColor={'white'}
                    { ...svgIconProps } />
            }
            { ...chipProps }/>
    );
}
