import moment from 'moment';
import { Typography, TypographyProps } from '@material-ui/core';
import React from 'react';

interface TimestampProps extends TypographyProps {
    timestamp: moment.MomentInput;
}

export function Timestamp(props: TimestampProps) {
    const { timestamp, ...typographyProps } = props;
    return (
        <Typography {...typographyProps}>
            { moment.utc(props.timestamp).local().format('LLL') }
        </Typography>
    );
}
