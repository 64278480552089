import { createStyles } from '@material-ui/core';
import { ThemeWithOrgPalette } from '../../apps/config';

export const styles = (theme: ThemeWithOrgPalette) => createStyles({
    imageBg: {
        filter: 'blur(20px)',
        position: 'absolute',
        height: 400,
        width: '100%',
        maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))',
        opacity: 0.3,
        zIndex: -1
    },
    safeAreaDivOffset: {
        paddingTop: 60,
        paddingBottom: 20,
        [theme.breakpoints.up('md')]: {
            paddingRight: 60
        }
    },
    safeAreaDiv: {
        width: '100vw',
        minHeight: '100vh'
    }
});
