import { Box, Button, Divider, makeStyles, Typography } from '@material-ui/core';
import {
    useCryptoConnect,
    useCryptoLogin,
    useCryptoRegister,
    useCustomer,
    useCustomerCryptoNonce,
    usePrevious
} from '@ogsnetwork/opp-component-lib';
import React, { useEffect, useState } from 'react';
import { config } from '../apps/config';
import metamask from '../images/metamask-logo-large.png';
import { useMetaMaskOnboard } from '../metaMask/onboarding';
import { web3 } from '../providers';
import { Error } from '../utils/views/error';

interface SendAsyncSignError {
    code: number;
    message: string;
    stack: string;
}

interface SendAsyncSignResult {
    id?: number;
    jsonrpc: string;
    result: string;
}

interface CryptoSingleSignOnProps {
    cryptoConnect: boolean;
    asIcon?: boolean;
}

const useStyles = makeStyles((theme) => ({
    button: {
        marginBottom: 15,
        marginTop: 15
    },
    card: {
        marginTop: 25,
        padding: 20
    },
    connectContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: '100%',
        marginTop: 20
    },
    container: {
        width: '60%',
        marginLeft: '20%',
        marginRight: '20%',
        marginTop: 15
    },
    divider: {
        marginTop: 20
    },
    error: {
        color: theme.palette.error.main
    },
    metaMaskIcon: {
        position: 'relative',
        margin: 10,
        top: 2,
        width: 24
    }
}));

export function CryptoSingleSignOn(props: CryptoSingleSignOnProps): JSX.Element {
    const classes = useStyles();
    const cryptoConnect = useCryptoConnect();
    const cryptoLogin = useCryptoLogin();
    const cryptoRegister = useCryptoRegister();
    const customerCryptoNonce = useCustomerCryptoNonce();
    const metaMaskOnboard = useMetaMaskOnboard();
    const previousNonceStatus = usePrevious(customerCryptoNonce.status);

    const [disconnect, setDisconnect] = useState(false);

    const { customer } = useCustomer();

    const domain = [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' }
    ];
    const nonceType = [
        { name: 'nonce', type: 'string' }
    ];

    /**
     * The nonce will only be acquired after the eth wallet address has been first obtained; after the nonce request
     * has completed successfully a login or register request can be initiated. The nonce will only be available if
     * the wallet address has already been registered.
     *
     * If there is a nonce then proceed with a login request
     * Else (nonce is nullish), proceed with a register request
     */
    useEffect(() => {
        if (customerCryptoNonce.status === 'success' && previousNonceStatus === 'fetching') {
            if (web3) {
                const account = metaMaskOnboard.accounts[0];
                if (!account) {
                    return;
                }
                const messageToSign = customerCryptoNonce.nonce ?? 'Register for Parrot Social account with Metamask';
                const message = {
                    nonce: messageToSign
                };
                const chainId = parseInt(config.metaMask.preferredNetworkAddress ?? '0x0', 16);
                const domainData = {
                    chainId,
                    name: config.metaMask.ethSignatureDomainName,
                    version: config.metaMask.ethSignatureDomainVersion,
                };
                const data = JSON.stringify({
                    types: {
                        EIP712Domain: domain,
                        Nonce: nonceType
                    },
                    chainId,
                    domain: domainData,
                    primaryType: 'Nonce',
                    message: message,
                });
                web3.givenProvider.sendAsync({
                    method: 'eth_signTypedData_v4',
                    params: [account, data],
                    },
                    (error: SendAsyncSignError, result: SendAsyncSignResult) => {
                        if (result.result) {
                            if (props.cryptoConnect) {
                                cryptoConnect.dispatchCryptoConnect(account, result.result, disconnect);
                            } else if (customerCryptoNonce.nonce) {
                                cryptoLogin.dispatchCryptoLogin(account, result.result);
                            } else {
                                cryptoRegister.dispatchCryptoRegister(account, result.result);
                            }
                        }
                    }
                );
            }
        }
    }, [customerCryptoNonce.status, metaMaskOnboard.accounts]);

    async function onClick(isConnecting = false) {
        if (!customer?.ethAddress) {
            setDisconnect(isConnecting);
            metaMaskOnboard.onboard((accounts) => {
                customerCryptoNonce.dispatchCustomerCryptoNonce(accounts[0]);
            });
        }
    }

    return (
        <>
        { props.cryptoConnect ?
            <>
            {
                customer?.ethAddress ?
                    <Box className={classes.connectContainer}>
                        <Button fullWidth color="primary" className={classes.button} onClick={() => onClick(true)} variant="contained">
                            Disconnect Wallet
                        </Button>
                        <Typography color="secondary" gutterBottom>Connected Ethereum Wallet:</Typography>
                        <Typography variant='body2'>{ customer.ethAddress }</Typography>
                    </Box> :
                    <Box className={classes.connectContainer}>
                        <Typography variant='subtitle2' className={classes.error} gutterBottom>No Ethereum wallet connected to account.</Typography>
                        <Button fullWidth className={classes.button} color="secondary" onClick={() => onClick(false)} variant="contained">
                            Connect wallet to account
                        </Button>
                        <Error error={cryptoConnect.error} errorKey="address" />
                    </Box>
            }
            </>:
            <>
            {
                props.asIcon ?
                    <img
                        className={classes.metaMaskIcon}
                        src={metamask}
                        onClick={() => onClick()}
                    /> :
                    <Button fullWidth color="secondary" onClick={() => onClick()} variant="contained">
                        Sign In with MetaMask
                    </Button>
            }
            </>
        }
        </>
    );
}
