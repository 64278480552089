import { Box, CircularProgress, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { usePropBetLobby } from '@ogsnetwork/opp-component-lib';
import polyBeach from '../images/polyBeach.png';
import React from 'react';
import { OverridableSafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';
import { CenterScreenView } from '../utils/views/CenterScreenView';
import { EventCard } from './eventCard';
import { NotificationBar } from '../utils/components/notificationBar';

const useStyles = makeStyles(() => ({
    container: {
        marginTop: 40
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: 25,
        marginTop: 50
    },
    loadingViewContainer: {
        height: 'calc(100vh - 100px)',
        marginTop: 40
    }
}));

export function PropBetLobby(): JSX.Element {
    const propBetLobby = usePropBetLobby({ showAppHiddenEvents: true });
    const classes = useStyles();

    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            {
                propBetLobby.state.loading ?
                    <CenterScreenView className={classes.loadingViewContainer}>
                        <Box className={classes.loadingContainer}>
                            <CircularProgress />
                        </Box>
                    </CenterScreenView> :
                    <>
                        {
                            typeof propBetLobby.state.filteredEvents[0] !== 'undefined' &&
                                <NotificationBar event={propBetLobby.state.filteredEvents[0]}/>
                        }
                        <Container className={classes.container}>
                            <Typography variant="h6" gutterBottom>Props</Typography>
                            <Grid container spacing={3}>
                                {
                                    propBetLobby.state.filteredEvents.map((event) => (
                                        <Grid item xs={12} sm={6} md={4} key={event.id}>
                                            <EventCard event={event}/>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Container>
                    </>
            }
        </OverridableSafeAreaDiv>
    );
}
