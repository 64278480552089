import React from 'react';

import {
    Box, Button, Grid, makeStyles, MenuItem, Select, Typography
} from '@material-ui/core';
import { TextField } from '@mui/material';
import { IBetChoices } from '@ogsnetwork/opp-api-lib';
import { usePropBetSelection, UsePropBetSelectionProps } from '@ogsnetwork/opp-component-lib';
import { ThemeWithOrgPalette } from '../apps/config';
import { ScoringDetailsModal } from './scoringRules';

export type PropBetSelectionProps = UsePropBetSelectionProps;

const usePropBetSelectionStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    rootActive: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        borderLeft: `5px solid ${theme.palette.primary.main}`,
        borderRadius: 5,
        padding: '0.5em 1em 1em 1em',
        marginTop: 20
    },
    rootInactive: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        borderRight: `5px solid ${theme.palette.secondary.main}`,
        borderRadius: 5,
        padding: '0.5em 1em 1em 1em',
        marginTop: 20
    },
    divider: {
        margin: '0px auto 20px auto'
    },
    muiNumberInput: {
        '& .MuiFilledInput-underline:before': {
            borderBottomColor: '#5b6376'
        },
        '& .MuiFilledInput-underline:after': {
          borderBottomColor: theme.palette.primary.main
        },
        '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: theme.palette.secondary.main
        },
        '& .MuiInputLabel-root': {
            color: 'white'
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: theme.palette.primary.main
        }
    },
    numberInput: {
        color: 'white!important'
    }
}));

const usePropBetSelectionHeadToHeadStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    betChoice: {
        fontWeight: 'bold'
    },
    selected: {
        backgroundColor: theme.palette.secondary.main,
    },
    defaultDivider: {
        backgroundColor: `${theme.orgPalette?.card}aa`,
        height: 1,
        margin: '0px auto 15px auto'
    }
}));

export function PropBetSelectionHeadToHead(props: PropBetSelectionProps): JSX.Element {
    const baseClasses = usePropBetSelectionStyles();
    const classes = usePropBetSelectionHeadToHeadStyles();
    const propBetSelection = usePropBetSelection(props);
    const { bet, event } = props;
    const leftBetChoice = props.bet.betChoices[0];
    const rightBetChoice = props.bet.betChoices[1];
    function setSelection(betChoice: IBetChoices) {
        if (typeof propBetSelection.selection === undefined) {
            propBetSelection.setSelectionIfEditable({
                betId: props.bet.id,
                outcomeId: betChoice.outcomeId
            });
        }
        if (typeof propBetSelection.selection !== undefined) {
            propBetSelection.setSelectionIfEditable({
                id: propBetSelection.selection?.id,
                betId: props.bet.id,
                outcomeId: betChoice.outcomeId,
                value: propBetSelection.selection?.value,
                losingOutcomeId: propBetSelection.selection?.losingOutcomeId,
                pointsEarned: propBetSelection.selection?.pointsEarned
            });
        }
    }
    return (
        <Box className={propBetSelection.selection?.outcomeId ? baseClasses.rootActive : baseClasses.rootInactive}>
            <Typography variant='h6'>{props.bet.description}</Typography>
            <Box className={baseClasses.divider} />
            <Grid container spacing={3}>
                <Grid xs={12} item className={classes.betChoice}>
                    <Button
                        fullWidth
                        variant={leftBetChoice.outcomeId === propBetSelection.selection?.outcomeId ? 'contained' : 'outlined'}
                        color={leftBetChoice.outcomeId === propBetSelection.selection?.outcomeId ? 'primary' : 'default'}
                        onClick={() => setSelection(leftBetChoice)}>
                        {leftBetChoice.outcomeName}
                    </Button>
                </Grid>
                <Grid xs={12} item className={classes.betChoice}>
                    <Button
                        fullWidth
                        variant={rightBetChoice.outcomeId === propBetSelection.selection?.outcomeId ? 'contained' : 'outlined'}
                        color={rightBetChoice.outcomeId === propBetSelection.selection?.outcomeId ? 'primary' : 'default'}
                        onClick={() => setSelection(rightBetChoice)}>
                        {rightBetChoice.outcomeName}
                    </Button>
                </Grid>
            </Grid>
            <Box className={baseClasses.divider} />
            <ScoringDetailsModal bet={bet} event={event} />
        </Box>
    );
}

export function PropBetSelectionFreeForm(props: PropBetSelectionProps): JSX.Element {
    const baseClasses = usePropBetSelectionStyles();
    const propBetSelection = usePropBetSelection(props);
    const { bet, event } = props;
    function setSelection(value: string | undefined) {
        propBetSelection.setSelectionIfEditable({ betId: props.bet.id, value });
        if (typeof propBetSelection.selection === undefined) {
            propBetSelection.setSelectionIfEditable({
                betId: props.bet.id,
                value
            });
        }
        if (typeof propBetSelection.selection !== undefined) {
            propBetSelection.setSelectionIfEditable({
                id: propBetSelection.selection?.id,
                betId: props.bet.id,
                outcomeId: propBetSelection.selection?.outcomeId,
                value,
                losingOutcomeId: propBetSelection.selection?.losingOutcomeId,
                pointsEarned: propBetSelection.selection?.pointsEarned
            });
        }
    }
    return (
        <Box className={propBetSelection.selection?.value ? baseClasses.rootActive : baseClasses.rootInactive}>
            <Typography variant='h6'>{props.bet.description}</Typography>
            <Box className={baseClasses.divider} />
            <TextField
                className={baseClasses.muiNumberInput}
                variant={'filled'}
                type='text'
                fullWidth
                label={'Prediction'}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', className: baseClasses.numberInput }}
                onChange={(event) => {
                        if (!event.target.validity.patternMismatch) {
                            setSelection(event.target.value);
                        }
                        if (event.target.value === '') {
                            setSelection(undefined);
                        }
                    }
                }
                value={propBetSelection.selection?.value ?? ''}/>
            <Box className={baseClasses.divider} />
            <ScoringDetailsModal bet={bet} event={event} />
        </Box>
    );
}

export function PropBetSelectionDropDown(props: PropBetSelectionProps): JSX.Element {
    const baseClasses = usePropBetSelectionStyles();
    const propBetSelection = usePropBetSelection(props);
    const { bet, event } = props;
    function setSelection(outcomeId: number) {
        propBetSelection.setSelectionIfEditable({ betId: props.bet.id, outcomeId });
        if (typeof propBetSelection.selection === undefined) {
            propBetSelection.setSelectionIfEditable({
                betId: props.bet.id,
                outcomeId
            });
        }
        if (typeof propBetSelection.selection !== undefined) {
            propBetSelection.setSelectionIfEditable({
                id: propBetSelection.selection?.id,
                betId: props.bet.id,
                outcomeId,
                value: propBetSelection.selection?.value,
                losingOutcomeId: propBetSelection.selection?.losingOutcomeId,
                pointsEarned: propBetSelection.selection?.pointsEarned
            });
        }
    }
    return (
        <Box className={propBetSelection.selection?.outcomeId ? baseClasses.rootActive : baseClasses.rootInactive}>
            <Typography variant='h6'>{props.bet.description}</Typography>
            <Box className={baseClasses.divider} />
            <Select
                inputProps={{MenuProps: {disableScrollLock: true}}}
                fullWidth
                value={propBetSelection.selection?.outcomeId ?? ''}
                onChange={(event) => setSelection(event.target.value as number)}>
                {props.bet.betChoices.map((betChoice) => (
                    <MenuItem key={betChoice.outcomeId} value={betChoice.outcomeId}>{betChoice.outcomeName}</MenuItem>
                ))}
            </Select>
            <Box className={baseClasses.divider} />
            <ScoringDetailsModal bet={bet} event={event} />
        </Box>
    );
}
