import React from 'react';
import {
    Box, Card, Container, makeStyles, Typography
} from '@material-ui/core';
import { ThemeWithOrgPalette } from '../../config';
import { OverridableSafeAreaDiv } from '../../../utils/views/overridableSafeAreaDiv';
import polyBeach from '../../../images/polyBeach.png';
import { Link } from 'react-router-dom';
import { BrandedLink } from '../../../utils/components/BrandedLink';

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    card: {
        padding: 20
    },
    containerHeader: {
        background: theme.orgPalette?.cardGradientOpacity,
        padding: 20,
        marginTop: 20,
        borderBottom: `5px solid ${theme.palette.primary.main}`,
        borderTop: `1px solid ${theme.palette.primary.main}`
    },
    link: {
        color: theme.palette.secondary.main
    }
}));

export function PrivacyPolicyScreen(): JSX.Element {
    const classes = useStyles();
    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <Container>
                <Box className={classes.containerHeader}>
                    <Typography variant={'h4'} gutterBottom>Privacy Policy</Typography>
                    <Typography>Version - 1.0</Typography>
                    <Typography>Date of last update - 09 March 2022</Typography>
                </Box>
                <Card className={classes.card}>
                    <Typography variant={'h6'}>Parrot Social’s Commitment to Your Privacy</Typography>
                    <Typography>Parrot Social recognizes that you value your privacy. To better protect your privacy we provide this notice explaining our online information practices and the choices you can make about the way your information is collected and used. This notice is incorporated into and is subject to the Parrot Social <BrandedLink to={'/termsAndConditions'} text={'Terms of Use'} />. Your use of our Web Site and any personal information you provide on the Web Site remains subject to the terms of this notice and the Parrot Social <BrandedLink to={'/termsAndConditions'} text={'Terms of Use'} />.</Typography>
                    <Typography>This notice applies to all information collected while using the Parrot Social app or web site and/or used in creating a Parrot Social account.</Typography>
                    <Typography>Our App or Web Site may contain links to web sites operated by third parties (including co-branded or affiliated services) offered from time to time by Parrot Social or its affiliate companies. Parrot Social is not responsible for the privacy practices or policies of such third-party web sites and this notice does not apply to those web sites.</Typography>
                    <Typography variant={'h6'}>Applicability of this Notice</Typography>
                    <Typography>This notice applies only to the information collected by Parrot Social from the users of our App or Web Site and does not apply to information collected by Parrot Social by other means.</Typography>
                    <Typography variant={'h6'}>The Information Parrot Social Collects</Typography>
                    <Typography>Our App and Web Site tracks certain information about visits to our App and Web Site. For example, we collect data on the number of visitors and frequency of visitors to pages on our App and Web Site. This aggregate data is used internally to improve our App and Web Site, come up with new features, and general marketing purposes. The data collected may also be provided to advertisers and other third parties.</Typography>
                    <Typography>Our Web Site does not automatically collect personally identifiable information as visitors come to our App or Web Site. There are anonymous statistics which are tracked. For example, we can tell which Internet Service Provider our new visitors use, but nothing that would allow us to identify a particular visitor. Once a user has visited our App or Web Site, we may have the ability to match that statistics for that user the next time they visit our site through the use of cookies in order to provide a superior user experience and relevant information and promotions. Any information previously provided by a user, such as an email address upon registration, may be associated with that user but again, only if it were previously provided to Parrot Social by the user.</Typography>
                    <Typography>We may also request information from our users when they perform specific activities on the App or Web Site. For example, we request information from you when you:</Typography>
                    <ul>
                        <li>Register or sign-up to use a service.</li>
                        <li>Login to our App or Web Site, and are prompted to login with your Username and password to do things like access your account and participate in contests.</li>
                        <li>Request your email to be added to our mailing list for marketing emails.</li>
                        <li>Redeem prizes.</li>
                        <li>Participate in online surveys or forums to provide us feedback.</li>
                    </ul>
                    <Typography>In each of the examples above, we may ask for items such as your name, e-mail address, mailing address, phone number, mobile phone number, Ethereum wallet, date of birth, functionality preferences, Parrot Social username and password, and other similar personal information that is needed to register you for certain services or offers or to fulfill your request. As needed, we may also request additional information so that we can provide you with access to and use of specific materials, information and services. You will be able to unsubscribe from newsletters or mailing lists at any time.</Typography>
                    <Typography variant={'h6'}>How Parrot Social Uses Information</Typography>
                    <Typography>Parrot Social only uses your personal information for specific purposes. For example, you must be at least 18 years of age, or older than 18 years of age in those jurisdictions, territories, and locations where the minimum age for permissible use of the App or Web Site by you is greater than 18 years old. We use such information provided by users to verify age.</Typography>
                    <Typography>The personal information you provide to us when using our Web Site, such as your name, mailing address, phone number, or e-mail address will be kept confidential and used by Parrot Social to support your customer relationship with us. This information may also be used to administer the Voluntary Self-Exclusion Program and notify you of special offers, information updates and additional products and/or services from Parrot Social. Agents or contractors of Parrot Social who are given access to your personal information will be required to keep the information confidential and not use it for any other purpose than to carry out the services they are performing for Parrot Social.</Typography>
                    <Typography>From time to time, we may send you information about various products and services we feel may be of interest to you. Only Parrot Social (or agents or contractors working on behalf of Parrot Social under confidentiality agreements) will send you these direct mailings. At any time you can easily opt out of receiving further marketing from Parrot Social by emailing us at <a className={classes.link} href='mailto:support@parrotsocial.app?subject=Parrot Social'>support@parrotsocial.app</a>.</Typography>
                    <Typography variant={'h6'}>Links to Third Party Sites</Typography>
                    <Typography>Our service(s) may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party’s site. Such links do not constitute an endorsement by Parrot Social of those other websites, their content or services, or the persons or entities associated with those websites. This Privacy Policy does not apply to third-party websites. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party sites or services. We encourage you to review the privacy policies and terms of all third-party websites or services that you may visit.</Typography>
                    <Typography variant={'h6'}>Third Party Advertising</Typography>
                    <Typography>Parrot Social may use third-party advertising companies (companies that manage and provide advertising for several unrelated companies). To the extent that Parrot Social utilizes such advertising companies to provide advertisements on our App or Web Site, Parrot Social may provide them with your log-in name and demographic information about you that we collect. Those advertising companies may combine that data with other non-personally identifiable data collected by the advertising company from your computer, tablet or phone solely for the purpose of delivering advertisements on our App or Web Site that are targeted to you. Parrot Social may enhance or merge your information collected on its App or Web Site with data from other third parties for marketing purposes. Some of our business partners may use cookies on our site (for example, advertisers). However, we have no access to or control over these cookies.</Typography>
                    <Typography>Circumstances may arise where we are required to disclose your personal information to third parties for purposes other than to support your customer relationship with Parrot Social. Examples of this may include purposes, such as in connection with a corporate divestiture or dissolution where we sell all or a portion of our business or assets (including our associated customer lists containing your personal information), or if disclosure is required by law or is pertinent to judicial or governmental investigations or proceedings.Co-Branded Partners are third parties with whom we may jointly offer a service or feature. You can tell when you are accessing a service or feature offered by a Co-Branded Partner because the Co-Branded Partner’s name will be featured prominently. You may be asked to provide information about yourself to register for a service offered by a Co-Branded Partner. In doing so, you may be providing your information to both us and the Co-Branded Partner, or we may share your information with the Co-Branded Partner. Please note that the Co-Branded Partner’s privacy policy may also apply to its use of your information.</Typography>
                    <Typography variant={'h6'}>California Privacy Rights</Typography>
                    <Typography>By law a California resident is entitled to request a list of all third parties to whom personal information was disclosed in the preceding calendar year.</Typography>
                    <Typography>To make such a request, please write to us at the following address:</Typography>
                    <Typography variant={'caption'}>Parrot Social</Typography>
                    <Typography variant={'caption'}>ATT: CA Privacy Rights</Typography>
                    <Typography variant={'caption'}>304 S. Jones Blvd #2799</Typography>
                    <Typography variant={'caption'}>Las Vegas NV 89107</Typography>
                    <Typography variant={'h6'}>Requesting and/or Correcting Your Information</Typography>
                    <Typography>You can request a copy of the personally-identifiable information that Parrot Social has collected about you and/or request corrections to factual inaccuracies contacting our Information Privacy team at <a className={classes.link} href='mailto:support@parrotsocial.app?subject=Parrot Social'>support@parrotsocial.app</a>.</Typography>
                    <Typography variant={'h6'}>Parrot Social’s Commitment to Data Security</Typography>
                    <Typography>In order to prevent unauthorized access, ensure the correct use of information, and maintain data accuracy, we have put in place appropriate physical, electronic, and managerial procedures to secure and safeguard the information we collect.</Typography>
                    <Typography variant={'h6'}>Access From Outside the US</Typography>
                    <Typography>The Parrot Social Website and Applications are intended for users in the United States and Canada. If you are using these services from outside the United States, please be aware that personal and non-personal data is transferred to, stored in, and processed inside the United States, and/or in other countries in which we engage service providers. Through your use of these services you consent to that transfer and handling of this data.</Typography>
                    <Typography variant={'h6'}>Tax and Regulatory Filing</Typography>
                    <Typography>In certain circumstances, such as when you withdraw funds or have winnings in excess of required thresholds, we may require you to provide your social security number solely for identity verification and tax purposes. Your social security number will be kept confidential by us, except as required by law. We may also have to disclose account activity and winnings to the Secretary of State, the Department of Revenue and any other applicable state or federal entities as required by law.</Typography>
                    <Typography variant={'h6'}>Revisions to this Privacy Notice</Typography>
                    <Typography>This notice may be revised periodically, and this will be reflected by the “date of last update” above. Please revisit this page to stay aware of any changes. In general, we only use your personal information in the manner described in the notice in effect when we received the personal information you provided. In the event that a revision to our policy makes this notice less restrictive on our use or disclosure of the personal information you have already provided us, we will make a reasonable attempt to obtain your consent before implementing the revision. Your continued use of the App and Web Site constitutes your agreement to this notice and any future revisions.</Typography>
                    <Typography>If you would like to contact us for any reason regarding our privacy practices, please e-mail us at <a className={classes.link} href='mailto:support@parrotsocial.app?subject=Parrot Social'>support@parrotsocial.app</a>.</Typography>
                    <Typography variant={'caption'}>Copyright © 2020- 2022 Parrot Social. All rights reserved. Parrot Social is owned and operated by Open Gaming Solutions Inc.. All payments are processed by Open Gaming Solutions Inc. or Open Gaming Sports Network Inc.. The sweepstakes promotions and prizes offered at Parrot Social are operated by Open Gaming Solutions Inc.. The registered address of Open Gaming Solutions is PO Box 28047 Lansdowne PO Kamloops, BC V2C 0C9.</Typography>
                </Card>
            </Container>
        </OverridableSafeAreaDiv>
    );
}
