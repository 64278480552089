import { Box, withStyles, WithStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import OverridableComponent from '../components/overridableComponent';
import { styles } from './safeAreaDivStyles';

export interface SafeAreaDivProps extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>, WithStyles<typeof styles> {
    imageSrc?: string;
}

export function UnstyledSafeAreaDiv(props: SafeAreaDivProps) {
    return <Box className={ props.classes.safeAreaDiv }>
        {
            props.imageSrc ?
                <img className={props.classes.imageBg} src={props.imageSrc} />:
                null
        }
        <Box className={
            classNames(
                props.classes.safeAreaDivOffset,
                `${typeof props.className !== 'undefined' ? props.className : ''}`
            ) }>
            { props.children }
        </Box>
    </Box>;
}

export const SafeAreaDiv = withStyles(styles)(UnstyledSafeAreaDiv);

export const OverridableSafeAreaDiv = OverridableComponent('safeAreaDiv', SafeAreaDiv);
