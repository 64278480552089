import { Box, createStyles, createTheme, CssBaseline, ThemeProvider, withStyles } from '@material-ui/core';
import React from 'react';
import './App.css';
import { AppRoutes } from './navigation/navigation';
import { config } from './apps/config';

interface AppStyleProps {
    classes: {
        box: string;
    }
}

export const theme = createTheme(config.theme);

const styles = () => createStyles({
    box: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex'
        }
    }
});

export default withStyles(styles)(
    class App extends React.PureComponent<AppStyleProps> {
        public render() {
            return (
                <Box className={this.props.classes.box}>
                    {/* todo add light and dark themes */}
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <AppRoutes />
                    </ThemeProvider>
                </Box>
            );
        }
    }
);
