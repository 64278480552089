import { AppComponent, AppConfigComponents, components } from '../../apps/components';

export default function OverridableComponent<Props>(key: keyof AppConfigComponents, component: AppComponent<Props>): AppComponent<Props> {
    /**
     * Cheating with the type narrowing by using `as` however, currently not sure how to type this correctly to get
     * the `config.components[key]` type. This means that wherever this is used, the key won't get the type checking
     * eg. OverridableComponent('appNavigationBar', SafeAreaDiv) will look correct but is totally wrong
     *
     * Since the `component` type is known still get the correct `return` type
     */
    const overrideComponent = components?.[key] as AppComponent<Props> | undefined;
    if (overrideComponent) {
        return overrideComponent;
    }
    return component;
}
