import React from 'react';
import {
    Box, makeStyles, Typography
} from '@material-ui/core';
import { useCustomer } from '@ogsnetwork/opp-component-lib';
import { ThemeWithOrgPalette } from '../apps/config';
import { CenterScreenView } from '../utils/views/CenterScreenView';
import { web3 } from '../providers';
import { OverridableSafeAreaDiv } from '../utils/views/overridableSafeAreaDiv';
import polyBeach from '../images/polyBeach.png';
import { getTokenContract } from '../contracts/GetToken';
import { AbiItem } from 'web3-utils';
import { abi as ParrotTokenAbi } from '../contracts/ParrotToken.json';
import { ContainerWithImage, CWIContentHeaderImage, CWIContentTitle, CWIContentContainer } from '../utils/components/containerWithImage';
import pscHand from '../images/pscHand.png';
import { BrandedLink } from '../utils/components/BrandedLink';
import ethImg from '../images/eth-bg.png';

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    actions: {
        padding: 10
    },
    button: {
        marginBottom: 10,
        marginTop: 10
    },
    card: {
        minWidth: 350,
        maxWidth: 400,
        padding: 20
    },
    cardMetaInfo: {
        borderRadius: 5,
        backgroundColor: theme.orgPalette?.cardOnCard,
        marginBottom: 15,
        marginTop: 10
    },
    divider: {
        backgroundColor: theme.orgPalette?.cardOnCard,
        height: 1,
        marginBottom: 10,
        width: '100%'
    },
    highlightText: {
        color: theme.palette.secondary.main
    },
    highlightTextAlt: {
        color: theme.palette.primary.main
    },
    manageFundsComingSoonContainer: {
        borderRadius: 5,
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        padding: 10
    }
}));

export function FundsScreen() {
    const classes = useStyles();
    const { customer } = useCustomer();
    // const [addressTokens, setAddressTokens] = useState('');
    // const [action, setAction] = useState('deposit');
    // const [amount, setAmount] = useState('');

    // const contract = getTokenContract(ParrotTokenAbi as AbiItem[], config.metaMask.contractAddresses?.parrotToken);

    // useEffect(() => {
    //     async function fetchUserTokens() {
    //         if (web3) {
    //             if (!contract) {
    //                 return;
    //             }
    //             const accounts = await web3.eth.requestAccounts();
    //             const account = accounts[0];
    //             if (account && account === customer?.ethAddress) {
    //                 const userTokens = await contract.methods.balanceOf(account).call();
    //                 const tokens = web3.utils.fromWei(userTokens, 'ether');
    //                 setAddressTokens(tokens);
    //             }
    //         }
    //     }
    //     fetchUserTokens();
    // }, []);

    // async function deposit() {
    //     if (web3) {
    //         if (!contract) {
    //             return;
    //         }
    //         const accounts = await web3.eth.requestAccounts();
    //         const account = accounts[0];
    //         if (account && account === customer?.ethAddress && amount !== '') {
    //             const tokens = web3.utils.toWei(amount, 'ether');
    //             const actualAmount = web3.utils.toBN(tokens);
    //             const tx = await contract.methods.transfer('0xaA4a07Fb5c4EcA87bC080fA7404224b16a6eFF38', actualAmount).send({from: account});
    //             if (tx) {
    //                 console.log('successfully deposited');
    //             } else {
    //                 console.log('error depositing');
    //             }
    //             setAmount('');
    //         }
    //     }
    // }

    // function deposit() {
    //    console.log('deposit');
    // }

    // function withdraw() {
    //     console.log('withdraw');
    // }

    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach}>
            <CenterScreenView fullHeight>
                <ContainerWithImage image={ethImg}>
                    <CWIContentHeaderImage image={pscHand} />
                    <CWIContentTitle title={'Coming Soon'} />
                    <CWIContentContainer>
                        <Typography>You will be able to withdraw and deposit your on site psc tokens as <a className={classes.highlightText} href="https://ethereum.org/en/developers/docs/standards/tokens/erc-20/" target="_blank" rel="noreferrer">ERC-20 PSC tokens</a>.</Typography>
                        <Typography>Follow our <a className={classes.highlightTextAlt} href="https://discord.gg/CRD9tvukYP" target="_blank" rel="noreferrer">Discord</a> and <a className={classes.highlightTextAlt} href="https://twitter.com/ParrotSocialApp" target="_blank" rel="noreferrer">Twitter</a> for future updates.</Typography>
                        <Typography>In the meantime, collect tokens in our <BrandedLink to={'/props/lobby'} text={'games room'} />!</Typography>
                    </CWIContentContainer>
                    {/* <Card className={classes.card}>
                        <Typography variant={'h4'}>
                            Manage Funds
                        </Typography>
                        <Divider />
                        <Typography>
                            You have { tokenBalance } { config.tokenShorthand } { tokenBalance !== 1 ? 'tokens': 'token' }.
                        </Typography>
                        <Divider />
                        <Box className={classes.manageFundsComingSoonContainer}>
                            <Typography variant={'h6'}>
                                Coming Soon
                            </Typography>
                            <Typography>
                                You will be able to deposit and withdraw ERC-20 PSC tokens.
                            </Typography>
                        </Box>
                        <Grid container direction="column">
                            { customer?.ethAddress ?
                                <Box className={classes.cardMetaInfo}>
                                    <Paper>
                                        <Tabs
                                            value={action}
                                            onChange={(event, value) => setAction(value)}
                                        >
                                            <Tab label="Deposit" value="deposit" />
                                            <Tab label="Withdraw" value="withdraw" />
                                        </Tabs>
                                    </Paper>
                                    <Box className={classes.actions}>
                                        { action === 'deposit' ?
                                            <>
                                                <Typography>Deposit tokens from your wallet</Typography>
                                                <TextField
                                                    id="depositAmount"
                                                    type="depositAmount"
                                                    label='deposit'
                                                    variant='outlined'
                                                    fullWidth
                                                    value={amount}
                                                    onChange={(event) => setAmount(event.target.value)}
                                                />
                                                <Button className={classes.button} fullWidth color="primary" onClick={() => deposit()} variant="contained">Deposit PSC</Button>
                                            </>
                                        :
                                            <>
                                                <Typography>Withdraw tokens to your wallet</Typography>
                                                <TextField
                                                    id="withdrawAmount"
                                                    type="withdrawAmount"
                                                    label='Withdraw'
                                                    variant='outlined'
                                                    fullWidth
                                                    value={amount}
                                                    onChange={(event) => setAmount(event.target.value)}
                                                />
                                                <Button className={classes.button} fullWidth color="primary" onClick={() => withdraw()} variant="contained">Withdraw PSC</Button>
                                            </>
                                        }
                                    </Box>
                                </Box>
                                :
                                <Typography>
                                    Before you can deposit or withdraw { config.tokenShorthand } tokens, first go to your <BrandedLink to='/mywallet' text={'Profile'}/> and connect an Ethereum Address to your account.
                                </Typography>
                            }
                        </Grid>
                    </Card> */}
                </ContainerWithImage>
            </CenterScreenView>
        </OverridableSafeAreaDiv>
    );
}
