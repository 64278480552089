import {
    authenticationReducer,
    betsReducer,
    claimParrotTokensReducer,
    cryptoConnectReducer,
    cryptoNonceReducer,
    customerReducer,
    eventsReducer,
    newsletterReducer,
    provincesReducer,
    selectionsReducer,
    ticketsReducer,
    transactionsReducer,
} from '@ogsnetwork/opp-redux-lib';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
    authentication: authenticationReducer,
    customer: customerReducer,
    bets: betsReducer,
    connect: cryptoConnectReducer,
    events: eventsReducer,
    newsletter: newsletterReducer,
    nonce: cryptoNonceReducer,
    claimParrotTokens: claimParrotTokensReducer,
    provinces: provincesReducer,
    selections: selectionsReducer,
    transactions: transactionsReducer,
    tickets: ticketsReducer
});

export type RootState = ReturnType<typeof rootReducer>
export const store = configureStore({
    reducer: rootReducer
});

export type AppDispatch = typeof store.dispatch
export interface AppDispatchProp {
    dispatch: AppDispatch;
}
